import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useForm } from "@mantine/form";
import {
  Button,
  TextInput,
  Card,
  Center,
  PasswordInput,
  useMantineTheme,
  Group,
  Title,
} from "@mantine/core";
import { IconAt, IconLock } from "@tabler/icons-react";
import authService from "../../../shared/services/auth.service";
import { AppLogo } from "../../../shared/components/icons/icons";
import { createAction } from "../../../shared/util/utility";
import * as actionTypes from "../../../store/action-types";
import httpService from "../../../shared/services/http.service";
import { API_CONFIG } from "../../../shared/constants/constants";
import { IResponseObj, IUserData } from "../../../shared/interface";
import notify from "../../../shared/notification/notification";

interface IFormValues {
  email: string;
  password: string;
}

const Login: React.FC = () => {
  const dispatch = useDispatch();
  const theme = useMantineTheme();
  const [loading, setLoading] = useState(false);

  const form = useForm({
    initialValues: {
      email: "",
      password: "",
    },
    validate: {
      email: (value) => (/^\S+@\S+$/.test(value) ? null : "Invalid email"),
    },
  });
  const handleLogin = (values: IFormValues) => {
    setLoading(true);
    httpService
      .post(API_CONFIG.path.login, values, {}, { isAccessTokenRequire: false })
      .then((response: IResponseObj<IUserData>) => {
        setLoading(false);
        authService.setAuthData(response.data);
        authService.setUserData(response.data);
        dispatch(createAction(actionTypes.AUTH_SUCCESS, response.data));
        notify(response.message, "success");
      })
      .catch((err) => {
        console.log("err", err);
        setLoading(false);
      });
  };

  return (
    <Center
      sx={{
        height: "100vh",
        width: "100%",
        flexDirection: "column",
        backgroundColor: theme.colors.gray[1],
      }}
    >
      <AppLogo />
      <Card
        sx={{
          width: "390px",
          marginTop: "20px",
          boxShadow: "0px 10px 20px 0px #2E36461C",
          border: "1px solid #E6E9EC80",
        }}
        padding="20px"
        radius="10px"
      >
        <Title
          order={1}
          style={{
            textAlign: "center",
            marginTop: 10,
            fontFamily: "Open Sans",
          }}
        >
          Login to Admin
        </Title>
        <form style={{ marginTop: 50 }} onSubmit={form.onSubmit(handleLogin)}>
          <TextInput
            placeholder="your@email.com"
            icon={<IconAt size="1rem" />}
            {...form.getInputProps("email")}
          />
          <PasswordInput
            sx={{ marginTop: "20px" }}
            placeholder="Password"
            icon={<IconLock size="1rem" />}
            {...form.getInputProps("password")}
          />
          <Group mt={40} position="center">
            <Button
              loading={loading}
              fullWidth
              sx={{
                height: "37px",
                transition: "0.3s all ease-in-out",
              }}
              type={loading ? "button" : "submit"}
              color={"app"}
            >
              Login
            </Button>
          </Group>
        </form>
      </Card>
    </Center>
  );
};

export default Login;
