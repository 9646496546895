import { Flex, Sx, SystemProp } from "@mantine/core";
import { CSSProperties } from "react";
import CustomBadge, { ICustomBadge } from "./customBadge";

export interface ICustomBadgeGroup {
  data: ICustomBadge[];
  sx?: Sx;
  align?: SystemProp<CSSProperties["justifyContent"]>;
}

const CustomBadgeGroup = (props: ICustomBadgeGroup) => {
  const { data, sx, align } = props;
  return (
    <Flex mt={20} justify={align || "start"} gap={20} sx={sx}>
      {data.map((eventType, index) => (
        <CustomBadge key={index} {...eventType} />
      ))}
    </Flex>
  );
};

export default CustomBadgeGroup;
