import { Image, CloseButton } from '@mantine/core';
import CustomModal from '../modal/CustomModal';

interface IProps {
  loading: boolean;
  opened: boolean;
  handleClose: () => void;
  className: string;
  imageLink: string;
}

const ImagePreviewModal = (props: IProps) => {
  const { opened, handleClose, className, imageLink } = props;

  return (
    <CustomModal
      opened={opened}
      handleClose={handleClose}
      className={className}
      modalTitle=""
      isConfirm={false}
      hideCloseIcon
    >
      <CloseButton
        title="Close popover"
        pos={'absolute'}
        right={-17}
        onClick={handleClose}
        size="lg"
        top={-17}
        sx={{ zIndex: 1, backgroundColor: 'white' }}
        iconSize={20}
      />
      <Image
        p={0}
        radius={10}
        alt="flagged-post-image"
        width={'700px'}
        height={'700px'}
        src={imageLink ? imageLink : ''}
      />
    </CustomModal>
  );
};

export default ImagePreviewModal;
