import { useEffect, useState } from "react";
import { Collapse, Text, UnstyledButton, rem, Flex } from "@mantine/core";
import { useDispatch } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { IconChevronLeft, IconChevronRight } from "@tabler/icons-react";
import * as actionTypes from "../../../store/action-types";
import authService from "../../services/auth.service";
import { createAction } from "../../util/utility";
import { useNavbarStyles } from "./styles";
import { IRouteObject } from "../../interface";
import Confirm from "../confirm/confirm";
import notify from "../../notification/notification";

interface ILinksGroupProps {
  route: IRouteObject;
}

export function LinksGroup({ route }: ILinksGroupProps) {
  const { label, Icon, href, action, children } = route;

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const { pathname } = useLocation();

  const [active, setActive] = useState(pathname === href);

  const [opened, setOpened] = useState(false);

  const { classes, theme, cx } = useNavbarStyles();

  const [isConfirmOpen, setIsConfirmOpen] = useState(false);

  useEffect(() => {
    if (!href) {
      setActive(false);
    } else if (pathname === "/" && href === "/") {
      setActive(true);
      setOpened(true);
    } else {
      const level1 = href.split("/")[1];
      const mainMenuActive = !level1 ? false : pathname.includes(level1);

      setActive(mainMenuActive);
      setOpened(mainMenuActive);
    }
  }, [pathname, setActive, href]);

  const hasLinks = Array.isArray(children);

  const IconChevron = theme.dir === "ltr" ? IconChevronRight : IconChevronLeft;

  const handleLogOut = () => {
    authService.removeAuthData();
    dispatch(createAction(actionTypes.AUTH_LOGOUT_SUCCESS));
    notify("Log out successfully", "success");
  };

  const handleConfirmClose = () => {
    setIsConfirmOpen(false);
  };

  const handleConfirmLogout = () => {
    handleLogOut();
  };

  const actionMapper: any = {
    [actionTypes.AUTH_LOGOUT_SUCCESS]: handleLogOut,
  };

  const handleMenuClick = () => {
    if (!children) {
      if (href) {
        navigate(href);
      } else if (action && action in actionMapper) {
        if (actionTypes.AUTH_LOGOUT_SUCCESS) {
          setIsConfirmOpen(true);
        } else {
          actionMapper[action]();
        }
      }
    } else if (!active) {
      setOpened((o) => !o);
    }
  };

  const items = (hasLinks ? children : []).map(({ href: subHref, label }) => (
    <Link
      key={subHref || "/"}
      to={subHref || "/"}
      className={cx(classes.link, {
        [classes.linkActive]: pathname.startsWith(subHref || "--") && active,
      })}
    >
      <Text key={label}>{label}</Text>
    </Link>
  ));

  return (
    <>
      {isConfirmOpen && (
        <Confirm
          loading={false}
          opened={isConfirmOpen}
          modalTitle="Are you sure you want to Logout?"
          handleConfirm={handleConfirmLogout}
          handleClose={handleConfirmClose}
          cancelBtnText="Cancel"
          submitBtnText="Logout"
          size={"500px"}
        />
      )}
      <UnstyledButton
        onClick={handleMenuClick}
        className={cx(classes.control, {
          [classes.controlActive]: active,
        })}
      >
        <Flex justify={"space-between"} align={"center"} sx={{ width: "100%" }}>
          <Flex gap={"20px"} align={"center"}>
            <Flex sx={{ width: "25px" }}>
              <Icon active={active} />
            </Flex>
            <Text>{label}</Text>
          </Flex>
          {hasLinks && (
            <IconChevron
              className={classes.chevron}
              size="1rem"
              stroke={1.5}
              style={{
                marginRight: rem(16),
                transform: opened
                  ? `rotate(${theme.dir === "rtl" ? -90 : 90}deg)`
                  : "none",
              }}
            />
          )}
        </Flex>
      </UnstyledButton>
      {hasLinks ? <Collapse in={opened}>{items}</Collapse> : null}
    </>
  );
}
