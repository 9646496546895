import { Badge, Box, Card, Group, Skeleton, Text } from '@mantine/core';
import { IUpComingChallenge } from '../interface/dashboard.interface';

interface IProps {
  loading: boolean;
  upComingChallengeList: IUpComingChallenge[];
  handleRedirect: (id: string) => void;
}

const UpcomingChallenge: React.FC<IProps> = (props) => {
  const CategoryLabelBorderColor: any = {
    draw: '#7CA9FF',
    write: '#C57CFF',
    business: '#74D2CD',
    other: '#818181',
  };

  const CategoryLabelBgColor: any = {
    draw: 'rgba(124, 169, 255, 0.20)',
    write: 'rgba(197, 124, 255, 0.2)',
    business: 'rgba(116, 210, 205, 0.2)',
    other: 'rgba(129, 129, 129, 0.2)',
  };

  return (
    <>
      {props.upComingChallengeList.length === 0 ? (
        <Skeleton sx={{ minHeight: '70px' }} mt={10} visible={props.loading}>
          <Card>
            <Text
              align="center"
              sx={{
                fontSize: '15px',
                fontWeight: 500,
                fontFamily: 'Open Sans',
              }}
            >
              No Any Upcoming Challenge Available
            </Text>
          </Card>
        </Skeleton>
      ) : (
        <>
          {props.upComingChallengeList?.map((item) => (
            <Skeleton
              key={item.id}
              sx={{ minHeight: '70px' }}
              mt={10}
              visible={props.loading}
            >
              <Card
                onClick={() => props.handleRedirect(item.id)}
                className={'animate__animated animate__fadeIn'}
                key={item.id}
                mt={22}
                sx={{
                  height: '70px',
                  cursor: 'pointer',
                  transition: '0.2s all ease-in-out',
                  '&:hover': {
                    transform: 'scale(1.01)',
                    boxShadow: '0px 6px 10px 0px #2e36460d',
                  },
                }}
              >
                <Group position="apart" align="center" sx={{ height: '100%' }}>
                  <Box
                    sx={{
                      '*': { fontFamily: 'Open Sans' },
                      display: 'flex',
                      justifyContent: 'start',
                      width: '75%',
                    }}
                  >
                    <Text
                      weight={600}
                      size={'18px'}
                      mr={20}
                      sx={{
                        fontFamily: 'Open Sans',
                        lineHeight: '23px',
                        wordWrap: 'break-word',
                        maxWidth: '700px',
                      }}
                      lineClamp={3}
                    >
                      {item.description}
                    </Text>
                    <Badge
                      sx={{
                        borderRadius: 30,
                        backgroundColor: CategoryLabelBgColor[item.label],
                        border: `1px solid ${
                          CategoryLabelBorderColor[item.label]
                        }`,
                      }}
                      size="lg"
                      fs={'14px'}
                      variant="outline"
                      fw={600}
                      mr={20}
                    >
                      <Text
                        sx={{
                          fontFamily: 'Open Sans',
                          textTransform: 'capitalize',
                        }}
                        weight={600}
                        size={'12px'}
                        color="black"
                      >
                        {item.label}
                      </Text>
                    </Badge>
                  </Box>
                  <Text
                    sx={{
                      width: '20%',
                      textAlign: 'end',
                    }}
                  >
                    {item.scheduleDate}
                  </Text>
                </Group>
              </Card>
            </Skeleton>
          ))}
        </>
      )}
    </>
  );
};

export default UpcomingChallenge;
