import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);
export const options = {
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    y: {
      display: false,
      beginAtZero: true,
    },
  },
  plugins: {
    legend: {
      align: 'end' as const,
      labels: {
        boxWidth: 12,
        fontSize: 8,
      },
    },
    title: {
      display: true,
    },
  },
};

interface IProps {
  chartData: any;
}

const ActiveTimesChart: React.FC<IProps> = (props) => {
  return <Bar height={'300px'} options={options} data={props.chartData} />;
};

export default ActiveTimesChart;
