import {
  Box,
  Button,
  Group,
  Text,
  TextInput,
  useMantineTheme,
} from '@mantine/core';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  APP_HEADER_HEIGHT,
  APP_PADDING,
  primaryBoxShadow,
  searchInputStyle,
} from '../../constants/constants';
import { IconLeftArrow, IconSearch } from '../icons/icons';

interface ActionButton {
  label: string;
  icon: React.ReactNode;
  type: string;
  loading?: boolean;
  disabled?: boolean;
  color?: string;
  variant?: string;
  boxShadow?: string;
  handleAction?: (type: string) => void;
}

interface PageHeaderProps {
  title: string;
  showBackButton?: boolean;
  showActionButtons?: ActionButton[];
  showSearchButton?: boolean;
  handleSearchAction?: (value: string) => void;
  searchProps?: any;
  searchValue?: string;
}

function PageHeader({
  title,
  showBackButton,
  showActionButtons,
  showSearchButton,
  handleSearchAction,
  searchProps,
  searchValue,
}: PageHeaderProps) {
  const [isInputFocused, setInputFocused] = useState(false);
  const navigate = useNavigate();

  const theme = useMantineTheme();

  const handleGoBack = () => {
    navigate(-1);
  };

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleSearchAction && handleSearchAction(event.target.value);
  };

  return (
    <Box
      sx={{
        marginLeft: `-${APP_PADDING}px`,
        width: `calc(100% + ${APP_PADDING * 2}px)`,
        marginBottom: '30px',
        backgroundColor: 'white',
        boxShadow: theme.shadows.md,
        marginTop: `${APP_HEADER_HEIGHT}px`,
        height: '100px',
        position: 'sticky',
        top: `${APP_HEADER_HEIGHT}px`,
        zIndex: 99999,
      }}
    >
      <Group
        align="center"
        sx={{
          marginBottom: 39,
          display: 'flex',
          justifyContent: 'space-between',
          height: '100px',
          background: '#fff',
          margin: `0 ${APP_PADDING}px`,
        }}
      >
        <Group>
          {showBackButton && (
            <Button
              variant="outline"
              sx={{
                marginRight: 0,
                border: 'none',
                background: 'none',
                padding: 0,
                '&:hover': {
                  background: 'transparent',
                },
              }}
              onClick={handleGoBack}
            >
              <IconLeftArrow />
            </Button>
          )}

          <Text
            size="xl"
            weight={700}
            sx={{ fontFamily: 'Open Sans, sans-serif', fontSize: '24px' }}
          >
            {title}
          </Text>
        </Group>

        <Group>
          {showSearchButton && (
            <Group style={{ position: 'relative', borderRadius: 30 }}>
              <TextInput
                placeholder="Search"
                variant="outline"
                value={searchValue}
                styles={searchInputStyle(isInputFocused)}
                icon={<IconSearch color="#ADADAD" />}
                onChange={handleSearch}
                onFocus={() => setInputFocused(true)}
                onBlur={() => setInputFocused(false)}
                {...searchProps}
              />
            </Group>
          )}
          {showActionButtons &&
            showActionButtons.map((actionButton, index) => (
              <Button
                key={index}
                disabled={actionButton.disabled}
                variant={actionButton.variant || 'filled'}
                color={actionButton.color || 'app'}
                sx={{
                  boxShadow: actionButton.boxShadow || primaryBoxShadow,
                  width: '170px',
                  '.mantine-Button-leftIcon': {
                    marginRight: '10px',
                  },
                }}
                leftIcon={actionButton.icon}
                onClick={() =>
                  actionButton.handleAction &&
                  actionButton.handleAction(actionButton.type)
                }
              >
                <Text
                  size={'12px'}
                  weight={700}
                  sx={{ fontFamily: 'Open Sans' }}
                >
                  {actionButton.label}
                </Text>
              </Button>
            ))}
        </Group>
      </Group>
    </Box>
  );
}

export default PageHeader;
