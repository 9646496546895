// Converts camelCase keys to underscore-separated keys
const camelToUnderscore = (input: string): string => {
  return input.replace(/([a-z\d])([A-Z])/g, '$1_$2').toLowerCase();
};

// Converts underscore-separated keys to camelCase keys
const underscoreToCamel = (input: string): string => {
  return input.replace(/_([a-z])/g, (_, letter) => letter.toUpperCase());
};

// Recursively converts object keys
const convertObjectKeys = (obj: any, converter: (input: string) => string): any => {
  if (typeof obj !== 'object' || obj === null) {
    return obj;
  }

  if (Array.isArray(obj)) {
    return obj.map(item => convertObjectKeys(item, converter));
  }

  const result: any = {};
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const newKey = converter(key);
      result[newKey] = convertObjectKeys(obj[key], converter);
    }
  }
  return result;
};

// Converts payload keys from camelCase to underscore-separated
export const transformPayload = (payload: any): any => {
  return convertObjectKeys(payload, camelToUnderscore);
};

// Converts API response keys from underscore-separated to camelCase
export const transferResponse = (response: any): any => {
  return convertObjectKeys(response, underscoreToCamel);
};