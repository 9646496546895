import { MantineProvider } from '@mantine/core';
import { ModalsProvider } from '@mantine/modals';
import 'animate.css';
import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import './App.scss';
import ErrorBoundary from './shared/errorBoundary/errorBoundary';
import { CustomFonts } from './shared/hoc/CustomFonts';
import customTheme from './shared/hoc/customTheme';
import ScrollToTop from './shared/hoc/scrollToTop';
import WithErrorHandler from './shared/hoc/withErrorHandler';
import store from './store';

const Root: React.FC = (props) => {
  return (
    <Provider store={store}>
      <ErrorBoundary>
        <WithErrorHandler />
        <BrowserRouter>
          <MantineProvider theme={customTheme}>
            <CustomFonts />
            <ModalsProvider>
              <ScrollToTop />
              <App {...props} />
            </ModalsProvider>
          </MantineProvider>
        </BrowserRouter>
      </ErrorBoundary>
    </Provider>
  );
};

export default Root;
