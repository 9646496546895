import React, { useMemo, useState } from 'react';
import {
  Box,
  Card,
  Flex,
  Group,
  Image,
  LoadingOverlay,
  Text,
  TextInput,
  useMantineTheme,
} from '@mantine/core';
import DataTable, { TableColumn } from 'react-data-table-component';
import { useNavigate } from 'react-router-dom';
import ActionButton from '../../../shared/components/actionButton/actionButton';
import { IconSearch, IconView } from '../../../shared/components/icons/icons';
import { dataTableStyles, searchInputStyle } from '../../../shared/constants/constants';
import { IPagination } from '../../../shared/interface';
import { IFlaggedUsersList } from '../interface/flaggedPosts.interface';
export interface IFlaggedPostsList {
  flaggedPostsData: IFlaggedUsersList[];
  handleSearch: (value: string) => void;
  loading: boolean;
  pagination: IPagination;
  searchProps: any;
  flaggedUserSearch: string;
}

const FlaggedUsersList: React.FC<IFlaggedPostsList> = (props) => {
  const theme = useMantineTheme();
  const navigate = useNavigate();

  const [isInputFocused, setInputFocused] = useState(false);

  const {
    flaggedPostsData,
    handleSearch,
    loading,
    pagination,
    searchProps,
    flaggedUserSearch,
  } = props;
  const { currentPage, recordPerPage } = pagination;

  const handleView = (userId: string) => {
    navigate(`/users/${userId}`);
  };

  const columns: TableColumn<any>[] = useMemo(() => {
    return [
      {
        name: 'Sr. No.',
        width: "80px",
        center: true,
        selector: (row) => row.id,
        cell: (row, index) => {
          return <span>{(currentPage - 1) * recordPerPage + index + 1}</span>
        }
      },
      {
        name: 'User Profile',
        center: true,
        selector: (row) => row.postImage,
        cell: (row) => {
          return (
            <Image
              src={row.postImage}
              alt="Image"
              radius="50%"
              width={40}
              height={40}
            />
          )
        }
      },
      {
        name: 'Username',
        center: true,
        selector: (row) => row.username || "-",
      },
      {
        name: 'Full Name',
        center: true,
        selector: (row) => row.fullname || "-",
      },
      {
        name: 'Country',
        center: true,
        width: "150px",
        selector: (row) => row.country || '-',
      },
      {
        name: 'Action',
        center: true,
        width: "200px",
        ignoreRowClick: true,
        cell: (row) => {
          return (
            <Flex align={'center'} justify={'center'}>
              <ActionButton
                onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
                  e.stopPropagation();
                  handleView(row.userId);
                }}
                icon={<IconView />}
              />
            </Flex>
          )
        }
      },
    ]
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flaggedPostsData])

  const loadingComponent = useMemo(() => (
    <Card sx={loading ? { minHeight: "60px" } : {}}>
      {loading ? (
        <LoadingOverlay
          visible={true}
          overlayBlur={2}
          zIndex={5}
          sx={{ borderRadius: '10px' }}
        />
      ) : (
        <Text>
          No any challenges found.
        </Text>
      )}
    </Card>
  ), [loading])

  return (
    <>
      <Group
        align="center"
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          height: '37px',
          marginBottom: '15px',
        }}
      >
        <Group>
          <Text
            size="xl"
            weight={700}
            sx={{
              fontFamily: 'Open Sans, sans-serif',
              fontSize: '18px',
              color: '#2E3646',
              fontWeight: 500,
              opacity: 0.5,
            }}
          >
            {`${flaggedPostsData.length} Users Flagged the posts`}
          </Text>
        </Group>
        <Group>
          <Group
            style={{ position: 'relative', borderRadius: 30 }}
            sx={{ boxShadow: theme.shadows.md }}
          >
            <TextInput
              placeholder="Search"
              variant="outline"
              value={flaggedUserSearch}
              styles={searchInputStyle(isInputFocused)}
              icon={<IconSearch color="#ADADAD" />}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                handleSearch(e.target.value);
              }}
              onFocus={() => setInputFocused(true)}
              onBlur={() => setInputFocused(false)}
              {...searchProps}
            />
          </Group>
        </Group>
      </Group>
      <Box pos={'relative'} sx={{
        boxShadow: "rgba(0, 0, 0, 0.1) 0px 10px 50px -10px",
        borderRadius: "9px",
      }}>
        <DataTable
          striped
          responsive
          onRowClicked={(row) => {
            handleView(row.userId)
          }}
          pointerOnHover
          persistTableHead
          noDataComponent={loadingComponent}
          highlightOnHover
          customStyles={dataTableStyles}
          columns={columns}
          data={loading ? [] : flaggedPostsData}
        />
      </Box>
    </>
  );
};

export default FlaggedUsersList;
