import { Flex } from '@mantine/core';
import React from 'react';

interface IActionButton {
  icon: React.ReactNode;
  color?: string;
  onClick: any;
  isDisable?: boolean;
}
const ActionButton: React.FC<IActionButton> = (props) => {
  const { icon, onClick, isDisable } = props;
  return (
    <Flex
      onClick={onClick}
      mx="5px"
      p="5px"
      sx={{
        transition: 'all ease-in-out 0.3s',
        svg: {
          transition: 'all ease-in-out 0.3s',
        },
        '&:hover': {
          svg: {
            transform: `scale(${isDisable ? 1 : 1.2})`,
          },
          cursor: isDisable ? 'not-allowed' : 'pointer',
        },
        borderRadius: '10px',
      }}
    >
      {icon}
    </Flex>
  );
};

export default ActionButton;
