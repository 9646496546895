import { Notifications } from '@mantine/notifications';
import { AxiosError, AxiosResponse } from 'axios';
import { FC, useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import * as actionTypes from '../../store/action-types';
import { IResponseObj } from '../interface';
import notify from '../notification/notification';
import authService from '../services/auth.service';
import { axiosInstance } from '../services/http.service';
import { createAction } from '../util/utility';

let resInterceptor: number;

const ErrorHandler: FC = () => {
  const dispatch = useDispatch();

  const logout = useCallback(() => {
    authService.removeAuthData();
    dispatch(createAction(actionTypes.AUTH_LOGOUT_SUCCESS));
    notify('Log out successfully', 'success');
  }, [dispatch]);

  useEffect(() => {
    resInterceptor = axiosInstance.interceptors.response.use(
      (res: AxiosResponse<IResponseObj<any>>) => {
        if (res.data.data && res.data.data.isError) {
          notify(res.data.data.message, 'error');
          // throw new Error(res.data.message);
        }
        dispatch(createAction(actionTypes.CORS_STATUS_UPDATE, false));
        return res;
      },
      (error: AxiosError) => {
        let show = true;
        if (error && error.request && error.request.status === 404) {
          show = false;
        }
        if (error && error.request && error.request.status === 504) {
          // throw new Error("504 Timeout");
          notify('Request timeout!', 'error');
        }
        if (error && error.request && error.request.status === 504) {
          // throw new Error("504 Timeout");
          notify('Request timeout!', 'error');
        }
        // check if error is having data
        if (
          error.response &&
          error.response.data &&
          error.response.status &&
          show
        ) {
          const status = error.response.status;
          const responseData: any = error.response.data;
          // is http error code is 401, and message contains **SESSION_EXPIRED**, log out of the application
          if (
            status === 401 &&
            responseData.message.toLowerCase().includes('session')
          ) {
            logout();
          } else if (responseData.is_error && responseData.message) {
            notify(responseData.message, 'error');
          }
          throw error;
        } else if (error && !error.response && error.message) {
          notify(error.message, 'error', error.code);
          dispatch(createAction(actionTypes.CORS_STATUS_UPDATE, true));
        }
      }
    );
    return () => axiosInstance.interceptors.response.eject(resInterceptor);
  }, [logout, dispatch]);

  return <Notifications />;
};

export default ErrorHandler;
