import CalendarComponent from '../../features/calendar/container/calendar';
import DailyChallengesComponent from '../../features/dailyChallenges/container/dailyChallenges';
import DashboardComponent from '../../features/dashboard/container/dashboard';
import FlaggedPostsComponent from '../../features/flaggedPosts/container/flaggedPosts';
import UserDetailsComponent from '../../features/users/container/userDetails';
import UsersComponent from '../../features/users/container/users';

import ChallengeDetails from '../../features/dailyChallenges/container/dailyChallengeDetails';
import FlaggedPostDetails from '../../features/flaggedPosts/container/flaggedPostDetails';
import {
  IconCalendar,
  IconChallenge,
  IconDashboard,
  IconFlaggedPost,
  IconUser,
} from '../components/icons/icons';
import { IRouteObject } from '../interface';

const Dashboard = 'Dashboard';
const Users = 'Users';
const DailyChallenges = 'Daily Challenges';
const FlaggedPosts = 'Flagged Posts';
const Calendar = 'Calendar';
// const Logout = "Logout";

// Example for Sub Routes

// const subRoutes: ISubRouteObject = {
//   [Dashboard]: [
//     {
//       label: "Sub Dashboard",
//       Icon: IconDashboard,
//       href: "/dashboard/sub-dashboard",
//       component: <DashboardComponent />,
//     },
//     {
//       label: "Charts",
//       Icon: IconUser,
//       href: "/dashboard/charts",
//       component: <DashboardComponent />,
//     },
//   ],
// };

const routes: IRouteObject[] = [
  {
    label: Dashboard,
    Icon: IconDashboard,
    component: <DashboardComponent />,
    href: '/',
    // children: subRoutes[Dashboard],
    showAsMenu: true,
  },
  {
    label: Users,
    Icon: IconUser,
    component: <UsersComponent />,
    href: '/users',
    showAsMenu: true,
  },
  {
    label: Users,
    Icon: IconUser,
    component: <UserDetailsComponent />,
    href: '/users/:userId',
    showAsMenu: false,
  },
  {
    label: DailyChallenges,
    Icon: IconChallenge,
    component: <DailyChallengesComponent />,
    href: '/challenges',
    showAsMenu: true,
  },
  {
    label: DailyChallenges,
    Icon: IconChallenge,
    component: <ChallengeDetails />,
    href: '/challenges/:challengeId',
    showAsMenu: false,
  },
  {
    label: FlaggedPosts,
    Icon: IconFlaggedPost,
    component: <FlaggedPostsComponent />,
    href: '/flagged-posts',
    showAsMenu: true,
  },
  {
    label: FlaggedPosts,
    Icon: IconFlaggedPost,
    component: <FlaggedPostDetails />,
    href: '/flagged-posts/:postId',
    showAsMenu: false,
  },
  {
    label: Calendar,
    Icon: IconCalendar,
    component: <CalendarComponent />,
    href: '/calendar',
    showAsMenu: true,
  },
  // {
  //   label: Logout,
  //   Icon: IconLogout,
  //   action: AUTH_LOGOUT_SUCCESS,
  //   showAsMenu: true,
  // },
];

export default routes;
