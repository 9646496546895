import { Box, Flex, Sx, Text } from "@mantine/core";

export interface ICustomBadge {
  label: string;
  color: string;
  labelStyle?: Sx;
  truncate?: boolean;
}

const CustomBadge = (props: ICustomBadge) => {
  const { label, color, truncate, labelStyle = {} } = props;
  return (
    <Flex gap={8} align={"center"}>
      <Box
        sx={{
          borderRadius: "5px",
          minWidth: "15px",
          height: "15px",
          backgroundColor: color,
        }}
      ></Box>
      <Text
        truncate={!!truncate ? "end" : undefined}
        sx={{
          fontFamily: "Inter, sans-serif",
          lineHeight: "27px",
          color: "gray",
          fontSize: "20px",
          fontWeight: 500,
          ...labelStyle,
        }}
      >
        {label}
      </Text>
    </Flex>
  );
};

export default CustomBadge;
