import { useCallback, useState } from 'react';

interface Filter {
  [key: string]: string | undefined;
  currentPage: string;
}

type UpdateFilterFunction = (filterObject: Partial<Filter>) => void;

function useLocalStorageFilter(
  storageKey: string,
  initialFilters: Partial<Filter> = {}
): [Filter, UpdateFilterFunction] {
  const [filters, setFilters] = useState<Filter>(() => {
    const savedFilters: Filter = JSON.parse(
      localStorage.getItem(storageKey) || '{}'
    );
    return { ...initialFilters, ...savedFilters };
  });

  const updateFilters = useCallback(
    (filterObject: Partial<Filter>) => {
      const updatedFilters: Filter = { ...filters, ...filterObject };
      localStorage.setItem(storageKey, JSON.stringify(updatedFilters));
      setFilters(updatedFilters);
    },
    [filters, storageKey]
  );

  return [filters, updateFilters];
}

export default useLocalStorageFilter;
