import { Navbar, ScrollArea } from "@mantine/core";
import { LinksGroup } from "./linkGroup";
import { useNavbarWrapperStyles } from "./styles";
import routes from "../../hoc/routes";

interface INavBarProps {
  hidden: boolean;
}

export default function NavbarComponent(props: INavBarProps) {
  const { hidden } = props;
  const { classes } = useNavbarWrapperStyles();

  const links = routes
    .filter((route) => route.showAsMenu)
    .map((route) => <LinksGroup route={route} key={route.label} />);

  return (
    <Navbar
      hiddenBreakpoint="sm"
      width={{ sm: 273 }}
      height={"100vh"}
      hidden={hidden}
      className={classes.navbar}
    >
      <Navbar.Section grow className={classes.links} component={ScrollArea}>
        {links}
      </Navbar.Section>
    </Navbar>
  );
}
