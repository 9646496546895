import { notifications } from '@mantine/notifications';
import { IconCheck, IconCross, IWarning } from '../components/icons/icons';

type NotificationType = 'success' | 'error' | 'warning' | 'info';

const getStyles = (type: string) => ({
  root: {
    backgroundColor: notificationColorMapper[type],
    borderColor: notificationColorMapper[type],
    '&::before': { backgroundColor: 'white' },
  },

  title: { color: 'white' },
  description: { color: 'white' },
  closeButton: {
    color: 'white',
    '&:hover': { backgroundColor: 'rgb(255, 255, 255, 0.3)' },
  },
});

export const notify = (
  message: string,
  type: NotificationType,
  id?: string
) => {
  const msg = `${message[0].toUpperCase()}${message.substr(1)}`;
  if (type === 'success') {
    notifications.show({
      id,
      title: 'Success',
      message: msg,
      styles: getStyles(type),
      icon: <IconCheck color="#3CB841" />,
    });
  } else if (type === 'error') {
    notifications.show({
      id,
      title: 'Error',
      message: msg,
      styles: getStyles(type),
      icon: <IconCross color="#FF5253" />,
    });
  } else if (type === 'warning') {
    notifications.show({
      id,
      title: 'Warning',
      message: msg,
      styles: getStyles(type),
      icon: <IWarning color="#E4B814" />,
    });
  } else {
    notifications.show({
      id,
      title: '',
      message: msg,
      styles: getStyles('info'),
    });
  }
};

const notificationColorMapper: any = {
  success: '#3CB841',
  error: '#FF5253',
  warning: '#E4B814',
  info: 'rgb(34, 139, 230)',
};

export default notify;
