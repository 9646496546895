import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { getUrl } from "../../shared/constants/constants";
import { IResponseObj } from "../interface";
import { transferResponse, transformPayload } from "../util/transformer";
import AuthService from "./auth.service";
const axiosInstance = axios.create();
const CancelToken = axios.CancelToken;
let cancel_req: any;

export { cancel_req };
export { axiosInstance };

export interface AxiosParams {
  method: string;
  url: string;
  data?: any;
  isAccessTokenRequire?: boolean;
  contentType?: string;
  responseType?: string
}

export interface MiscellaneousRequestParams {
  isAccessTokenRequire?: boolean;
  contentType?: string;
  responseType?: string, // important
}

/**
 * get method
 * @param request object containing axios params
 */
const get = (
  url: string,
  params: any = {},
  otherData: MiscellaneousRequestParams = {}
) => {
  return commonAxios({
    method: "GET",
    url: getUrl(url, transformPayload(params)),
    ...otherData,
  });
};

/**
 * post method
 * @param request object containing axios params
 */
const post = (
  url: string,
  params: any = {},
  queryParams = {},
  otherData: MiscellaneousRequestParams = {}
) => {
  return commonAxios({
    method: "POST",
    url: getUrl(url, queryParams),
    data: transformPayload(params),
    ...otherData,
  });
};

/**
 * put method
 * @param request object containing axios params
 */
const put = (
  url: string,
  params: any = {},
  otherData: MiscellaneousRequestParams = {}
) => {
  return commonAxios({
    method: "PUT",
    url: getUrl(url),
    data: transformPayload(params),
    ...otherData,
  });
};

/**
 * deleteRequest method
 * @param request object containing axios params
 */
const deleteRequest = (
  url: string,
  params: any = {},
  otherData: MiscellaneousRequestParams = {}
) => {
  return commonAxios({
    method: "DELETE",
    url: getUrl(url),
    data: transformPayload(params),
    ...otherData,
  });
};

/**
 * patch method
 * @param request object containing axios params
 */
const patch = (
  url: string,
  params: any = {},
  otherData: MiscellaneousRequestParams = {}
) => {
  return commonAxios({
    method: "PATCH",
    url: getUrl(url),
    data: transformPayload(params),
    ...otherData,
  });
};

/**
 * commonAxios
 * @param object containing method, url, data, access token, content-type
 */
const commonAxios = ({
  method,
  url,
  data,
  isAccessTokenRequire = true,
  contentType = "application/json",
  responseType = "json"
}: AxiosParams): Promise<any> => {
  const headers: any = {
    "Content-Type": contentType,
  };
  const token = isAccessTokenRequire && AuthService.getAccessToken();
  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }
  let body: any = null;
  if (contentType === "application/json") {
    body = JSON.stringify(data);
  } else {
    body = data;
  }
  return new Promise((resolve, reject) => {
    axiosInstance({
      method: method,
      url: url,
      cancelToken: new CancelToken(function executor(c) {
        // An executor function receives a cancel function as a parameter
        cancel_req = c;
      }),
      headers: headers,
      data: body,
      responseType
    } as AxiosRequestConfig)
      .then((response: AxiosResponse<IResponseObj<any>>) => {
        if (responseType !== "json") {
          resolve(response)
        } else if (response) {
          resolve(transferResponse(response.data));
        } else {
          reject(new Error("Network Error"))
        }
      })
      .catch((error: Error) => {
        reject(error);
      });
  });
};

const getBlob = (url: string) => {
  return new Promise((resolve, reject) => {
    axios
      .get(url, { responseType: "blob" })
      .then((response: any) => {
        resolve(response.data);
      })
      .catch((error: Error) => reject(error));
  });
};

const httpService = {
  get: get,
  post: post,
  put: put,
  deleteRequest: deleteRequest,
  patch: patch,
  getBlob: getBlob,
};


export default httpService;
