import {
  Badge,
  Box,
  Card,
  Flex,
  LoadingOverlay,
  Text,
  Tooltip,
} from '@mantine/core';
import React, { useMemo } from 'react';
import ActionButton from '../../../shared/components/actionButton/actionButton';
import moment from "moment"
import {
  IconEdit,
  IconTrash,
  IconView,
} from '../../../shared/components/icons/icons';
import {
  CHALLENGE_STATUS,
  DEFAULT_FORMAT,
  GetPromptLabelColor,
  dataTableStyles,
} from '../../../shared/constants/constants';
import { IPagination } from '../../../shared/interface';
import {
  convertFirstLatterToCapital,
  convertUnixToGMT,
  getChallengeStatus,
} from '../../../shared/util/utility';
import { IChallengeInfo } from '../interface/dailyChallenges.interface';
import DataTable, { TableColumn } from 'react-data-table-component';

export interface IChallengeList {
  challengesData: IChallengeInfo[];
  handleModel: (type: string, id?: string) => void;
  loading: boolean;
  pagination: IPagination;
  handleRedirect: (id: string) => void;
}

const textStyle = {
  fontFamily: 'Open Sans, sans-serif',
  fontWeight: 500,
  fontSize: '14px',
  lineHeight: 1.5,
};

const ChallengesList: React.FC<IChallengeList> = (props) => {
  const { challengesData, loading, pagination, handleModel, handleRedirect } =
    props;
  const { currentPage, recordPerPage } = pagination;

  const columns: TableColumn<any>[] = useMemo(() => {
    return [
      {
        name: 'Sr. No.',
        width: "80px",
        center: true,
        selector: (row) => row.id,
        cell: (row, index) => {
          return <span>{(currentPage - 1) * recordPerPage + index + 1}</span>
        }
      },
      {
        name: 'Challenges',
        selector: (row) => row.description,
        cell: (row) => {
          return (
            <Tooltip label={row.description || "-"} multiline>
              <Text
                align="left"
                sx={{ ...textStyle, wordWrap: 'break-word' }}
                lineClamp={2}
              >
                {row.description}
              </Text>
              {/* <Text {...textStyle} onClick={() => handleRedirect(row.id)}>{row.description || "-"}</Text> */}
            </Tooltip>
          )
        }
      },
      {
        name: 'Prompt Label',
        center: true,
        width: "200px",
        selector: (row) => row.label || "-",
        cell: (row) => {
          return (
            <Text sx={textStyle} color={GetPromptLabelColor(row.label)}>
              {convertFirstLatterToCapital(row.label)}
            </Text>
          )
        }
      },
      {
        name: 'Date Created',
        center: true,
        width: "150px",
        selector: (row) => row.createdAt || "-",
        cell: (row) => {
          return row.createdAt
            ? convertUnixToGMT(row.createdAt).format(DEFAULT_FORMAT)
            : '-'
        }
      },
      {
        name: 'Date of Post',
        center: true,
        width: "150px",
        selector: (row) => row.scheduleDate,
        cell: (row) => {
          return row.scheduleDate
            ? moment(row.scheduleDate * 1000).format(DEFAULT_FORMAT)
            : '-'
        }
      },

      {
        name: 'Status',
        center: true,
        width: "180px",
        selector: (row) => row.scheduleDate,
        cell: (row) => {
          const challengeStatus = getChallengeStatus(row.scheduleDate);
          return (
            <Badge
              sx={{
                fontWeight: 500,
                fontFamily: 'Open Sans',
                fontSize: '14px',
                textTransform: 'capitalize',
              }}
              color={CHALLENGE_STATUS[challengeStatus]}
              size={'lg'}
            >
              {challengeStatus}
            </Badge>
          )
        }
      },
      {
        name: 'Action',
        center: true,
        width: "200px",
        ignoreRowClick: true,
        selector: (row) => row.id,
        cell: (row) => {
          const challengeStatus = getChallengeStatus(row.scheduleDate);
          const isDisableDeleteAction =
            challengeStatus === 'active' || challengeStatus === 'posted';
          return (
            <Flex align={'center'} justify={'center'}>
              <ActionButton
                onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
                  e.stopPropagation();
                  handleRedirect(row.id);
                }}
                icon={<IconView />}
              />
              <ActionButton
                onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
                  e.stopPropagation();
                  handleModel('EDIT A CHALLENGE', row.id);
                }}
                icon={<IconEdit scale={1.2} />}
              />
              <ActionButton
                onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
                  e.stopPropagation();
                  !isDisableDeleteAction &&
                    handleModel('DELETE A CHALLENGE', row.id);
                }}
                icon={
                  <IconTrash
                    color={!isDisableDeleteAction ? '#FF6252' : 'gray'}
                  />
                }
                isDisable={isDisableDeleteAction}
              />
            </Flex>
          )
        }
      },
    ]
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [challengesData])

  const loadingComponent = useMemo(() => (
    <Card sx={loading ? { minHeight: "60px" } : {}}>
      {loading ? (
        <LoadingOverlay
          visible={true}
          overlayBlur={2}
          zIndex={5}
          sx={{ borderRadius: '10px' }}
        />
      ) : (
        <Text>
          No any challenges found.
        </Text>
      )}
    </Card>
  ), [loading])

  return (
    <Box mt={10} pos={'relative'} sx={{
      boxShadow: "rgba(0, 0, 0, 0.1) 0px 10px 50px -10px",
      borderRadius: "9px",
    }}>
      <DataTable
        striped
        responsive
        onRowClicked={(row) => {
          handleRedirect(row.id)
        }}
        pointerOnHover
        persistTableHead
        noDataComponent={loadingComponent}
        highlightOnHover
        customStyles={dataTableStyles}
        columns={columns}
        data={loading ? [] : challengesData}
      />
    </Box>
  );
};

export default ChallengesList;
