import { Pagination } from "@mantine/core";
import { IPaginationProps } from "../../interface";

export default function PaginationComponent(props: IPaginationProps) {
  const { pagination, fetchData, loading } = props;
  return (
    <Pagination
      value={pagination.currentPage}
      total={pagination.totalPages}
      mt={20}
      mb={10}
      position="right"
      withEdges
      onChange={(page) => {
        fetchData(page);
      }}
      disabled={loading}
    />
  );
}
