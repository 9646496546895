import {
  Button,
  Card,
  Flex,
  Image,
  Paper,
  Skeleton,
  Text,
  useMantineTheme,
} from '@mantine/core';
import React from 'react';
import {
  IconBlock,
  IconBlockFlag,
  IconResolveFlag,
  IconTrash,
} from '../../../shared/components/icons/icons';
import { ModalType, redBoxShadow } from '../../../shared/constants/constants';
import { IFlaggedPostDetails } from '../interface/flaggedPosts.interface';

interface FlaggedPostContentProps {
  currentFlagPostDetails: IFlaggedPostDetails;
  openConfirmModal: (type: ModalType) => void;
  isLoading: boolean;
  handleImageClick: () => void;
}

const FlaggedPostContent: React.FC<FlaggedPostContentProps> = ({
  currentFlagPostDetails,
  openConfirmModal,
  handleImageClick,
  isLoading,
}) => {
  const theme = useMantineTheme();

  const actionButtons = [
    {
      type: 'resolve',
      label: 'Appropriate Post',
      color: 'green',
      icon: <IconResolveFlag scale={1} color="#2AC670" />,
    },
    {
      type: 'blockPost',
      label: 'Inappropriate Post (Block)',
      color: 'red',
      icon: <IconBlock scale={1} color="#FF6252" />,
    },
    {
      type: 'deletePost',
      label: 'Delete User Post',
      color: 'red',
      icon: <IconTrash scale={1} color="#FF6252" />,
    },
  ];

  return (
    <Card shadow="md" radius="sm" padding="30px">
      <Skeleton p={0} sx={{ zIndex: 1 }} visible={isLoading}>
        <Card p={0} sx={{ zIndex: isLoading ? 1 : 12, overflow: 'visible' }}>
          <Paper
            shadow="md"
            p="20px"
            mb={20}
            sx={{ border: '2px solid #7CA9FF', borderRadius: '8px' }}
          >
            <Text
              sx={{
                fontFamily: 'Rubik, sans-serif',
                fontSize: '18px',
                lineHeight: 'normal',
                color: '#7CA9FF',
              }}
              weight={500}
            >
              {currentFlagPostDetails.postDescription}
            </Text>
          </Paper>
          <Flex gap={25}>
            <Paper sx={{ width: '12%' }}>
              <Image
                src={currentFlagPostDetails.postImage}
                alt="Image"
                className="image-thumbnail"
                width={170}
                height={170}
                onClick={handleImageClick}
                sx={{ cursor: 'pointer' }}
              />
            </Paper>
            <Paper
              shadow="md"
              p="16px"
              sx={{ borderRadius: '8px', width: '88%' }}
            >
              <Text
                size="lg"
                weight={700}
                color={'#2E3646'}
                sx={{
                  marginBottom: 10,
                  fontFamily: 'Open Sans, sans-serif',
                  fontSize: '14px',
                  lineHeight: 'normal',
                  textTransform: 'uppercase',
                  opacity: 0.4,
                }}
              >
                description
              </Text>
              <Text
                size="md"
                color="#2E3646"
                weight={400}
                sx={{
                  fontFamily: 'Rubik, sans-serif',
                  fontSize: '14px',
                }}
              >
                {currentFlagPostDetails.description}
              </Text>
            </Paper>
          </Flex>
          {currentFlagPostDetails.flagStatus === 'pending' && (
            <Flex mt={20} gap={20} sx={{ width: '100%' }} justify="center">
              {actionButtons.map((button) => (
                <Button
                  key={button.type}
                  color={button.color}
                  variant="light"
                  leftIcon={button.icon}
                  sx={{
                    fontSize: '12px',
                    borderRadius: theme.radius.sm,
                    boxShadow:
                      button.color === 'red' ? redBoxShadow : theme.shadows.sm,
                    width: '170px',
                    fontFamily: 'Open Sans, sans-serif',
                  }}
                  fw={700}
                  onClick={() => openConfirmModal(button.type)}
                  disabled={isLoading}
                >
                  {button.label}
                </Button>
              ))}
            </Flex>
          )}
          {currentFlagPostDetails.flagStatus === 'accept' && (
            <Flex
              mt={20}
              gap={5}
              sx={{ width: '100%' }}
              justify="center"
              align="center"
            >
              <Text
                size="md"
                color="#000"
                weight={700}
                sx={{
                  fontFamily: 'Open Sans, sans-serif',
                  fontSize: '12px',
                }}
              >
                This post has been reviewed as an
              </Text>
              <Text
                size="md"
                color="#3CB841"
                weight={700}
                sx={{
                  fontFamily: 'Open Sans, sans-serif',
                  fontSize: '12px',
                }}
                align="center"
              >
                Appropriate Post
              </Text>
              <IconResolveFlag color="#3CB841" />
            </Flex>
          )}
          {currentFlagPostDetails.flagStatus === 'reject' && (
            <Flex
              mt={20}
              gap={5}
              sx={{ width: '100%' }}
              justify="center"
              align="center"
            >
              <Text
                size="md"
                color="#000"
                weight={700}
                sx={{
                  fontFamily: 'Open Sans, sans-serif',
                  fontSize: '12px',
                }}
              >
                This post has been reviewed as an
              </Text>
              <Text
                size="md"
                color="#FF6252"
                weight={700}
                sx={{
                  fontFamily: 'Open Sans, sans-serif',
                  fontSize: '12px',
                }}
                align="center"
              >
                Inappropriate Post
              </Text>
              <IconBlockFlag color="#FF6252" />
            </Flex>
          )}
        </Card>
      </Skeleton>
    </Card>
  );
};

export default FlaggedPostContent;
