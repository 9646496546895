import {
  Avatar,
  Box,
  Burger,
  Flex,
  Group,
  Header,
  MediaQuery,
  Menu,
  rem,
  Text,
  UnstyledButton,
  useMantineTheme,
} from '@mantine/core';
import { IconChevronDown } from '@tabler/icons-react';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as actionTypes from '../../../store/action-types';
import { APP_HEADER_HEIGHT } from '../../constants/constants';
import authService from '../../services/auth.service';
import { createAction } from '../../util/utility';
import Confirm from '../confirm/confirm';
import { AppLogo } from '../icons/icons';
import { useStyles } from './style';

interface ITopHeaderProps {
  opened: boolean;
  setOpened: (opened: boolean) => void;
}

const TopHeader: React.FC<ITopHeaderProps> = (props) => {
  const { opened, setOpened } = props;

  const { classes } = useStyles();
  const theme = useMantineTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isConfirmOpen, setIsConfirmOpen] = useState(false);

  const handleLogOut = () => {
    authService.removeAuthData();
    dispatch(createAction(actionTypes.AUTH_LOGOUT_SUCCESS));
  };

  const handleConfirmClose = () => {
    setIsConfirmOpen(false);
  };

  const handleConfirmLogout = () => {
    handleLogOut();
  };

  return (
    <Header
      height={`${APP_HEADER_HEIGHT}px`}
      pr="md"
      pl={{ sm: '0', base: 'md' }}
      sx={{
        backgroundColor: theme.colors.gray[1],
        border: 'none',
        zIndex: 99999,
        overflowY: 'hidden',
      }}
    >
      {isConfirmOpen && (
        <Confirm
          loading={false}
          opened={isConfirmOpen}
          modalTitle="Are you sure you want to Logout?"
          handleConfirm={handleConfirmLogout}
          handleClose={handleConfirmClose}
          cancelBtnText="Cancel"
          submitBtnText="Logout"
          size={'500px'}
        />
      )}
      <Flex
        className={classes.mainSection}
        justify={'space-between'}
        sx={{
          padding: 0,
          height: '100%',
          backgroundColor: theme.colors.gray[1],
        }}
      >
        <Flex
          sx={{
            height: '100%',
            backgroundColor: '#fff',
            width: '272px',
            boxShadow: '4px 0px 15px 0px #0000000D',
            borderRight: '0.0625rem solid #e9ecef',
            '@media (max-width: 767px)': {
              backgroundColor: 'transparent',
              boxShadow: 'unset',
              borderRight: 'none',
            },
          }}
          align={'center'}
          justify={'center'}
        >
          <MediaQuery largerThan="sm" styles={{ display: 'none' }}>
            <Burger
              opened={opened}
              onClick={() => setOpened(!opened)}
              size="sm"
              color={theme.colors.gray[6]}
              mr="xl"
            />
          </MediaQuery>
          <Box onClick={() => navigate(`/`)} sx={{ cursor: 'pointer' }}>
            <AppLogo />
          </Box>
        </Flex>
        <Flex sx={{ gap: '4px', height: '100%' }} align={'center'}>
          <Avatar
            radius="xl"
            color={theme.colors.blue[1]}
            size={'50px'}
            sx={{
              '.mantine-Avatar-placeholder': {
                backgroundColor: 'white',
              },
            }}
          >
            A
          </Avatar>
          <Menu
            width={130}
            position="bottom-end"
            transitionProps={{ transition: 'pop-top-right' }}
            withinPortal
          >
            <Menu.Target>
              <UnstyledButton className={classes.user}>
                <Group spacing={7} sx={{ gap: '44px' }}>
                  <Text
                    weight={500}
                    size="20px"
                    sx={{ lineHeight: 1, fontFamily: 'Open Sans' }}
                    mr={3}
                  >
                    Admin
                  </Text>
                  <IconChevronDown
                    style={{ gap: '204px' }}
                    size={rem(12)}
                    stroke={1.5}
                  />
                </Group>
              </UnstyledButton>
            </Menu.Target>
            <Menu.Dropdown
              sx={{ marginTop: 0, boxShadow: theme.shadows.md, border: 'none' }}
            >
              <Menu.Item p={15} fz={15} onClick={() => setIsConfirmOpen(true)}>
                Logout
              </Menu.Item>
            </Menu.Dropdown>
          </Menu>
        </Flex>
      </Flex>
    </Header>
  );
};

export default TopHeader;
