import {
  Badge,
  Box,
  Card,
  Flex,
  Group,
  Progress,
  Select,
  Skeleton,
  Text,
  Title,
  Tooltip,
  useMantineTheme,
} from '@mantine/core';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import CountUp from 'react-countup';
import { useNavigate } from 'react-router-dom';
import { IconDown, IconUp } from '../../../shared/components/icons/icons';
import PageHeader from '../../../shared/components/pageHeader/pageHeader';
import {
  API_CONFIG,
  DEFAULT_FORMAT,
  getSelectProps,
  initialDataForDashboardStatistics,
  initialDataForPopularChallengesCard,
  statisticsOptions,
} from '../../../shared/constants/constants';
import httpService from '../../../shared/services/http.service';
import {
  convertFirstLatterToCapital,
  convertUnixToGMT,
  changeMinutesToHours,
} from '../../../shared/util/utility';
import { ICountry, ICountryRes } from '../../users/interface/users.interface';
import ActiveTimesChart from '../component/activeTimesChart';
import UpcomingChallenge from '../component/upcomingChallenge';
import {
  IAgeGroup,
  IGeneralStatistics,
  IPopularChallenge,
  IUpComingChallenge,
} from '../interface/dashboard.interface';

const TrendIcon: any = {
  'remained the same': '',
  decreased: <IconDown />,
  increased: <IconUp />,
};

const TrendColor: any = {
  'remained the same': '',
  decreased: '#FF6252',
  increased: '#2AC670',
};

const TitleKeyMapper: any = {
  totalUser: 'TOTAL USERS',
  newUser: 'NEW USERS',
  activeUser: 'ACTIVE USERS',
  post: 'POST',
  likes: 'LIKES',
  comments: 'COMMENTS',
  avgUser: 'AVG TIME PER USER',
};

export const labelColor: any = {
  draw: '#7CA9FF',
  write: '#C57CFF',
  business: '#74D2CD',
  other: '#FF7CBB',
};

const Dashboard = () => {
  const [isUpComingChallengeLoading, setIsUpComingChallengeLoading] =
    useState<boolean>(false);
  const [isGeneralStatisticsLoading, setIsGeneralStatisticsLoading] =
    useState<boolean>(false);
  const [isPopularChallengeLoading, setIsPopularChallengeLoading] =
    useState<boolean>(false);
  const [isAgeGroupLoading, setIsAgeGroupLoading] = useState<boolean>(false);
  const [isChartInfoLoading, setIsChartInfoLoading] = useState<boolean>(false);
  const [isCountryLoading, setIsCountryLoading] = useState<boolean>(false);
  const [upComingChallengeList, setUpComingChallengeList] = useState<
    IUpComingChallenge[]
  >([]);
  const [ageGroupList, setAgeGroupList] = useState<IAgeGroup[]>([]);
  const [totalUserCount, setTotalUserCount] = useState(0);
  const [popularChallengeList, setPopularChallengeList] = useState<
    IPopularChallenge[]
  >(initialDataForPopularChallengesCard);
  const [generalStatisticsList, setGeneralStatisticsList] = useState<
    IGeneralStatistics[]
  >(initialDataForDashboardStatistics);
  const [countryList, setCountryList] = useState<ICountry[]>([]);
  const [selectedTimeline, setSelectedTimeline] = useState<string>(
    statisticsOptions[0].value
  );
  const [selectedGender, setSelectedGender] = useState<string>('');
  const [selectedCountry, setSelectedCountry] = useState<string>('');
  const [dataset1, setDataset1] = useState<number[]>([]);
  const [dataset2, setDataset2] = useState<number[]>([]);
  const theme = useMantineTheme();

  const navigate = useNavigate();

  const handleRedirect = (challengeId: string) => {
    navigate(`/challenges/${challengeId}`);
  };

  useEffect(() => {
    getUpComingChallengeList();
    getPopularChallengeList();
    getCountryList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getGeneralStatisticsInfo = useCallback(
    (statistics = selectedTimeline) => {
      setIsGeneralStatisticsLoading(true);
      httpService
        .get(API_CONFIG.path.dashboard, { statistics })
        .then((response) => {
          setIsGeneralStatisticsLoading(false);
          let totalUserData = {
            title: '',
            newData: 0,
            percentageCount: '',
            trend: '',
          };
          const modiFiedData = Object.entries(response.data).reduce(
            (acc: IGeneralStatistics[], [key, value]: [string, any]) => {
              if (key === 'totalUser') {
                totalUserData = {
                  ...value.data,
                  title: key,
                  newData: value.totalUser,
                };
              }
              acc.push({
                title: key,
                newData: value.userAnalytics,
                percentageCount: value.percentageCount,
                trend: value.trend,
              });
              return acc;
            },
            []
          );
          let newData = modiFiedData.map((item) => {
            if (item.title === 'totalUser') {
              return totalUserData;
            } else {
              return item;
            }
          });
          setGeneralStatisticsList(newData);
        })
        .catch((error) => {
          setIsGeneralStatisticsLoading(false);
          console.error(error);
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedTimeline]
  );

  useEffect(() => {
    getGeneralStatisticsInfo(selectedTimeline);
  }, [selectedTimeline, getGeneralStatisticsInfo]);

  const getPopularChallengeList = useCallback(() => {
    setIsPopularChallengeLoading(true);
    httpService
      .get(API_CONFIG.path.popularChallenges)
      .then((response) => {
        setIsPopularChallengeLoading(false);
        const transformedData = response.data.map((challenge: any) => ({
          description: challenge.description,
          label: challenge.label,
          challengeStatistics: {
            post: challenge.post,
            likes: challenge.likes,
            comments: challenge.comments,
          },
          challengeId: challenge.challengeId,
        }));
        setPopularChallengeList(transformedData);
      })
      .catch((error) => {
        setIsPopularChallengeLoading(false);
        setPopularChallengeList([]);
        console.error(error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getAgeGroupInfo = useCallback(
    (gender = selectedGender) => {
      setIsAgeGroupLoading(true);
      httpService
        .get(API_CONFIG.path.dashboardAgeGroup, { gender })
        .then((response) => {
          const totalUser = response.data
            .map(({ value }: IAgeGroup) => value)
            .reduce((a: number, c: number) => a + c, 0);
          setTotalUserCount(totalUser);
          setAgeGroupList(
            response.data.map((dt: IAgeGroup) => ({
              ...dt,
              ageRange: dt.ageRange.replace(/yr/g, ''),
              percentage: totalUser === 0 ? 0 : (dt.value * 100) / totalUser,
            }))
          );
          setIsAgeGroupLoading(false);
        })
        .catch((error) => {
          setIsAgeGroupLoading(false);
          console.error(error);
        });
    },
    [selectedGender]
  );

  useEffect(() => {
    getAgeGroupInfo(selectedGender);
  }, [selectedGender, getAgeGroupInfo]);

  const getCountryList = useCallback(
    () => {
      setIsCountryLoading(true);
      const detailUrl = `${API_CONFIG.path.user}/country`;
      httpService
        .get(detailUrl)
        .then((response) => {
          setIsCountryLoading(false);
          setCountryList(
            response.data.map((country: ICountryRes) => ({
              label: country.name,
              value: country.name,
              ...country,
            }))
          );
        })
        .catch((error) => {
          console.error(error);
          setIsCountryLoading(false);
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const getChartInfo = useCallback(
    (country = selectedCountry) => {
      setIsChartInfoLoading(true);
      httpService
        .get(API_CONFIG.path.dashboardChart, { country })
        .then((response) => {
          setIsChartInfoLoading(false);
          const dataset1: number[] = [];
          const dataset2: number[] = [];
          response.data.forEach(({ likeComment, posts }: any) => {
            dataset1.push(likeComment);
            dataset2.push(posts);
          });
          setDataset1(dataset1);
          setDataset2(dataset2);
        })
        .catch((error) => {
          setIsChartInfoLoading(false);
          console.error(error);
        });
    },
    [selectedCountry]
  );

  useEffect(() => {
    getChartInfo(selectedCountry);
  }, [selectedCountry, getChartInfo]);

  const getUpComingChallengeList = useCallback(() => {
    const currentDate = moment();
    const startDate = currentDate.add(1, 'day').format('YYYY-MM-DD');
    const endDate = currentDate.add(1, 'year').format('YYYY-MM-DD');
    setIsUpComingChallengeLoading(true);
    httpService
      .get(`${API_CONFIG.path.challenge}/all`, { startDate, endDate })
      .then((response) => {
        setIsUpComingChallengeLoading(false);
        const modiFiedData = response.data.map((item: any) => {
          return {
            ...item,
            scheduleDate: convertUnixToGMT(item.scheduleDate).format(
              DEFAULT_FORMAT
            ),
          };
        });
        setUpComingChallengeList(modiFiedData);
      })
      .catch((error) => {
        setIsUpComingChallengeLoading(false);
        console.error(error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const chartData = {
    labels: [
      '00-03AM',
      '03-06AM',
      '06-09AM',
      '09-12PM',
      '12-03PM',
      '03-06PM',
      '06-09PM',
      '09-12AM',
    ],
    datasets: [
      {
        label: 'Likes/Comments',
        borderRadius: 10,
        backgroundColor: '#54C5EB',
        hoverBackgroundColor: 'rgba(84, 197, 235, 0.5)',
        data: dataset1,
      },
      {
        label: 'Posts',
        backgroundColor: '#6160DC',
        borderRadius: 10,
        hoverBackgroundColor: 'rgba(97, 96, 220, 0.5)',
        data: dataset2,
      },
    ],
  };
  const timelineProps = getSelectProps(selectedTimeline);
  const genderProps = getSelectProps(selectedGender);
  const countryProps = getSelectProps(selectedCountry);

  return (
    <>
      <PageHeader title="Dashboard" />
      <Group position="apart">
        <Title
          opacity={0.6}
          sx={{ fontSize: '20px', fontWeight: 500, fontFamily: 'Open Sans' }}
        >
          General Statistics
        </Title>
        <Select
          name="selectTimeline"
          placeholder="Select Timeline"
          iconWidth={100}
          data={statisticsOptions}
          clearable
          searchable
          sx={{
            boxShadow: theme.shadows.sm,
            width: '155px',
            borderRadius: '8px',
            fontSize: '12px',
          }}
          defaultValue={'today'}
          onChange={(value) => {
            setSelectedTimeline(value || '');
          }}
          transitionProps={{
            transition: 'pop-top-left',
            duration: 100,
            timingFunction: 'ease',
          }}
          {...timelineProps}
        />
      </Group>

      {generalStatisticsList.length === 0 ? (
        <Card>
          <Text
            align="center"
            sx={{
              fontSize: '16px',
              fontWeight: 500,
              fontFamily: 'Open Sans',
            }}
          >
            General statistical data is not available.
          </Text>
        </Card>
      ) : (
        <Flex
          mt={20}
          gap={20}
          wrap={'wrap'}
          sx={{
            margin: '0 auto',
            marginRight: '-20px',
            '@media (max-width: 1440px)': {
              marginRight: '-7px',
              gap: '7px',
            },
          }}
        >
          {generalStatisticsList.map((item, index) => (
            <Skeleton
              key={index}
              mt={10}
              visible={isGeneralStatisticsLoading}
              width={`calc(calc(100% / ${generalStatisticsList.length}) - 20px)`}
              sx={{
                minHeight: '129px',
                '@media (max-width: 1440px)': {
                  width: `calc(calc(100% / ${generalStatisticsList.length}) - 7px)`,
                  zIndex: 1,
                },
              }}
            >
              {!isGeneralStatisticsLoading && (
                <Card
                  key={index}
                  className={'animate__animated animate__fadeIn'}
                  sx={{
                    width: '100%',
                    display: 'flex',
                    minHeight: '100%',
                    '@media (max-width: 1440px)': {
                      padding: '9px',
                      zIndex: 12,
                    },
                  }}
                  display={'flex'}
                  shadow="sm"
                >
                  <Flex direction={'column'}>
                    <Title
                      color="#8491A5"
                      order={5}
                      sx={{
                        fontWeight: 500,
                        marginBottom: '8px',
                        fontFamily: 'Open Sans',
                        '@media (max-width: 1440px)': {
                          fontSize: '14px',
                        },
                      }}
                    >
                      {TitleKeyMapper[item.title]}
                    </Title>
                    <Text
                      mt={15}
                      weight={700}
                      size="26px"
                      style={{ marginTop: '8px' }}
                    >
                      <CountUp
                        end={item.newData}
                        start={0}
                        formattingFn={(value) =>
                          item.title === 'avgUser'
                            ? changeMinutesToHours(value)
                            : value.toString()
                        }
                      />
                    </Text>
                    <Text mt={15} color={TrendColor[item.trend]}>
                      {TrendIcon[item.trend]}
                      {item.percentageCount}
                    </Text>
                  </Flex>
                </Card>
              )}
            </Skeleton>
          ))}
        </Flex>
      )}

      <Title
        mt={20}
        opacity={0.6}
        sx={{ fontSize: '20px', fontWeight: 500, fontFamily: 'Open Sans' }}
      >
        Top 5 Challenges
      </Title>

      {popularChallengeList.length === 0 ? (
        <Card mt={20}>
          <Text
            align="center"
            sx={{
              fontSize: '16px',
              fontWeight: 500,
              fontFamily: 'Open Sans',
              minWidth: '276px',
              minHeight: '236px',
              alignItems: 'center',
              display: 'flex',
              alignItem: 'center',
              justifyContent: 'center',
            }}
          >
            There are no popular challenges available.
          </Text>
        </Card>
      ) : (
        <Flex
          mt={22}
          gap={10}
          wrap={'wrap'}
          sx={{ margin: '0 auto', marginRight: '-10px' }}
        >
          {popularChallengeList.map((item, index) => (
            <Skeleton
              key={index}
              mt={10}
              visible={isPopularChallengeLoading}
              width={`calc(calc(100% / 5) - 10px)`}
              sx={{
                minWidth: '276px',
                minHeight: '236px',
                zIndex: 1,
              }}
            >
              {!isPopularChallengeLoading && (
                <Card
                  className={'animate__animated animate__fadeIn'}
                  sx={{
                    width: '100%',
                    display: 'flex',
                    backgroundColor: `${labelColor[item.label]}`,
                    cursor: 'pointer',
                    zIndex: 12,
                  }}
                  display={'flex'}
                  shadow="sm"
                  onClick={() => handleRedirect(item.challengeId)}
                >
                  <Flex direction={'column'} sx={{ width: '100%' }}>
                    <Tooltip label={item.description} key={index} withArrow>
                      <Title
                        lineClamp={1}
                        color="#FFFFFF"
                        order={4}
                        sx={{
                          fontSize: '20px',
                          fontWeight: 700,
                          marginBottom: '20px',
                          fontFamily: 'Open Sans',
                        }}
                      >
                        {item.description}
                      </Title>
                    </Tooltip>
                    <Badge
                      sx={{
                        width: 'max-content',
                        fontWeight: 600,
                        fontFamily: 'Open Sans',
                        fontSize: '14px',
                        backgroundColor: '#FEA700',
                      }}
                      size={'xl'}
                    >
                      <Text
                        weight={600}
                        size={'14px'}
                        color="#FFFFFF"
                        sx={{ fontFamily: 'Open Sans' }}
                      >
                        Popular
                      </Text>
                    </Badge>
                    <Group mt={25}>
                      {Object.entries(item.challengeStatistics || []).map(
                        ([key, value]) => {
                          return (
                            <Box key={key} sx={{ width: '100%' }}>
                              <Group key={key} spacing="15px" position="apart">
                                <Text
                                  size="14px"
                                  color={'#FFFFFF'}
                                  weight={500}
                                  sx={{
                                    fontFamily: 'Open Sans, sans-serif',
                                    lineHeight: '22px',
                                    width: '30%',
                                  }}
                                >
                                  {convertFirstLatterToCapital(key)}
                                </Text>
                                <Text
                                  size="14px"
                                  color={'#FFFFFF'}
                                  weight={500}
                                  sx={{
                                    fontFamily: 'Open Sans, sans-serif',
                                    lineHeight: '22px',
                                    textAlign: 'end',
                                    width: '50%',
                                  }}
                                >
                                  {value}
                                </Text>
                              </Group>
                            </Box>
                          );
                        }
                      )}
                    </Group>
                  </Flex>
                </Card>
              )}
            </Skeleton>
          ))}
        </Flex>
      )}

      <Group
        position="apart"
        align="stretch"
        sx={{ '*': { fontFamily: 'Open Sans' } }}
        mt={20}
      >
        <Card
          shadow="md"
          p={20}
          sx={{ width: 'calc(50% - 18px)', minHeight: '300px' }}
        >
          <Group position="apart">
            <Title
              sx={{
                fontSize: '20px',
                fontWeight: 600,
                fontFamily: 'Open Sans',
              }}
            >
              Age Group
            </Title>
            <Select
              name="selectGender"
              placeholder="Select Gender"
              iconWidth={100}
              data={[
                { value: 'male', label: 'Male' },
                { value: 'female', label: 'Female' },
                { value: 'non-binary', label: 'Non-Binary' },
                { value: 'other', label: 'Other' },
              ]}
              sx={{
                width: '155px',
                borderRadius: '8px',
                fontSize: '12px',
              }}
              clearable
              searchable
              onChange={(value) => {
                setSelectedGender(value || '');
              }}
              transitionProps={{
                transition: 'pop-top-left',
                duration: 100,
                timingFunction: 'ease',
              }}
              {...genderProps}
            />
          </Group>
          <Skeleton
            mt={10}
            visible={isAgeGroupLoading && ageGroupList.length === 0}
            height={'calc(100% - 56px)'}
          >
            {ageGroupList.length === 0 ? (
              <Card
                display={'flex'}
                sx={{
                  justifyContent: 'center',
                  alignItems: 'center',
                  minHeight: '300px',
                  position: 'relative',
                }}
              >
                <Text
                  align="center"
                  sx={{
                    fontSize: '16px',
                    fontWeight: 500,
                    fontFamily: 'Open Sans',
                  }}
                >
                  There are no age group data available.
                </Text>
              </Card>
            ) : (
              <Card sx={{ zIndex: 12 }}>
                <Flex
                  className={'animate__animated animate__fadeIn'}
                  justify={'space-around'}
                  direction={'column'}
                  mt={10}
                  sx={{ height: 'auto' }}
                >
                  {ageGroupList?.map((item, index) => (
                    <Group
                      key={index}
                      position="apart"
                      my={'15px'}
                      sx={{ width: '100%' }}
                    >
                      <Text
                        size="12px"
                        color={theme.colors.gray[8]}
                        weight={400}
                        sx={{
                          lineHeight: '22px',
                          opacity: 0.5,
                          width: '40px',
                        }}
                      >
                        {item.ageRange}
                      </Text>
                      <Progress
                        sections={[
                          {
                            value: item.percentage as number,
                            color: 'cyan',
                            label: item.value.toString(),
                            tooltip: `${item.value} users out of ${totalUserCount}`,
                          },
                        ]}
                        size={20}
                        sx={{ width: '88%' }}
                        radius={'xs'}
                      />
                    </Group>
                  ))}
                </Flex>
              </Card>
            )}
          </Skeleton>
        </Card>

        <Card shadow="md" p={20} sx={{ width: 'calc(50% - 18px)' }}>
          <Group position="apart">
            <Title
              sx={{
                fontSize: '20px',
                fontWeight: 600,
                fontFamily: 'Open Sans',
              }}
            >
              Today’s Most Active Times
            </Title>
            <Select
              name="selectCountry"
              placeholder="Select Country"
              iconWidth={100}
              data={countryList ? countryList.map((item) => item.value) : []}
              sx={{
                width: '155px',
                borderRadius: '8px',
                fontSize: '12px',
              }}
              searchable
              clearable
              onChange={(country) => {
                if (country) {
                  setSelectedCountry(country);
                } else {
                  setSelectedCountry('');
                }
              }}
              transitionProps={{
                transition: 'pop-top-left',
                duration: 100,
                timingFunction: 'ease',
              }}
              {...countryProps}
            />
          </Group>
          <Box
            display={'flex'}
            className={'animate__animated animate__fadeIn'}
            sx={{
              justifyContent: 'center',
              alignItems: 'center',
              minHeight: '300px',
              position: 'relative',
            }}
          >
            <Skeleton
              mt={10}
              visible={
                (isChartInfoLoading || isCountryLoading) &&
                dataset1.length === 0 &&
                dataset2.length === 0
              }
              height={'calc(100% - 56px)'}
            >
              {(dataset1.length === 0 || dataset2.length === 0) &&
              !isChartInfoLoading ? (
                <Card>
                  <Text
                    align="center"
                    sx={{
                      fontSize: '16px',
                      fontWeight: 500,
                      fontFamily: 'Open Sans',
                    }}
                  >
                    Today’s most active times data is not available.
                  </Text>
                </Card>
              ) : (
                <ActiveTimesChart key={selectedCountry} chartData={chartData} />
              )}
            </Skeleton>
          </Box>
        </Card>
      </Group>

      <Title
        mt={20}
        opacity={0.5}
        sx={{ fontSize: '20px', fontWeight: 500, fontFamily: 'Open Sans' }}
      >
        Upcoming Challenges
      </Title>

      <UpcomingChallenge
        upComingChallengeList={upComingChallengeList}
        loading={isUpComingChallengeLoading}
        handleRedirect={handleRedirect}
      />
    </>
  );
};

export default Dashboard;
