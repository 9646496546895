import {
  Box,
  Button,
  Card,
  Flex,
  Grid,
  Group,
  Image,
  Skeleton,
  Text,
} from '@mantine/core';
import { useDebouncedValue } from '@mantine/hooks';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Confirm from '../../../shared/components/confirm/confirm';
import {
  IconBlock,
  IconEdit,
  IconTrash,
} from '../../../shared/components/icons/icons';
import ImagePreviewModal from '../../../shared/components/imagePreviewModal/ImagePreviewModal';
import PageHeader from '../../../shared/components/pageHeader/pageHeader';
import PaginationComponent from '../../../shared/components/pagination/pagination';
import {
  API_CONFIG,
  getSearchProps,
  redBoxShadow,
} from '../../../shared/constants/constants';
import { IPagination } from '../../../shared/interface';
import httpService from '../../../shared/services/http.service';
import {
  convertFirstLatterToCapital,
  formatUnixTimestamp,
  unixTimestampToFormattedDate,
} from '../../../shared/util/utility';
import UserPostsList from '../components/userPostsList';
import {
  ICountry,
  ICountryRes,
  IStateInfo,
  IStateInfoRes,
  IUserPostInfo,
  IUsersDetailsInfo,
  IUsersDetailsInfoRes,
} from '../interface/users.interface';
import EditUserModal from '../modals/editUserModal';
import '../styles/styles.scss';

interface AccountDetails {
  createdAt: string;
  loginType: string;
  reportedCount: number;
}

const commonTextStyle = {
  color: '#2E3646',
  fontWeight: 400,
  width: '80%',
  fontFamily: 'Open Sans, sans-serif',
  lineHeight: '22px',
};

const cardHeaderStyle = {
  marginBottom: '16px',
  fontFamily: 'Open Sans, sans-serif',
  fontWeight: 700,
  fontSize: '14px',
};

const excludedKeys = [
  'loggedInInfo',
  'accountDetails',
  'profileImage',
  'name',
  'createdAt',
  'username',
  'id',
  'status',
  'post',
  'country',
  'state',
];

const UsersDetails: React.FC = () => {
  const { userId }: any = useParams();
  const navigate = useNavigate();
  const [currentUserDetails, setCurrentUserDetails] =
    useState<IUsersDetailsInfo>({} as IUsersDetailsInfo);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingPost, setIsLoadingPost] = useState(false);
  const [isLoadingAction, setIsLoadingAction] = useState(false);
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const [isBlockUserConfirmOpen, setIsBlockUserConfirmOpen] = useState(false);
  const [isEditUserOpen, setIsEditUserOpen] = useState(false);
  const [userPosts, setUserPosts] = useState<IUserPostInfo[]>([]);
  const [countryList, setCountryList] = useState<ICountry[]>([]);
  const [isCountryLoading, setIsCountryLoading] = useState<boolean>(false);
  const [stateList, setStateList] = useState<IStateInfo[]>([]);
  const [isStateLoading, setIsStateLoading] = useState<boolean>(false);
  const [selectedCountry, setSelectedCountry] = useState<string>('');
  const [showImagePreview, setShowImagePreview] = useState(false);
  const [pagination, setPagination] = useState<IPagination>({
    currentPage: 1,
    nextPage: null,
    recordPerPage: 5,
    remainingCount: 0,
    total: 0,
    totalPages: 1,
  });
  const [postSearch, setPostSearch] = useState('');
  const [debouncedPostSearch] = useDebouncedValue(
    postSearch,
    postSearch ? 700 : 0
  );
  useEffect(() => {
    getUserDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isEditUserOpen && countryList.length === 0) {
      getCountryList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditUserOpen]);

  useEffect(() => {
    const selectedCountryString =
      selectedCountry || currentUserDetails.country || '';
    const countryId = countryList.find(
      (country: ICountry) =>
        country.name.toLowerCase() === selectedCountryString.toLowerCase()
    );
    if (countryId) {
      getStateList(countryId.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCountry, currentUserDetails.country, countryList]);

  // UserSearch
  useEffect(() => {
    const hasOnlySpaces = /^ *$/.test(debouncedPostSearch);
    if (!hasOnlySpaces) {
      getUserPosts(1, pagination.recordPerPage, debouncedPostSearch);
    } else if (debouncedPostSearch === '') {
      getUserPosts(1, pagination.recordPerPage, '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedPostSearch]);

  // Get User Details
  const getUserDetails = useCallback(
    () => {
      setIsLoading(true);
      const detailUrl = `${API_CONFIG.path.users}/${userId}`;
      httpService
        .get(detailUrl)
        .then((response) => {
          const userDetailsData: IUsersDetailsInfoRes = response.data;
          setIsLoading(false);
          const modifiedDetails: IUsersDetailsInfo = {
            id: userId,
            name: userDetailsData.name,
            username: userDetailsData.username,
            email: userDetailsData.email,
            profileImage:
              userDetailsData.profilePhoto ||
              'https://d3gf7zawoqsit4.cloudfront.net/user/150/default.png',
            birthday: userDetailsData.birthday,
            age: userDetailsData.age,
            gender: userDetailsData.gender,
            location: `${convertFirstLatterToCapital(
              userDetailsData.state
            )}, ${convertFirstLatterToCapital(userDetailsData.country)}`,
            country: userDetailsData.country,
            state: userDetailsData.state,
            'Notification Preference': `${userDetailsData.dailyChallengeTime} ${userDetailsData.dailyChallengeTime === 5 ? 'PM' : 'AM'
              }`,
            description: userDetailsData.about ? userDetailsData.about : ' ',
            createdAt: userDetailsData.createdAt
              ? unixTimestampToFormattedDate(userDetailsData.createdAt)
              : '',
            loggedInInfo: [],
            accountDetails: {
              createdAt: userDetailsData.createdAt
                ? unixTimestampToFormattedDate(userDetailsData.createdAt)
                : '-',
              loginType: convertFirstLatterToCapital(userDetailsData.loginType),
              reportedCount: userDetailsData.reportedUser,
            },
            isBlocked: userDetailsData.isBlocked,
            status: userDetailsData.isBlocked ? 'Block' : 'Active',
            post: pagination.total,
          };

          const modifiedLoggedInInfo =
            userDetailsData && userDetailsData.userDeviceInformation.length > 0
              ? userDetailsData.userDeviceInformation.map((device) => {
                const key = device.userDeviceDeviceOs.toUpperCase();
                const value = device.userDeviceCreatedAt;
                return { [key]: value };
              })
              : [];

          modifiedDetails.loggedInInfo = modifiedLoggedInInfo;

          setCurrentUserDetails(modifiedDetails);
        })
        .catch((error) => {
          console.error(error);
          setIsLoading(false);
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  // Block User
  const blockUser = useCallback(
    (isBlocked: boolean = false) => {
      setIsLoadingAction(true);
      const detailUrl = `${API_CONFIG.path.users}/${userId}/status`;
      httpService
        .put(detailUrl, {
          isBlocked,
        })
        .then(() => {
          setIsLoadingAction(false);
          setIsBlockUserConfirmOpen(false);
          getUserDetails();
        })
        .catch((error) => {
          console.error(error);
          setIsLoadingAction(false);
          setIsBlockUserConfirmOpen(false);
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  // Delete User
  const deleteUser = useCallback(
    () => {
      setIsLoadingAction(true);
      const detailUrl = `${API_CONFIG.path.users}/${userId}`;
      httpService
        .deleteRequest(detailUrl)
        .then(() => {
          setIsLoadingAction(false);
          setIsConfirmOpen(false);
          getUserDetails();
          handleUserListView();
        })
        .catch((error) => {
          console.error(error);
          setIsConfirmOpen(false);
          setIsLoadingAction(false);
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  // Edit User
  const editUser = useCallback(
    (userId: string, data: any) => {
      setIsLoadingAction(true);
      const detailUrl = `${API_CONFIG.path.users}/${userId}`;
      httpService
        .put(detailUrl, data)
        .then(() => {
          setIsEditUserOpen(false);
          setIsLoadingAction(false);
          getUserDetails();
        })
        .catch((error) => {
          console.error(error);
          setIsLoadingAction(false);
          setIsEditUserOpen(false);
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  // Get User Posts
  const getUserPosts = useCallback(
    (
      currentPage = pagination.currentPage,
      recordPerPage = pagination.recordPerPage,
      search = ''
    ) => {
      setIsLoadingPost(true);
      const detailUrl = `${API_CONFIG.path.users}/post-list/${userId}`;
      httpService
        .get(detailUrl, {
          currentPage,
          recordPerPage,
          search,
        })
        .then((response) => {
          const { data, ...paginationData } = response.data;
          setPagination(paginationData);
          setUserPosts(data);
          setIsLoadingPost(false);
        })
        .catch((error) => {
          console.error(error);
          setIsLoadingPost(false);
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [pagination]
  );

  // Get Country List
  const getCountryList = useCallback(
    () => {
      setIsCountryLoading(true);
      const detailUrl = `${API_CONFIG.path.user}/country`;
      httpService
        .get(detailUrl)
        .then((response) => {
          setIsCountryLoading(false);
          setCountryList(
            response.data.map((country: ICountryRes) => ({
              label: country.name,
              value: country.name,
              ...country,
            }))
          );
        })
        .catch((error) => {
          console.error(error);
          setIsCountryLoading(false);
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  // Get States List
  const getStateList = useCallback(
    (countryId: number) => {
      setIsStateLoading(true);
      const detailUrl = `${API_CONFIG.path.user}/state/${countryId}`;
      httpService
        .get(detailUrl)
        .then((response) => {
          const sortedStates = response.data
            .map((state: IStateInfoRes) => ({
              label: state.name,
              value: state.name,
              ...state,
            }))
            .sort((a: IStateInfoRes, b: IStateInfoRes) =>
              a.name.localeCompare(b.name)
            );
          setStateList(sortedStates);
          setIsStateLoading(false);
        })
        .catch((error) => {
          console.error(error);
          setIsStateLoading(false);
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedCountry]
  );

  const handlePagination = useCallback(
    (page: number) => {
      if (page !== pagination.currentPage) {
        getUserPosts(
          page,
          pagination.recordPerPage,
          debouncedPostSearch ? debouncedPostSearch.trim() : ''
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [debouncedPostSearch, pagination]
  );

  const handleBlockUser = () => {
    blockUser(!currentUserDetails.isBlocked);
  };

  const handleBlockUserConfirmClose = () => {
    setIsBlockUserConfirmOpen(false);
  };

  const handleConfirmClose = () => {
    setIsConfirmOpen(false);
  };

  const handleConfirmDelete = () => {
    deleteUser();
  };

  const handleEditUser = (values: any) => {
    editUser(userId, values);
  };

  const handleSelectedCountry = (country: string) => {
    setSelectedCountry(country);
  };

  const handleSearch = (value: string) => setPostSearch(value);

  const handleAction = (type: string) => {
    if (type) {
      setIsEditUserOpen(true);
    }
  };

  const handleUserListView = () => {
    navigate(`/users`);
  };

  // Function to render each user info item dynamically
  const renderUserInfoItems = () => {
    return Object.entries(currentUserDetails).map(([key, value]) => {
      const isDescription = key === 'description';
      if (excludedKeys.includes(key)) return null;

      if (typeof value === 'string' || typeof value === 'number') {
        return (
          <Skeleton visible={isLoading}>
            <Group
              key={key}
              spacing="sm"
              position="apart"
              mb={10}
              align="flex-start"
            >
              <Text
                size="sm"
                sx={{
                  ...commonTextStyle,
                  opacity: 0.5,
                  width: '23%',
                }}
              >
                {convertFirstLatterToCapital(key)}
              </Text>
              <Text
                size="sm"
                sx={{
                  ...commonTextStyle,
                  textAlign: isDescription ? 'justify' : 'end',
                  maxWidth: isDescription ? '311px' : 'auto',
                  fontFamily: isDescription
                    ? 'Rubik, sans-serif'
                    : 'Open Sans, sans-serif',
                  width: '73%',
                  minHeight: isDescription ? '60px' : 'auto',
                }}
              >
                {value}
              </Text>
            </Group>
          </Skeleton>
        );
      }
      return null;
    });
  };

  // Function to render the loggedInInfo in a separate card
  const renderLoggedInInfoCard = () => {
    const loggedInInfo = currentUserDetails.loggedInInfo;

    return (
      <Card
        shadow="md"
        sx={{ marginBottom: '8px', minHeight: 'calc(100% - 8px)' }}
      >
        <Text align="start" size="xl" weight={700} sx={{ ...cardHeaderStyle }}>
          LOGGED IN INFO
        </Text>
        <Skeleton sx={{ zIndex: 1 }} visible={isLoading}>
          {loggedInInfo && loggedInInfo.length > 0 ? (
            <Card p={0} sx={{ zIndex: isLoading ? 1 : 12 }}>
              <Group sx={{}} spacing="md">
                {loggedInInfo.map((item, index) => {
                  return (
                    <Box
                      key={`loggedInInfo-${index}`}
                      sx={{
                        width: '100%',
                        margin: 0,
                        padding: 0,
                      }}
                    >
                      {Object.entries(item).map(
                        ([platform, loginTime], idx) => {
                          return (
                            <Group
                              key={`${platform}-${loginTime}-${idx}`}
                              spacing="md"
                              position="apart"
                            >
                              <Text
                                size="sm"
                                sx={{
                                  ...commonTextStyle,
                                  opacity: 0.5,
                                  width: '20%',
                                }}
                              >
                                {platform}
                              </Text>
                              <Text
                                size="sm"
                                sx={{
                                  ...commonTextStyle,
                                  textAlign: 'end',
                                  width: '75%',
                                }}
                              >
                                {formatUnixTimestamp(loginTime)}
                              </Text>
                            </Group>
                          );
                        }
                      )}
                    </Box>
                  );
                })}
              </Group>
            </Card>
          ) : (
            <Card
              p={0}
              sx={{
                minHeight: '100%',
                zIndex: 12,
                textAlign: 'center',
              }}
            >
              <Text
                size="sm"
                sx={{
                  ...commonTextStyle,
                  opacity: 0.5,
                  width: '100%',
                }}
              >
                No Details Available
              </Text>
            </Card>
          )}
        </Skeleton>
      </Card>
    );
  };

  // Function to render the accountDetails in a separate card
  const renderAccountDetailsCard = () => {
    const accountDetails = currentUserDetails.accountDetails;
    if (!accountDetails) return null;
    // Mapping function for account details keys
    const mapAccountDetailsKeys = (key: keyof AccountDetails): string => {
      const keyMappings: { [key in keyof AccountDetails]: string } = {
        createdAt: 'Account Created',
        loginType: 'Login Type',
        reportedCount: 'No of times User has been reported',
        // ... add more mappings if needed
      };
      return keyMappings[key] || key;
    };

    return (
      <Card shadow="md" sx={{ marginTop: '8px', height: 'calc(100% - 8px)' }}>
        <Text align="start" size="xl" weight={700} sx={{ ...cardHeaderStyle }}>
          ACCOUNT DETAILS
        </Text>
        <Skeleton visible={isLoading}>
          {Object.entries(accountDetails).map(([key, value], idx) => {
            const mappedKey = mapAccountDetailsKeys(
              key as keyof AccountDetails
            ); // Map the key

            if (typeof value === 'string' || typeof value === 'number') {
              return (
                <Group
                  key={key}
                  spacing="sm"
                  position="apart"
                  mb={9}
                  align={'flex-start'}
                >
                  <Text
                    size="sm"
                    sx={{
                      ...commonTextStyle,
                      opacity: 0.5,
                      width: '40%',
                    }}
                  >
                    {mappedKey.charAt(0).toUpperCase() + mappedKey.slice(1)}
                  </Text>
                  <Text
                    size="sm"
                    sx={{
                      ...commonTextStyle,
                      textAlign: 'end',
                      width: '50%',
                    }}
                  >
                    {value}
                  </Text>
                </Group>
              );
            }
            return null;
          })}
        </Skeleton>
      </Card>
    );
  };

  const noDataFound =
    !isLoading && Object.keys(currentUserDetails).length === 0;

  const disableBtn = isLoading || isLoadingAction || noDataFound;

  const actionButtons = [
    {
      label: 'Edit User',
      icon: <IconEdit color="white" />,
      type: 'edit',
      handleAction: handleAction,
      disabled: disableBtn,
    },
  ];
  const searchProps = getSearchProps(postSearch, () => {
    setPostSearch('');
  });

  return (
    <Box sx={{ margin: 0, padding: 0 }}>
      <PageHeader
        title="Users Details"
        showBackButton
        showActionButtons={actionButtons}
      />
      {currentUserDetails.isBlocked && (
        <Group
          align="center"
          position="apart"
          mb={30}
          sx={{
            height: '37px',
          }}
        >
          <Group>
            <Text
              size="xl"
              weight={700}
              sx={{
                fontFamily: 'Inter, sans-serif',
                fontSize: '18px',
                color: '#000',
                fontWeight: 400,
              }}
            >
              This user has been{' '}
              <Text component="span" sx={{ color: '#FF6252', fontWeight: 700 }}>
                blocked
              </Text>{' '}
              . Click{' '}
              <Text component="span" sx={{ fontWeight: 700 }}>
                Unblock User
              </Text>{' '}
              to unblock them from the app
            </Text>
          </Group>
          <Group>
            <Button
              color="dark"
              variant="light"
              rel="noopener noreferrer"
              onClick={() => setIsBlockUserConfirmOpen(true)}
              sx={{
                borderRadius: '10px',
                boxShadow: redBoxShadow,
                width: '170px',
                fontFamily: 'Open Sans, sans-serif',
              }}
              loading={isLoadingAction}
            >
              Unblock User
            </Button>
          </Group>
        </Group>
      )}
      <Box mb={30}>
        <Grid>
          <Grid.Col span={12} mb={30}>
            <Card shadow="md" radius={'sm'} padding={'lg'}>
              <Skeleton sx={{ zIndex: 1 }} p={0} visible={isLoading}>
                <Card
                  sx={{ zIndex: isLoading ? 1 : 12, overflow: 'visible' }}
                  p={0}
                >
                  <Group align="center" position="apart">
                    <Flex align={'center'}>
                      <Image
                        src={currentUserDetails.profileImage}
                        alt={currentUserDetails.name}
                        onClick={() => setShowImagePreview(true)}
                        className="image-thumbnail"
                        width={100}
                        height={100}
                      />
                      <Box ml={16}>
                        <Text
                          size="lg"
                          weight={600}
                          color={'#2E3646'}
                          sx={{
                            marginBottom: 10,
                            fontFamily: 'Open Sans, sans-serif',
                            fontWeight: 600,
                            fontSize: '25px',
                            lineHeight: 'normal',
                          }}
                        >
                          {currentUserDetails?.name
                            ? currentUserDetails?.name
                            : '-'}
                        </Text>
                        <Text
                          size="md"
                          color="dimmed"
                          weight={400}
                          sx={{
                            fontFamily: 'Open Sans, sans-serif',
                            fontWeight: 400,
                            fontSize: '20px',
                          }}
                        >
                          {currentUserDetails?.username
                            ? currentUserDetails?.username
                            : '-'}
                        </Text>
                      </Box>
                    </Flex>
                    <Flex align={'center'} gap="sm">
                      {!currentUserDetails.isBlocked && (
                        <Button
                          color="red"
                          variant="light"
                          rel="noopener noreferrer"
                          leftIcon={<IconBlock color="#FF6252" />}
                          onClick={() => setIsBlockUserConfirmOpen(true)}
                          sx={{
                            borderRadius: '10px',
                            boxShadow: redBoxShadow,
                            width: '170px',
                            fontFamily: 'Open Sans, sans-serif',
                          }}
                          loading={isLoadingAction}
                        >
                          Block User
                        </Button>
                      )}
                      <Button
                        color="red"
                        variant="light"
                        rel="noopener noreferrer"
                        leftIcon={<IconTrash color="#FF6252" />}
                        onClick={() => setIsConfirmOpen(true)}
                        sx={{
                          borderRadius: '10px',
                          boxShadow: redBoxShadow,
                          width: '170px',
                          fontFamily: 'Open Sans, sans-serif',
                        }}
                        loading={isLoadingAction}
                      >
                        Delete User
                      </Button>
                    </Flex>
                  </Group>
                </Card>
              </Skeleton>
            </Card>
          </Grid.Col>
        </Grid>
        <Grid>
          <Grid.Col span={6}>
            <Card shadow="sm" radius="sm" sx={{ height: '100%' }}>
              <Text
                align="start"
                size="xl"
                weight={700}
                sx={{ ...cardHeaderStyle }}
              >
                USER INFO
              </Text>
              {renderUserInfoItems()}
            </Card>
          </Grid.Col>
          <Grid.Col span={6}>
            <Grid.Col span={12} p={0} sx={{ height: '50%' }}>
              {renderLoggedInInfoCard()}
            </Grid.Col>
            <Grid.Col span={12} p={0} sx={{ height: '50%' }}>
              {renderAccountDetailsCard()}
            </Grid.Col>
          </Grid.Col>
        </Grid>
      </Box>
      <UserPostsList
        userPostsData={userPosts}
        loading={isLoadingPost}
        pagination={pagination}
        postSearch={postSearch}
        handleSearch={handleSearch}
        searchProps={searchProps}
      />
      {pagination.totalPages > 1 && (
        <PaginationComponent
          pagination={pagination}
          fetchData={handlePagination}
          loading={isLoading}
        />
      )}
      <Confirm
        loading={isLoadingAction}
        opened={isBlockUserConfirmOpen}
        modalTitle={`Are you sure you want to ${currentUserDetails.isBlocked ? 'unblock' : 'block'
          } this user?`}
        handleConfirm={handleBlockUser}
        handleClose={handleBlockUserConfirmClose}
        cancelBtnText="Cancel"
        submitBtnText={`Yes, ${currentUserDetails.isBlocked ? 'Unblock' : 'Block'
          }`}
        size={'450px'}
      />
      <Confirm
        loading={isLoadingAction}
        opened={isConfirmOpen}
        modalTitle="Are you sure you want to Delete the user?"
        handleConfirm={handleConfirmDelete}
        handleClose={handleConfirmClose}
        cancelBtnText="Cancel"
        submitBtnText="Yes, Delete"
        size={'450px'}
      />
      <ImagePreviewModal
        imageLink={currentUserDetails.profileImage}
        loading={isLoading}
        opened={showImagePreview}
        handleClose={() => setShowImagePreview(false)}
        className="image-preview-modal"
      />
      <EditUserModal
        key={JSON.stringify(currentUserDetails)}
        loading={isLoadingAction}
        isStateLoading={isStateLoading}
        isCountryLoading={isCountryLoading}
        opened={isEditUserOpen}
        handleClose={() => setIsEditUserOpen(false)}
        handleSubmit={handleEditUser}
        countryList={countryList}
        stateList={stateList}
        userDetails={currentUserDetails}
        handleLocation={handleSelectedCountry}
        className="edit-user-modal"
      />
    </Box>
  );
};

export default UsersDetails;
