import * as d3 from 'd3';
import React, { useEffect, useRef } from 'react';

import { convertFirstLatterToCapital } from '../../../shared/util/utility';
import { IChartData } from '../interface/dailyChallenges.interface';

interface BarChartProps {
  chartData: IChartData[];
  chartWidth: number;
}

const BarChart: React.FC<BarChartProps> = (props) => {
  const { chartData, chartWidth } = props;

  const svgRef = useRef<SVGSVGElement | null>(null);

  useEffect(() => {
    if (!chartData || chartData.length === 0 || !chartWidth) return;

    const barWidth = chartWidth;
    const margin = { top: 20, right: 20, bottom: 30, left: 35 },
      width = barWidth - margin.left - margin.right,
      height = 400 - margin.top - margin.bottom;

    const paddingBetweenBars = chartWidth >= 1650 ? 1000 : 700;

    var x = d3
      .scaleBand()
      .range([0, width])
      .paddingInner(paddingBetweenBars / width)
      .paddingOuter(0.5);
    const y = d3.scaleLinear().range([height, 0]);

    const svg = d3
      .select(svgRef.current)
      .attr('width', '100%')
      .attr('height', height + margin.top + margin.bottom)
      .append('g')
      .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')');

    chartData.forEach(function (d: any) {
      d.y = +d.y;
    });

    x.domain(chartData.map((d: any) => d.x));
    y.domain([0, 1]);
    const yAxisFormat = d3.format('.0%');

    const tooltip = d3
      .select('body')
      .append('div')
      .attr('class', 'd3-tooltip')
      .style('position', 'absolute')
      .style('z-index', '10')
      .style('top', '-100%')
      .style('visibility', 'hidden')
      .style('padding', '10px')
      .style('background', 'rgba(0,0,0,0.6)')
      .style('border-radius', '4px')
      .style('color', '#fff')
      .text('a simple tooltip');

    svg
      .selectAll('.bar')
      .data(chartData)
      .enter()
      .append('rect')
      .on('mouseover', (d, i) => {
        tooltip
          .html(
            `<div>${convertFirstLatterToCapital(
              i.label.toString()
            )}: ${parseInt((Number(i.y) * 100).toString())}%</div>`
          )
          .style('visibility', 'visible');
      })
      .on('mousemove', function (event) {
        tooltip
          .style('top', event.pageY - 10 + 'px')
          .style('left', event.pageX + 10 + 'px');
      })
      .on('mouseout', function () {
        tooltip.html(``).style('visibility', 'hidden');
      })
      .attr('fill', (d: any) => d.bgColor)
      .attr('x', (d: any) => x(d.x) || 0)
      .attr('width', x.bandwidth())
      .attr('y', height) // Initial y position set to the bottom of the chart
      .attr('height', 0.0) // Initial height set to 0
      .transition() // Add a transition for the initial load animation
      .ease(d3.easeLinear)
      .duration(500) // Animation duration in milliseconds
      .delay((d, i) => i * 100) // Delay each bar's animation
      .attr('y', (d: any) => y(d.y) || 0)
      .attr('height', function (d: any) {
        return height - y(d.y);
      })
      .attr('rx', 5)
      .attr('ry', 5);

    svg
      .append('g')
      .attr('class', 'grid')
      .attr('transform', 'translate(0,' + height + ')')
      .call(d3.axisBottom(x))
      .attr('stroke-dasharray', '10,10')
      .attr('stroke-opacity', 0.1);

    svg
      .append('g')
      .attr('class', 'grid')
      .call(
        d3
          .axisLeft(y)
          .tickValues([0, 0.25, 0.5, 0.75, 1])
          .tickSize(-width)
          .tickFormat(yAxisFormat)
      )
      .attr('stroke-opacity', 0.1)
      .attr('stroke-dasharray', '10,10')
      .attr('stroke', '#E6E0EC');

    svg
      .selectAll('.tick')
      .style('font-size', '14px')
      .style('font-family', 'Inter')
      .style('font-weight', 400);
  }, [chartData, chartWidth]);

  return <svg ref={svgRef} key={chartWidth}></svg>;
};

export default BarChart;
