import {
  Badge,
  Box,
  Card,
  Flex,
  LoadingOverlay,
  Text,
  TextProps,
  Tooltip,
} from '@mantine/core';
import React, { useMemo } from 'react';
import ActionButton from '../../../shared/components/actionButton/actionButton';
import {
  IconBlock,
  IconEdit,
  IconTrash,
  IconView,
} from '../../../shared/components/icons/icons';
import { IPagination } from '../../../shared/interface';
import { unixTimestampToFormattedDate } from '../../../shared/util/utility';
import { IUserInfo } from '../interface/users.interface';
import DataTable, { TableColumn } from 'react-data-table-component';
import { dataTableStyles } from '../../../shared/constants/constants';

export interface IUsersList {
  usersData: IUserInfo[];
  loading: boolean;
  pagination: IPagination;
  handleAction: (type: 'edit' | 'delete' | 'block', userId: string) => void;
  handleView: (id: string) => void;
}
const textStyle: TextProps = {
  lh: 1.5,
  align: 'center',
  ff: 'Open Sans',
  fw: 'normal',
  truncate: true,
};

const USER_STATUS: any = {
  Active: 'green',
  Blocked: 'red',
};

const UsersList: React.FC<IUsersList> = (props) => {
  const { usersData, loading, pagination, handleAction, handleView } = props;
  const { currentPage, recordPerPage } = pagination;

  const columns: TableColumn<any>[] = useMemo(() => {
    return [
      {
        name: 'Sr. No.',
        center: true,
        width: "80px",
        selector: (row) => row.id,
        cell: (row, index) => {
          return <span>{(currentPage - 1) * recordPerPage + index + 1}</span>;
        },
      },
      {
        name: 'Name',
        center: true,
        maxWidth: "190px",
        selector: (row) => row.name,
        cell: (row) => {
          return (
            <Tooltip label={row.name || '-'}>
              <Text {...textStyle} onClick={() => handleView(row.id)}>
                {row.name || '-'}
              </Text>
            </Tooltip>
          );
        },
      },
      {
        name: 'Email',
        center: true,
        maxWidth: "200px",
        selector: (row) => row.email || '-',
        cell: (row) => {
          return (
            <Tooltip label={row.email || '-'}>
              <Text {...textStyle} onClick={() => handleView(row.id)}>
                {row.email || '-'}
              </Text>
            </Tooltip>
          );
        },
      },
      {
        name: 'Username',
        center: true,
        maxWidth: "190px",
        selector: (row) => row.username || '-',
        cell: (row) => {
          return (
            <Tooltip label={row.username || '-'}>
              <Text {...textStyle} onClick={() => handleView(row.id)}>
                {row.username || '-'}
              </Text>
            </Tooltip>
          );
        },
      },
      {
        name: 'Date Joined',
        center: true,
        width: "110px",
        selector: (row) => row.createdAt,
        cell: (row) => {
          return row.createdAt
            ? unixTimestampToFormattedDate(Number(row.createdAt))
            : '-';
        },
      },
      {
        name: 'Age',
        center: true,
        width: "60px",
        selector: (row) => row.age ?? '-',
      },
      {
        name: 'Gender',
        center: true,
        width: "110px",
        selector: (row) => row.gender || '-',
      },
      {
        name: 'Country',
        center: true,
        width: "150px",
        selector: (row) => row.country || '-',
      },
      {
        name: 'State',
        center: true,
        width: "150px",
        selector: (row) => row.state || '-',
      },
      {
        name: 'Posts',
        center: true,
        width: "70px",
        selector: (row) => row.post ?? '-',
      },
      {
        name: 'Status',
        center: true,
        width: "100px",
        selector: (row) => row.status,
        cell: (row) => {
          return (
            <Badge
              sx={{
                fontWeight: 500,
                fontFamily: 'Open Sans',
                fontSize: '12px',
                textTransform: 'capitalize',
              }}
              color={USER_STATUS[`${row.status}`]}
              size={'lg'}
            >
              {row.status}
            </Badge>
          );
        },
      },
      {
        name: 'Action',
        center: true,
        width: "200px",
        ignoreRowClick: true,
        cell: (row) => {
          return (
            <Flex align={'center'} justify={'center'}>
              <ActionButton
                onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
                  e.stopPropagation();
                  handleView(row.id);
                }}
                icon={<IconView />}
              />
              <ActionButton
                onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
                  e.stopPropagation();
                  handleAction('edit', row.id);
                }}
                icon={<IconEdit scale={1.3} />}
              />
              <ActionButton
                onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
                  e.stopPropagation();
                  handleAction('delete', row.id);
                }}
                icon={<IconTrash color="#FF6252" />}
              />
              <ActionButton
                onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
                  e.stopPropagation();
                  handleAction('block', row.id);
                }}
                icon={
                  <IconBlock
                    color={row.status === 'Active' ? '#FF6252' : '#2AC670'}
                    scale={1.3}
                  />
                }
              />
            </Flex>
          );
        },
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usersData]);

  const loadingComponent = useMemo(() => (
    <Card sx={loading ? { minHeight: "60px" } : {}}>
      {loading ? (
        <LoadingOverlay
          visible={true}
          overlayBlur={2}
          zIndex={5}
          sx={{ borderRadius: '10px' }}
        />
      ) : (
        <Text>
          No any challenges found.
        </Text>
      )}
    </Card>
  ), [loading])

  return (
    <Box pos={'relative'} sx={{
      boxShadow: "rgba(0, 0, 0, 0.1) 0px 10px 50px -10px",
      borderRadius: "9px",
    }}>
      <DataTable
        striped
        responsive
        conditionalRowStyles={[
          {
            when: (row) => row.status === 'Blocked',
            classNames: ['blocker-user'],
          },
        ]}
        onRowClicked={(row) => {
          handleView(row.id);
        }}
        persistTableHead
        pointerOnHover
        noDataComponent={loadingComponent}
        highlightOnHover
        customStyles={dataTableStyles}
        columns={columns}
        data={loading ? [] : usersData}
      />
    </Box>
  );
};

export default UsersList;
