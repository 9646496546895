import { Alert, AppShell, useMantineTheme } from '@mantine/core';
import { ReactNode, useState } from 'react';
import TopHeader from '../components/header';
import NavbarComponent from '../components/navbar/navbar';
import { APP_HEADER_HEIGHT, APP_PADDING } from '../constants/constants';
import { IconAlertCircle } from '@tabler/icons-react';
import { useSelector } from 'react-redux';
import { State } from '../interface';
interface ILayout {
  children: ReactNode;
}

const Layout: React.FC<ILayout> = (props) => {
  const { children } = props;
  const [opened, setOpened] = useState(false);
  const theme = useMantineTheme();
  const { hasCorsError } = useSelector((state: State) => state.cors);
  return (
    <AppShell
      className={'animate__animated animate__fadeIn'}
      sx={{
        backgroundColor: theme.colors.gray[1],
        '.mantine-AppShell-main': {
          paddingTop: 0,
        },
      }}
      navbarOffsetBreakpoint="sm"
      padding={`${APP_PADDING}px`}
      navbar={<NavbarComponent hidden={!opened} />}
      header={<TopHeader opened={opened} setOpened={setOpened} />}
    >
      {hasCorsError ? (
        <Alert
          icon={<IconAlertCircle size="1rem" />}
          title="Oops!"
          color="red"
          mt={APP_HEADER_HEIGHT}
        >
          Something went wrong! Please try again later.
        </Alert>
      ) : (
        children
      )}
    </AppShell>
  );
};

export default Layout;
