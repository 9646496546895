import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Button,
  Group,
  Select,
  Text,
  TextInput,
  useMantineTheme,
} from '@mantine/core';
import { DateInput } from '@mantine/dates';
import { Reset } from '@mantine/form/lib/types';
import { useDebouncedValue } from '@mantine/hooks';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import Confirm from '../../../shared/components/confirm/confirm';
import { IconAdd, IconSearch } from '../../../shared/components/icons/icons';
import PageHeader from '../../../shared/components/pageHeader/pageHeader';
import PaginationComponent from '../../../shared/components/pagination/pagination';
import {
  API_CONFIG,
  getSearchProps,
  getSelectProps,
  searchInputStyle,
} from '../../../shared/constants/constants';
import useLocalStorageFilter from '../../../shared/hoc/useLocalStorageFilter';
import { IPagination } from '../../../shared/interface';
import httpService from '../../../shared/services/http.service';
import {
  convertToGMTDate,
  formatDateToMMDDYYYY,
  getChallengeStatus,
} from '../../../shared/util/utility';
import ChallengesList from '../component/challengesList';
import ChallengesTypeChart from '../component/challengesType';
import { IChallengeInfo } from '../interface/dailyChallenges.interface';
import ChallengeModal from '../modals/challengeModal';

const DailyChallenges = () => {
  const [filters, updateFilters] = useLocalStorageFilter(
    'dailyChallengesFilters',
    {
      currentPage: '1',
      searchChallenge: '',
      challengeStatus: '',
      challengeScheduleDate: '',
      challengeLabel: '',
    }
  );

  const [challengesList, setChallengesList] = useState<IChallengeInfo[]>([]);
  const [challengeId, setChallengeId] = useState<string>('');
  const [loading, setIsLoading] = useState(false);
  const [isActionLoading, setIsActionLoading] = useState(false);
  const [pagination, setPagination] = useState<IPagination>({
    currentPage: 1,
    nextPage: null,
    recordPerPage: 10,
    remainingCount: 0,
    total: 0,
    totalPages: 1,
  });
  const savedCurrentPage = parseInt(filters.currentPage || '1', 10);

  const [openModel, setOpenModel] = useState('');
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const [isInputFocused, setInputFocused] = useState(false);
  const [challengeScheduleDate, setChallengeScheduleDate] = useState<any>(
    filters.challengeScheduleDate
      ? moment(filters.challengeScheduleDate).toDate()
      : null
  );
  const [searchChallenge, setSearchChallenge] = useState(
    filters.searchChallenge || ''
  );
  const [debouncedUserSearchChallenge] = useDebouncedValue(
    searchChallenge,
    searchChallenge ? 700 : 0
  );
  const [challengeLabel, setChallengeLabel] = useState(
    filters.challengeLabel || ''
  );
  const [challengeStatus, setChallengeStatus] = useState(
    filters.challengeStatus || ''
  );
  const [scheduleDateList, setScheduleDateList] = useState([]);
  const theme = useMantineTheme();

  const navigate = useNavigate();
  const handleRedirect = (id: string) => {
    navigate(`/challenges/${id}`);
  };
  useEffect(() => {
    getScheduleDateList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const formattedScheduleDate = challengeScheduleDate
    ? formatDateToMMDDYYYY(challengeScheduleDate, 'YYYY-MM-DD')
    : '';

  useEffect(() => {
    // Save filter values to local storage
    updateFilters({
      currentPage: savedCurrentPage.toString(),
      searchChallenge,
      challengeStatus,
      challengeScheduleDate: formattedScheduleDate,
      challengeLabel,
    });

    const hasOnlySpaces = /^ *$/.test(debouncedUserSearchChallenge);

    if (!hasOnlySpaces) {
      getChallengesList(
        savedCurrentPage,
        pagination.recordPerPage,
        formattedScheduleDate,
        challengeLabel || '',
        challengeStatus || '',
        debouncedUserSearchChallenge.trim()
      );
    } else if (debouncedUserSearchChallenge === '') {
      getChallengesList(
        savedCurrentPage,
        pagination.recordPerPage,
        formattedScheduleDate,
        challengeLabel || '',
        challengeStatus || '',
        ''
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    challengeScheduleDate,
    challengeLabel,
    challengeStatus,
    debouncedUserSearchChallenge,
    savedCurrentPage,
  ]);

  const getChallengesList = useCallback(
    (
      currentPage = pagination.currentPage,
      recordPerPage = pagination.recordPerPage,
      scheduleDate = '',
      label = '',
      status = '',
      challenge = '',
      orderbyField = '',
      orderbyValue = ''
    ) => {
      setIsLoading(true);
      httpService
        .get(API_CONFIG.path.challenge, {
          currentPage,
          recordPerPage,
          scheduleDate,
          label,
          status,
          challenge,
          orderbyField,
          orderbyValue
        })
        .then((response) => {
          const { data, ...paginationData } = response.data;
          const sortedChallenges = data.sort(
            (a: IChallengeInfo, b: IChallengeInfo) => {
              const statusA = getChallengeStatus(a.scheduleDate);
              const statusB = getChallengeStatus(b.scheduleDate);
              if (statusA === 'active' && statusB !== 'active') {
                return -1;
              } else if (statusB === 'active' && statusA !== 'active') {
                return 1;
              }
              return a.scheduleDate - b.scheduleDate;
            }
          );
          setChallengesList(sortedChallenges);
          setPagination(paginationData);
          setIsLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setIsLoading(false);
        });
    },
    [pagination]
  );

  const getScheduleDateList = useCallback(() => {
    setIsLoading(true);
    const url = `${API_CONFIG.path.challenge}/all`;
    httpService
      .get(url)
      .then((response) => {
        setIsLoading(false);
        setScheduleDateList(
          response.data.map((item: any) =>
            formatDateToMMDDYYYY(convertToGMTDate(item.scheduleDate) as Date)
          )
        );
      })
      .catch((error) => {
        setIsLoading(false);
        console.error(error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const addChallenges = useCallback(
    (
      values: any,
      resetForm?: Reset,
      page = 1,
      label = '',
      status = '',
      challenge = ''
    ) => {
      setIsActionLoading(true);
      const payload = {
        label: values.label,
        description: values.description,
        scheduleDate: values.scheduleDate ? values.scheduleDate : null,
      };

      if (payload.scheduleDate === 'Invalid date') {
        payload.scheduleDate = null;
      }

      httpService
        .post(API_CONFIG.path.challenge, payload)
        .then(() => {
          getChallengesList(
            page,
            pagination.recordPerPage,
            formattedScheduleDate,
            label,
            status,
            challenge
          );
          setIsActionLoading(false);
          setOpenModel('');
          if (resetForm) {
            resetForm();
          }
          getScheduleDateList();
        })
        .catch((error) => {
          setIsActionLoading(false);
          console.error(error);
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const editChallenges = useCallback(
    (
      values: any,
      resetForm?: Reset,
      page = 1,
      label = '',
      status = '',
      challenge = ''
    ) => {
      let payload: any = {
        label: values.label,
        description: values.description,
      };

      if ("scheduleDate" in values) {
        payload.scheduleDate = values.scheduleDate && values.scheduleDate !== 'Invalid date' ? values.scheduleDate : null
      }

      setIsActionLoading(true);
      httpService
        .put(`${API_CONFIG.path.challenge}/${challengeId}`, payload)
        .then(() => {
          getChallengesList(
            page,
            pagination.recordPerPage,
            formattedScheduleDate,
            label,
            status,
            challenge
          );
          setIsActionLoading(false);
          setOpenModel('');
          setChallengeId('');
          if (resetForm) {
            resetForm();
          }
          getScheduleDateList();
        })
        .catch((error) => {
          setIsActionLoading(false);
          console.error(error);
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [challengeId, getChallengesList]
  );

  const handleConfirmDelete = () => {
    setIsActionLoading(true);
    if (openModel === 'DELETE A CHALLENGE') {
      httpService
        .deleteRequest(`${API_CONFIG.path.challenge}/${challengeId}`)
        .then(() => {
          getChallengesList(
            pagination.currentPage,
            pagination.recordPerPage,
            formattedScheduleDate,
            challengeLabel,
            challengeStatus,
            debouncedUserSearchChallenge
              ? debouncedUserSearchChallenge.trim()
              : ''
          );
          setIsConfirmOpen(false);
          setIsActionLoading(false);
          setOpenModel('');
          getScheduleDateList();
        })
        .catch((error) => {
          console.log(error);
          setIsActionLoading(false);
          setOpenModel('');
          setIsConfirmOpen(false);
        });
    }
  };

  const handleConfirmClose = () => {
    setIsConfirmOpen(false);
  };

  const handleAddChallengeClose = () => {
    setOpenModel('');
    setChallengeId('');
  };

  const handleModel = (type: string, id?: string) => {
    setOpenModel(type);
    setIsConfirmOpen(type === 'DELETE A CHALLENGE');
    if (id) {
      setChallengeId(id);
    }
  };

  const handleSubmit = (values: any, resetForm?: Reset) => {
    if (values && openModel === 'ADD A CHALLENGE') {
      addChallenges(
        values,
        resetForm,
        pagination.currentPage,
        challengeLabel,
        challengeStatus,
        debouncedUserSearchChallenge ? debouncedUserSearchChallenge.trim() : ''
      );
    } else if (values && openModel === 'EDIT A CHALLENGE') {
      editChallenges(
        values,
        resetForm,
        pagination.currentPage,
        challengeLabel,
        challengeStatus,
        debouncedUserSearchChallenge ? debouncedUserSearchChallenge.trim() : ''
      );
    }
  };

  const handlePagination = useCallback(
    (page: number) => {
      if (page !== pagination.currentPage) {
        // Save filter values to local storage
        updateFilters({
          ...filters,
          currentPage: page.toString(),
        });
        getChallengesList(
          page,
          pagination.recordPerPage,
          formattedScheduleDate,
          challengeLabel ? challengeLabel : '',
          challengeStatus ? challengeStatus : '',
          debouncedUserSearchChallenge ? debouncedUserSearchChallenge.trim() : ''
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      pagination,
      debouncedUserSearchChallenge,
      challengeLabel,
      challengeStatus,
      challengeScheduleDate,
    ]
  );

  const handleSearch = (value: string) => setSearchChallenge(value);

  const selectedChallenge: any = useMemo(
    () => challengesList.find((item) => item.id === challengeId) || {},
    [challengeId, challengesList]
  );

  const labelProps = getSelectProps(challengeLabel);
  const statusProps = getSelectProps(challengeStatus);
  const searchProps = getSearchProps(searchChallenge, () => {
    setSearchChallenge('');
  });

  const onFilterChangeUpdateFilters = () => {
    updateFilters({
      ...filters,
      currentPage: '1',
    });
  };

  const handleScheduleDate = (date: Date) => {
    if (date) {
      const formattedDate = formatDateToMMDDYYYY(date, 'YYYY-MM-DD');
      setChallengeScheduleDate(date);
      updateFilters({
        ...filters,
        challengeScheduleDate: formattedDate,
        currentPage: '1',
      });
    } else {
      setChallengeScheduleDate(null);
      updateFilters({
        ...filters,
        challengeScheduleDate: '',
        currentPage: '1',
      });
    }
  };

  // const handleSort = (orderbyField: string, orderbyValue: string) => {
  //   getChallengesList(1, undefined, challengeScheduleDate, challengeLabel, challengeStatus, searchChallenge, orderbyField, orderbyValue)
  // }

  return (
    <>
      <PageHeader title="Daily Challenges" />
      <ChallengesTypeChart />
      <Group align="center" position="apart" mt={30}>
        <Group>
          <Text
            size="xl"
            weight={700}
            sx={{
              fontFamily: 'Open Sans, sans-serif',
              fontSize: '18px',
              color: '#2E3646',
              fontWeight: 500,
              opacity: 0.5,
            }}
          >
            Challenge Details
          </Text>
        </Group>
        <Group>
          <Group sx={{ position: 'relative', borderRadius: 30 }}>
            <TextInput
              placeholder="Search"
              variant="outline"
              value={searchChallenge}
              styles={searchInputStyle(isInputFocused)}
              icon={<IconSearch color="#ADADAD" />}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                handleSearch(e.target.value);
                onFilterChangeUpdateFilters();
              }}
              onFocus={() => setInputFocused(true)}
              onBlur={() => setInputFocused(false)}
              type="search"
              {...searchProps}
            />
          </Group>
          <Select
            name="label"
            value={challengeLabel}
            placeholder="Label"
            iconWidth={100}
            data={[
              { value: 'draw', label: 'Draw' },
              { value: 'write', label: 'Write' },
              { value: 'business', label: 'Business' },
              { value: 'other', label: 'Other' },
            ]}
            sx={{
              width: '130px',
              boxShadow: theme.shadows.md,
              background: 'transparent',
              borderRadius: '10px',
              fontSize: '12px',
            }}
            clearable
            searchable
            onChange={(value: any) => {
              setChallengeLabel(value || '');
              onFilterChangeUpdateFilters();
            }}
            transitionProps={{
              transition: 'pop-top-left',
              duration: 100,
              timingFunction: 'ease',
            }}
            {...labelProps}
          />
          <Select
            name="status"
            value={challengeStatus}
            placeholder="Status"
            iconWidth={100}
            data={[
              { value: 'active', label: 'Active' },
              { value: 'scheduled', label: 'Scheduled' },
              { value: 'posted', label: 'Posted' },
              { value: 'unscheduled', label: 'Unscheduled' },
            ]}
            sx={{
              width: '140px',
              boxShadow: theme.shadows.md,
              background: 'transparent',
              borderRadius: '10px',
              fontSize: '12px',
            }}
            clearable
            searchable
            onChange={(value) => {
              setChallengeStatus(value || '');
              onFilterChangeUpdateFilters();
            }}
            transitionProps={{
              transition: 'pop-top-left',
              duration: 100,
              timingFunction: 'ease',
            }}
            {...statusProps}
          />
          <DateInput
            placeholder="Post Date"
            sx={{
              width: '130px',
              marginRight: '0px !important',
              marginLeft: '0px !important',
            }}
            clearable
            mx="auto"
            disabled={filters.challengeStatus === 'active'}
            name="scheduleDate"
            valueFormat={'YYYY.MM.DD'}
            value={challengeScheduleDate}
            iconWidth={100}
            popoverProps={{ zIndex: 1000 }}
            onChange={handleScheduleDate}
            weekendDays={[]}
          />
          <Button
            key={''}
            color={'blue'}
            sx={{
              boxShadow: '0px 6px 10px 0px',
              width: '170px',
              height: '37px',
            }}
            rel="noopener noreferrer"
            leftIcon={<IconAdd />}
            onClick={() => {
              handleModel('ADD A CHALLENGE');
            }}
          >
            <Text size={'12px'} weight={700} sx={{ fontFamily: 'Open Sans' }}>
              Add Challenge
            </Text>
          </Button>
        </Group>
      </Group>
      <ChallengesList
        loading={loading}
        pagination={pagination}
        challengesData={challengesList}
        handleModel={handleModel}
        handleRedirect={handleRedirect}
      />
      {pagination.totalPages > 1 && (
        <PaginationComponent
          loading={loading}
          pagination={pagination}
          fetchData={handlePagination}
        />
      )}
      <Confirm
        loading={isActionLoading}
        opened={isConfirmOpen}
        modalTitle="Are you sure you want to Delete the challenge?"
        handleConfirm={handleConfirmDelete}
        handleClose={handleConfirmClose}
        cancelBtnText="Cancel"
        submitBtnText="Delete"
        size={'450px'}
      />

      <ChallengeModal
        loading={isActionLoading}
        modalTitle={openModel}
        opened={
          (openModel === 'EDIT A CHALLENGE' &&
            Object.keys(selectedChallenge).length > 0) ||
          openModel === 'ADD A CHALLENGE'
        }
        challenge={selectedChallenge}
        scheduleDateList={scheduleDateList}
        handleClose={handleAddChallengeClose}
        handleSubmit={handleSubmit}
        resetOnClose
      />
    </>
  );
};

export default DailyChallenges;
