import {
  Box,
  Button,
  Card,
  Flex,
  LoadingOverlay,
  Select,
  Text,
  Textarea,
  useMantineTheme,
} from '@mantine/core';
import { DateInput } from '@mantine/dates';
import { useForm } from '@mantine/form';
import { Reset } from '@mantine/form/lib/types';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { IconChevronDownFilled } from '../../../shared/components/icons/icons';
import CustomModal from '../../../shared/components/modal/CustomModal';
import {
  DEFAULT_FORMAT,
  primaryBoxShadow,
} from '../../../shared/constants/constants';
import {
  convertToGMTDate,
  formatDateToMMDDYYYY,
} from '../../../shared/util/utility';

interface IProps {
  loading: boolean;
  opened: boolean;
  scheduleDateList?: string[];
  handleClose: () => void;
  handleSubmit: (value: any, resetForm?: Reset) => void;
  modalTitle?: string;
  className?: string;
  challenge?: any;
  isCalendar?: boolean;
  resetOnClose?: boolean;
}

const typeOptions = [
  { label: 'Draw', value: 'draw' },
  { label: 'Write', value: 'write' },
  { label: 'Business', value: 'business' },
  { label: 'Other', value: 'other' },
];

const ChallengeModal = (props: IProps) => {
  const [currentScheduleDate, setCurrentScheduleDate] = useState<any>();
  const {
    handleSubmit,
    handleClose,
    challenge = {},
    modalTitle,
    loading,
    scheduleDateList,
    isCalendar,
    resetOnClose = false,
  } = props;
  const theme = useMantineTheme();

  const form = useForm({
    initialValues: {
      description: '',
      label: '',
      scheduleDate: null,
    } as any,
  });

  useEffect(() => {
    if (Object.keys(challenge).length > 0) {
      const sd = convertToGMTDate(challenge.scheduleDate);
      form.setValues({
        description: challenge.description,
        label: (challenge.label || '').toLowerCase(),
        scheduleDate: sd,
      });
      setCurrentScheduleDate(sd);
    } else {
      form.reset();
      setCurrentScheduleDate(undefined);
    }
    return form.reset;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [challenge]);

  useEffect(() => {
    if (!props.opened) {
      form.clearErrors();
      if (resetOnClose) {
        form.reset();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.opened]);

  const Label = ({ label }: { label: string }) => {
    const theme = useMantineTheme();
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          height: '100%',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            margin: '10px',
            alignItems: 'center',
            borderRight: `1px solid ${theme.colors.gray[3]}`,
            width: 'calc(100% - 20px)',
            height: 'calc(100% - 20px)',
          }}
        >
          <Text size={'sm'} sx={{ lineHeight: 1 }}>
            {label}
          </Text>
        </Box>
      </Box>
    );
  };

  const scheduleDateFormProps = form.getInputProps('scheduleDate');
  delete scheduleDateFormProps.onChange;

  return (
    <CustomModal
      opened={props.opened}
      handleClose={props.handleClose}
      className={props.className}
      modalTitle={props.modalTitle}
      isConfirm={false}
      hideCloseIcon
      size={'520px'}
    >
      <Card
        radius="10px"
        padding={0}
        mt={25}
        sx={{
          '.mantine-Input-input': { height: 40 },
          overflow: 'visible',
          '*': { fontFamily: 'Open Sans' },
        }}
      >
        <LoadingOverlay
          visible={loading}
          zIndex={5}
          sx={{ borderRadius: '10px' }}
        />
        <form
          onSubmit={form.onSubmit((values) => {
            const clonedValues = { ...values };
            const isSameDate = !!currentScheduleDate
              ? moment(currentScheduleDate).format(DEFAULT_FORMAT) ===
              moment(clonedValues.scheduleDate).format(DEFAULT_FORMAT)
              : false;

            clonedValues.description = clonedValues.description
              .trim()
              .replace(/\n+/g, '\n'); // Replace consecutive newlines with a single newline

            if (isSameDate && !isCalendar) {
              delete clonedValues.scheduleDate;
            } else {
              clonedValues.scheduleDate = clonedValues.scheduleDate ? formatDateToMMDDYYYY(
                clonedValues.scheduleDate,
                'YYYY-MM-DD'
              ) : null;
            }
            // Validate Textarea
            let hasError = false;
            const errors: { [key: string]: string } = {};

            if (
              !clonedValues.description &&
              props.modalTitle !== 'Schedule Challenge'
            ) {
              errors.description = 'Description is required';
              hasError = true;
            }

            if (!clonedValues.label) {
              errors.label = 'Type is required';
              hasError = true;
            }
            console.log('clonedValues:', clonedValues);

            form.setErrors(errors);

            if (!hasError) {
              handleSubmit(clonedValues, form.reset);
            }
          })}
        >
          {modalTitle !== 'Schedule Challenge' && (
            <Textarea
              name="description"
              placeholder="Write a prompt here.."
              autosize
              maxRows={8}
              minRows={8}
              sx={{ height: '120px' }}
              {...form.getInputProps('description')}
              error={form.errors.description}
            />
          )}
          <Flex mt={50} mb={'15px'} gap={'30px'}>
            {modalTitle !== 'Schedule Challenge' && (
              <Select
                searchable
                sx={{
                  width: '250px',
                  '.mantine-Input-input': { height: '50px' },
                  '.mantine-Select-error': { fontSize: '12px' },
                }}
                size="lg"
                rightSection={
                  <IconChevronDownFilled color={theme.colors.dark[3]} />
                }
                placeholder="Select"
                iconWidth={100}
                name="label"
                data={typeOptions}
                icon={<Label label="Type" />}
                transitionProps={{
                  transition: 'pop-top-left',
                  duration: 100,
                  timingFunction: 'ease',
                }}
                {...form.getInputProps('label')}
                error={form.errors.label}
              />
            )}
            <DateInput
              placeholder="Select Date"
              maw={400}
              sx={{
                width: modalTitle !== 'Schedule Challenge' ? '170px' : '75%',
                '.mantine-Input-input': { height: '50px' },
              }}
              size="md"
              minDate={new Date()}
              clearable
              excludeDate={(date: Date) => {
                return (scheduleDateList || []).includes(
                  formatDateToMMDDYYYY(date)
                );
              }}
              mx="auto"
              name="scheduleDate"
              valueFormat={'YYYY.MM.DD'}
              iconWidth={100}
              popoverProps={{ zIndex: 1000 }}
              {...scheduleDateFormProps}
              onChange={(date: Date) => {
                form.setFieldValue('scheduleDate', date ? date : null);
              }}
              weekendDays={[]}
            />
          </Flex>
          <Flex
            mt={40}
            justify="center"
            sx={{ width: '100%', paddingBottom: '15px' }}
          >
            <Button
              variant="outline"
              color="gray"
              sx={{ width: '50%', height: '50px', marginRight: '5px' }}
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              loading={loading}
              disabled={loading}
              color={'app'}
              type="submit"
              sx={{
                width: '50%',
                height: '50px',
                marginLeft: '5px',
                boxShadow: primaryBoxShadow,
              }}
            >
              Save
            </Button>
          </Flex>
        </form>
      </Card>
    </CustomModal>
  );
};

export default ChallengeModal;
