import {
  Badge,
  Box,
  Button,
  Card,
  Group,
  Skeleton,
  Text,
  Tooltip,
} from '@mantine/core';
import {
  CHALLENGE_STATUS,
  GetChallengeBgColor,
} from '../../../shared/constants/constants';

interface IProps {
  title: string;
  status: string;
  loading: boolean;
  isPopular?: boolean;
  handleScheduleChallenge?: (type: string) => void;
}

const ChallengePrompt: React.FC<IProps> = (props) => {
  const { title, status, isPopular, loading, handleScheduleChallenge } = props;
  return (
    <Skeleton mt={10} visible={loading}>
      <Box>
        <Card
          sx={{
            overflow: 'visible',
            backgroundColor: GetChallengeBgColor(status),
            zIndex: loading ? 1 : 12,
          }}
        >
          <Group
            position="apart"
            align="center"
            sx={{
              height: '100%',
            }}
          >
            <Box
              sx={{
                '*': { fontFamily: 'Open Sans' },
                display: 'flex',
                justifyContent: 'start',
                width: '80%',
                alignItems: 'center',
                '@media (max-width: 1440px)': {
                  width: '70%',
                },
              }}
            >
              <Tooltip
                multiline
                label={title}
                withArrow
                position="bottom"
                sx={{
                  maxWidth: '40%',
                  padding: '15px',
                  lineHeight: 1.3,
                  zIndex: 999999,
                }}
              >
                <Text
                  weight={600}
                  size={'25px'}
                  mr={20}
                  color="white"
                  sx={{
                    fontFamily: 'Open Sans',
                    wordWrap: 'break-word',
                    maxWidth: '80%',
                    lineHeight: 1.3,
                    '@media (max-width: 1700px)': {
                      fontSize: '18px',
                    },
                  }}
                  lineClamp={2}
                >
                  {title}
                </Text>
              </Tooltip>
              <Badge
                sx={{
                  border: '1px solid rgba(255, 255, 255, 0.50);',
                }}
                color={CHALLENGE_STATUS[status]}
                size="lg"
                fs={'14px'}
                variant="filled"
                fw={600}
                mr={20}
              >
                {status}
              </Badge>
              {isPopular && (
                <Badge
                  sx={{
                    border: '1px solid rgba(255, 255, 255, 0.50)',
                  }}
                  color={'yellow'}
                  fs={'14px'}
                  size="lg"
                  variant="filled"
                >
                  {'Popular'}
                </Badge>
              )}
            </Box>
            {status !== 'active' && (
              <Box
                sx={{
                  width: '15%',
                  textAlign: 'end',
                  '@media (max-width: 1440px)': {
                    width: '20%',
                  },
                }}
              >
                <Button
                  variant="filled"
                  color={'white'}
                  sx={{
                    color: 'black',
                    fontFamily: 'Open Sans',
                    fontWeight: 400,
                    fontSize: '14px',
                  }}
                  onClick={() =>
                    handleScheduleChallenge &&
                    handleScheduleChallenge(
                      status === 'unscheduled'
                        ? 'Schedule Challenge'
                        : 'Re-Schedule Challenge'
                    )
                  }
                >
                  {status === 'unscheduled'
                    ? 'Schedule Challenge'
                    : 'Re-Schedule Challenge'}
                </Button>
              </Box>
            )}
          </Group>
        </Card>
      </Box>
    </Skeleton>
  );
};

export default ChallengePrompt;
