import { CloseButton, useMantineTheme } from '@mantine/core';
import isEmpty from 'lodash/isEmpty';
import queryString from 'query-string';
import { IconChevronDownFilled } from '../components/icons/icons';
import { IQueryParameters } from '../interface';
import { TableStyles } from 'react-data-table-component';

export const firstLevelBreadcrumbs = [{ name: 'Home', link: '/' }];

export const APP_PADDING = 20;
export const APP_HEADER_HEIGHT = 100;

export const API_CONFIG = {
  baseUrl: `${process.env.REACT_APP_BASE_URL}`,
  path: {
    login: 'admin/login',
    logout: 'admin/logout',
    users: 'admin/users',
    user: 'user',
    challenge: 'admin/challenge',
    chartData: 'admin/challenge/chart-data',
    flag: 'admin/flag',
    dashboard: 'admin/dashboard',
    popularChallenges: 'admin/dashboard/popular-challenges',
    dashboardChart: 'admin/dashboard/chart',
    dashboardAgeGroup: 'admin/dashboard/age-group',
    excelData: 'admin/users/download-excel',
  },
};

export const getUrl = (url: string, params: IQueryParameters = {}): string => {
  if (!url.includes('https')) {
    let urlString = `${API_CONFIG.baseUrl}/${url}`;
    const parsedParams: IQueryParameters = {};
    for (const key in params) {
      const element = params[key];
      if (![undefined, null, ''].includes(element)) {
        parsedParams[key] = element;
      }
    }
    if (parsedParams && !isEmpty(parsedParams)) {
      urlString += `?${queryString.stringify(parsedParams)}`;
    }
    return urlString;
  }
  return url;
};

export const pageOptions: number[] = [20, 50, 100];

export const PER_PAGE = 20;

export const primaryBoxShadow = '0px 6px 10px 0px rgba(57, 131, 243, 0.25)';
export const redBoxShadow = '0px 2px 5px 0px rgba(255, 98, 82, 0.10)';

export const CHALLENGE_STATUS: any = {
  active: 'green',
  scheduled: 'yellow',
  posted: 'dark',
  unscheduled: 'gray',
};

export const GetChallengeBgColor = (status: string) => {
  const theme = useMantineTheme();
  const CHALLENGE_Bgcolor: any = {
    active: theme.colors.blue[3],
    scheduled: theme.colors.grape[5],
    posted: theme.colors.cyan[7],
    unscheduled: 'gray',
  };
  return CHALLENGE_Bgcolor[status];
};

export const statisticsOptions = [
  { value: 'today', label: 'Today' },
  { value: 'lastDay', label: 'Last day' },
  { value: 'week', label: 'Week' },
  { value: 'month', label: 'Month' },
  { value: 'year', label: 'Year' },
]

export const daysMapper: any = {
  7: 'in last week',
  14: 'in last two week',
  30: 'in last month',
  90: 'in last three month',
};

export const DEFAULT_FORMAT = 'MM-DD-YYYY';
export const DEFAULT_FORMAT_WITH_TIME = 'YYYY-MM-DD HH:mm:ss';
export const DEFAULT_GMT_FORMAT = 'ddd MMM DD YYYY HH:mm:ss [GMT]ZZ';
export const GMT_TIMEZONE = 'Africa/Abidjan';

export const GetPromptLabelColor = (status: string) => {
  const theme = useMantineTheme();
  const PROMPT_LABEL_COLOR: any = {
    draw: theme.colors.blue[3],
    write: theme.colors.grape[6],
    business: theme.colors.cyan[5],
    other: theme.colors.pink[6],
    none: theme.colors.gray[4],
  };
  return PROMPT_LABEL_COLOR[status] || PROMPT_LABEL_COLOR['none'];
};

export const searchInputStyle = (isInputFocused: boolean) => ({
  input: {
    border: '1px solid #E6E9EC',
    borderRadius: '30px',
    paddingLeft: '45px',
    paddingRight: '45px',
    height: '37px',
    width: '250px',
    boxShadow: '0px 6px 10px 0px #2e36460d',
    borderColor: isInputFocused ? '#3983F3' : '#E6E9EC',
    '&:focus': {
      outline: 'none',
    },
  },
});

export const challengeType = [
  { color: '#7CA9FF', label: 'Draw' },
  { color: '#C57CFF', label: 'Write' },
  { color: '#74D2CD', label: 'Biz' },
  { color: '#FF7CBB', label: 'Other' },
];

export const getSelectProps = (value: any) => {
  if (!value) {
    return {
      rightSection: <IconChevronDownFilled />,
      rightSectionProps: {
        style: {
          pointerEvents: 'none',
        },
      },
    };
  } else {
    return {};
  }
};
export const getSearchProps = (value: string, onClick: () => void) => {
  if (value) {
    return {
      rightSection: <CloseButton onClick={() => onClick()} />,
    };
  } else {
    return {};
  }
};

export const initialDataForDashboardStatistics = [
  {
    title: 'TOTAL USERS',
    newData: 0,
    percentageCount: '0.00%',
    trend: 'remained the same',
  },
  {
    title: 'NEW USERS',
    newData: 0,
    percentageCount: '0.00%',
    trend: 'remained the same',
  },
  {
    title: 'ACTIVE USERS',
    newData: 0,
    percentageCount: '0.00%',
    trend: 'remained the same',
  },
  {
    title: 'POST',
    newData: 0,
    percentageCount: '0.00%',
    trend: 'remained the same',
  },
  {
    title: 'LIKES',
    newData: 0,
    percentageCount: '0.00%',
    trend: 'remained the same',
  },
  {
    title: 'COMMENTS',
    newData: 0,
    percentageCount: '0.00%',
    trend: 'remained the same',
  },
  {
    title: 'AVG TIME PER USER',
    newData: 0,
    percentageCount: '0.00%',
    trend: 'remained the same',
  },
];

export const initialDataForPopularChallengesCard = [
  {
    description: '',
    label: '',
    challengeStatistics: {
      post: 0,
      likes: 0,
      comments: 0,
    },
    challengeId: '1',
  },
  {
    description: '',
    label: '',
    challengeStatistics: {
      post: 0,
      likes: 0,
      comments: 0,
    },
    challengeId: '2',
  },
  {
    description: '',
    label: '',
    challengeStatistics: {
      post: 0,
      likes: 0,
      comments: 0,
    },
    challengeId: '3',
  },
  {
    description: '',
    label: '',
    challengeStatistics: {
      post: 0,
      likes: 0,
      comments: 0,
    },
    challengeId: '4',
  },
  {
    description: '',
    label: '',
    challengeStatistics: {
      post: 0,
      likes: 0,
      comments: 0,
    },
    challengeId: '5',
  },
];


export type ModalType = 'resolve' | 'blockPost' | 'blockUser' | 'deletePost' | 'unblockUser' | string;


export const confirmBtnTextMapper: Record<ModalType, string> = {
  resolve: 'Yes, Resolve',
  reject: 'Yes, Block',
  blockPost: 'Yes, Block',
  blockUser: 'Yes, Block',
  unblockUser: 'Yes, Unblock',
  deleteUser: 'Yes, Delete',
};

export const modalTitleMapper: Record<ModalType, string> = {
  resolve: 'Do you wish to resolve this post?',
  reject: 'Do you wish to block this post for all users?',
  blockPost: 'Do you wish to block this post for all users?',
  blockUser: 'Are you sure you want to block this user?',
  unblockUser: 'Are you sure you want to unblock this user?',
  deletePost: 'Are you sure you want to delete this user post?',
};

export const statusMapper: Record<string, string> = {
  pending: 'pending',
  accept: 'Appropriate',
  reject: 'Inappropriate',
};

export const actionPayloadMapper: Record<ModalType, string> = {
  resolve: 'accept',
  reject: 'reject'
}

export const dataTableStyles: TableStyles = {
  rows: {
    stripedStyle: {
      backgroundColor: "#f4f4f4",
    },
    style: {
      minHeight: "60px",
    },
    highlightOnHoverStyle: {
      backgroundColor: "#f1f7ff",
      outline: "unset",
      borderBottomColor: "rgba(0,0,0,.12)"
    }
  },
  headRow: {
    style: {
      height: "60px"
    },
  },
  table: {
    style: {
      backgroundColor: "#fff",
      borderRadius: "10px",
      overflow: "hidden",
    }
  },
  headCells: {
    style: {
      backgroundColor: "#3983f3",
      color: "#fff",
      borderColor: "#e6e9ec",
      fontSize: "14px",
      fontFamily: "Open Sans"
    }
  },
  cells: {
    style: {
      fontSize: "14px",
      fontFamily: "Open Sans",
    }
  }
}