import {
  Alert,
  Box,
  Card,
  Flex,
  Group,
  Progress,
  Skeleton,
  Text,
  useMantineTheme,
} from '@mantine/core';
import { IconAlertCircle } from '@tabler/icons-react';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Confirm from '../../../shared/components/confirm/confirm';
import { IconEdit, IconTrash } from '../../../shared/components/icons/icons';
import PageHeader from '../../../shared/components/pageHeader/pageHeader';
import {
  API_CONFIG,
  DEFAULT_FORMAT,
  primaryBoxShadow,
  redBoxShadow,
} from '../../../shared/constants/constants';
import httpService from '../../../shared/services/http.service';
import {
  convertToGMTDate,
  convertUnixToGMT,
  formatDateToMMDDYYYY,
  getChallengeStatus,
} from '../../../shared/util/utility';
import { IAgeGroup } from '../../dashboard/interface/dashboard.interface';
import ChallengePrompt from '../component/challengePrompt';
import {
  IAgeGroups,
  IChallengeDetails,
  IStates,
} from '../interface/dailyChallenges.interface';
import ChallengeModal from '../modals/challengeModal';

interface promptInformationDetails {
  id: string;
  createdAt: string;
  scheduleDate: string;
}

interface promptEngagementsDetails {
  likeCount: string;
  commentCounts: string;
  postCount: string;
}

const ChallengeDetails = () => {
  const [currentChallengesData, setCurrentChallengesData] =
    useState<IChallengeDetails>({} as IChallengeDetails);
  const [isLoading, setIsLoading] = useState(true);
  const [isAllChallengeLoading, setIsAllChallengeLoading] = useState(true);
  const [isActionLoading, setIsActionLoading] = useState(false);
  const [openModel, setOpenModel] = useState('');
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const [scheduleDateList, setScheduleDateList] = useState([]);

  const theme = useMantineTheme();
  const { challengeId }: any = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    getChallengeDetails();
    getScheduleDateList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getChallengeDetails = useCallback(
    () => {
      setIsLoading(true);
      httpService
        .get(`${API_CONFIG.path.challenge}/${challengeId}`)
        .then((response) => {
          const totalUser = response.data.ageGroups
            .map(({ value }: IAgeGroup) => value)
            .reduce((a: number, c: number) => a + c, 0);

          const ageGroups = response.data.ageGroups.map((dt: IAgeGroups) => ({
            ...dt,
            ageRange: dt.ageRange.replace(/yr/g, ''),
            percentage: totalUser === 0 ? 0 : (dt.value * 100) / totalUser,
            totalUser,
          }));

          const totalStates = response.data.states
            .map(({ value }: IStates) => value)
            .reduce((a: number, c: number) => a + c, 0);

          const states = response.data.states.map((dt: IStates) => ({
            ...dt,
            name: dt.name,
            percentage: totalStates === 0 ? 0 : (dt.value * 100) / totalStates,
            totalStates,
          }));

          const modifiedResponse = {
            ...response.data,
            ageGroups,
            states,
          };
          setCurrentChallengesData({ ...modifiedResponse, id: challengeId });
          setIsLoading(false);
        })
        .catch((error) => {
          console.error(error);
          setIsLoading(false);
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [challengeId]
  );

  const getScheduleDateList = useCallback(() => {
    setIsAllChallengeLoading(true);
    const url = `${API_CONFIG.path.challenge}/all`;
    httpService
      .get(url)
      .then((response) => {
        setIsAllChallengeLoading(false);
        setScheduleDateList(
          response.data.map((item: any) =>
            formatDateToMMDDYYYY(convertToGMTDate(item.scheduleDate) as Date)
          )
        );
      })
      .catch((error) => {
        setIsAllChallengeLoading(false);
        console.error(error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleConfirmDelete = () => {
    setIsActionLoading(true);
    if (openModel === 'DELETE A CHALLENGE') {
      httpService
        .deleteRequest(`${API_CONFIG.path.challenge}/${challengeId}`)
        .then(() => {
          navigate(-1);
          setIsConfirmOpen(false);
          setIsActionLoading(false);
          getScheduleDateList();
        })
        .catch((error) => {
          console.log(error);
          setIsActionLoading(false);
        });
    }
  };

  const editChallenges = useCallback(
    (values: any) => {
      let payload: any = {
        label: values.label,
        description: values.description,
        scheduleDate: values.scheduleDate
          ? moment(values.scheduleDate).format('YYYY-MM-DD')
          : null,
      };

      if (!payload.scheduleDate) {
        delete payload.scheduleDate;
      }

      if (payload.scheduleDate === 'Invalid date') {
        payload.scheduleDate = null;
      }

      setIsActionLoading(true);
      httpService
        .put(`${API_CONFIG.path.challenge}/${challengeId}`, payload)
        .then(() => {
          getChallengeDetails();
          setIsActionLoading(false);
          setOpenModel('');
          getScheduleDateList();
        })
        .catch((error) => {
          setIsActionLoading(false);
          console.error(error);
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [challengeId, getChallengeDetails]
  );

  const addChallenges = useCallback((values: any) => {
    setIsActionLoading(true);
    const payload = {
      label: values.label,
      description: values.description,
      scheduleDate: values.scheduleDate
        ? moment(values.scheduleDate).format('YYYY-MM-DD')
        : null,
    };

    if (payload.scheduleDate === 'Invalid date') {
      payload.scheduleDate = null;
    }

    httpService
      .post(API_CONFIG.path.challenge, payload)
      .then(() => {
        navigate(-1);
        setIsActionLoading(false);
        setOpenModel('');
        getScheduleDateList();
      })
      .catch((error) => {
        setIsActionLoading(false);
        console.error(error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = (values: any) => {
    if (values && openModel === 'EDIT A CHALLENGE') {
      editChallenges(values);
    } else if (values && openModel === 'Re-Schedule Challenge') {
      addChallenges(values);
    } else if (values && openModel === 'Schedule Challenge') {
      const payload = {
        scheduleDate: values.scheduleDate
          ? moment(values.scheduleDate).format('YYYY-MM-DD')
          : null,
      };
      httpService
        .put(`${API_CONFIG.path.challenge}/${challengeId}`, payload)
        .then((response) => {
          setIsActionLoading(false);
          setOpenModel('');
          getChallengeDetails();
          setCurrentChallengesData({ ...currentChallengesData, ...response });
        })
        .catch((error) => {
          setIsActionLoading(false);
          console.error(error);
        });
    }
  };

  const handleScheduleChallenge = (type: string) => {
    setOpenModel(type);
  };

  const handleAction = (type: string) => {
    if (type === 'edit') {
      setOpenModel('EDIT A CHALLENGE');
    } else if (type === 'delete') {
      setIsConfirmOpen(true);
      setOpenModel('DELETE A CHALLENGE');
    }
  };

  const handleAddChallengeClose = () => {
    setOpenModel('');
  };

  const handleConfirmClose = () => {
    setIsConfirmOpen(false);
  };

  const noDataFound =
    !isLoading && Object.keys(currentChallengesData).length === 0;

  const disableBtn = isLoading || isAllChallengeLoading || noDataFound;
  const challengeStatus = getChallengeStatus(
    currentChallengesData.scheduleDate
  );
  const isDisableDeleteAction =
    challengeStatus === 'active' || challengeStatus === 'posted';

  const actionButtons = [
    {
      label: 'Edit Challenge',
      icon: <IconEdit color={disableBtn ? theme.colors.gray[5] : 'white'} />,
      type: 'edit',
      boxShadow: !disableBtn ? primaryBoxShadow : 'none',
      disabled: disableBtn,
      handleAction,
    },
    {
      label: 'Delete Challenge',
      icon: (
        <IconTrash
          color={
            disableBtn || isDisableDeleteAction
              ? theme.colors.gray[5]
              : theme.colors.red[6]
          }
        />
      ),
      color: 'red',
      variant: 'light',
      disabled: disableBtn || isDisableDeleteAction,
      type: 'delete',
      boxShadow: !disableBtn ? redBoxShadow : 'none',
      handleAction,
    },
  ];

  const excludedKeysOfInformation = [
    'description',
    'label',
    'postCount',
    'likeCount',
    'commentCounts',
    'views',
    'states',
    'ageGroups',
  ];

  const excludedKeysOfEngagements = [
    'id',
    'description',
    'label',
    'createdAt',
    'scheduleDate',
    'states',
    'ageGroups',
  ];

  const promptInformationDetailsKeys = (
    key: keyof promptInformationDetails
  ): string => {
    const keyMappings: { [key in keyof promptInformationDetails]: string } = {
      id: 'ID',
      createdAt: 'Prompt Created',
      scheduleDate: 'Prompt Posted',
    };
    return keyMappings[key] || key;
  };

  const promptEngagementsDetailsKeys = (
    key: keyof promptEngagementsDetails
  ): string => {
    const keyMappings: { [key in keyof promptEngagementsDetails]: string } = {
      likeCount: 'Likes',
      commentCounts: 'Comments',
      postCount: 'Post Counts',
    };
    return keyMappings[key] || key;
  };

  return (
    <>
      <PageHeader
        title="Daily Challenge Details"
        showBackButton
        showActionButtons={actionButtons}
      />
      {noDataFound ? (
        <Alert icon={<IconAlertCircle size="1rem" />} title="Oops!" color="red">
          Something went wrong! Please try again later.
        </Alert>
      ) : (
        <>
          <ChallengePrompt
            title={currentChallengesData.description}
            status={getChallengeStatus(currentChallengesData.scheduleDate)}
            isPopular={currentChallengesData.isPopular}
            loading={isLoading}
            handleScheduleChallenge={handleScheduleChallenge}
          />

          <Group
            position="apart"
            align="stretch"
            sx={{ '*': { fontFamily: 'Open Sans' } }}
          >
            <Card
              shadow="md"
              mt={30}
              p={20}
              sx={{ width: 'calc(50% - 18px)', position: 'unset', zIndex: -1 }}
            >
              <Text
                sx={{ fontFamily: 'Open Sans' }}
                align="start"
                size={'14px'}
                weight={700}
              >
                PROMPT INFORMATION
              </Text>
              <Skeleton sx={{ zIndex: 1 }} mt={10} visible={isLoading}>
                <Card p={0} sx={{ zIndex: isLoading ? 1 : 12 }}>
                  <Group mt={20}>
                    {Object.entries(currentChallengesData).map(
                      ([key, value]) => {
                        if (excludedKeysOfInformation.includes(key))
                          return null;
                        if (
                          typeof value === 'string' ||
                          typeof value === 'number'
                        ) {
                          const mappedKey = promptInformationDetailsKeys(
                            key as keyof promptInformationDetails
                          );
                          return (
                            <Box key={key} sx={{ width: '100%' }}>
                              <Group key={key} spacing="15px" position="apart">
                                <Text
                                  size="14px"
                                  color={theme.colors.gray[8]}
                                  weight={400}
                                  sx={{
                                    fontFamily: 'Open Sans, sans-serif',
                                    lineHeight: '22px',
                                    opacity: 0.5,
                                    width: '30%',
                                  }}
                                >
                                  {mappedKey.charAt(0).toUpperCase() +
                                    mappedKey.slice(1)}
                                </Text>
                                <Text
                                  size="14px"
                                  color={theme.colors.gray[8]}
                                  weight={key === 'id' ? 600 : 400}
                                  sx={{
                                    fontFamily: 'Open Sans, sans-serif',
                                    lineHeight: '22px',
                                    textAlign: 'end',
                                    width: '50%',
                                  }}
                                >
                                  {key === 'scheduleDate' || key === 'createdAt'
                                    ? convertUnixToGMT(Number(value)).format(
                                        DEFAULT_FORMAT
                                      )
                                    : value}
                                </Text>
                              </Group>
                            </Box>
                          );
                        } else {
                          return null;
                        }
                      }
                    )}
                  </Group>
                </Card>
              </Skeleton>
            </Card>

            <Card
              shadow="md"
              mt={30}
              p={20}
              sx={{ width: 'calc(50% - 18px)', zIndex: -1 }}
            >
              <Text
                align="start"
                size={'14px'}
                weight={700}
                sx={{ fontFamily: 'Open Sans' }}
              >
                PROMPT ENGAGEMENTS
              </Text>
              <Skeleton mt={10} visible={isLoading}>
                <Card p={0} sx={{ zIndex: isLoading ? 1 : 12 }}>
                  <Group mt={20}>
                    {Object.entries(currentChallengesData).map(
                      ([key, value]) => {
                        if (excludedKeysOfEngagements.includes(key))
                          return null;
                        if (
                          typeof value === 'string' ||
                          typeof value === 'number'
                        ) {
                          const mappedKey = promptEngagementsDetailsKeys(
                            key as keyof promptEngagementsDetails
                          );
                          return (
                            <Box key={key} sx={{ width: '100%' }}>
                              <Group key={key} spacing="15px" position="apart">
                                <Text
                                  size="14px"
                                  color={theme.colors.gray[8]}
                                  weight={400}
                                  sx={{
                                    fontFamily: 'Open Sans, sans-serif',
                                    lineHeight: '22px',
                                    opacity: 0.5,
                                    width: '30%',
                                  }}
                                >
                                  {mappedKey.charAt(0).toUpperCase() +
                                    mappedKey.slice(1)}
                                </Text>
                                <Text
                                  size="14px"
                                  color={theme.colors.gray[8]}
                                  weight={400}
                                  sx={{
                                    fontFamily: 'Open Sans, sans-serif',
                                    lineHeight: '22px',
                                    textAlign: 'end',
                                    width: '50%',
                                  }}
                                >
                                  {value}
                                </Text>
                              </Group>
                            </Box>
                          );
                        } else {
                          return null;
                        }
                      }
                    )}
                  </Group>
                </Card>
              </Skeleton>
            </Card>
          </Group>

          <Group
            position="apart"
            align="stretch"
            sx={{ '*': { fontFamily: 'Open Sans' } }}
          >
            <Card
              shadow="md"
              mt={30}
              p={20}
              sx={{ width: 'calc(50% - 18px)', zIndex: -1 }}
            >
              <Text
                sx={{ fontFamily: 'Open Sans' }}
                align="start"
                size={'14px'}
                weight={700}
              >
                TOP STATES REACH
              </Text>
              <Skeleton mt={10} sx={{ zIndex: 1 }} visible={isLoading}>
                <Card p={0} sx={{ zIndex: isLoading ? 1 : 12 }}>
                  <Group mt={20}>
                    {currentChallengesData?.states &&
                    currentChallengesData?.states.length === 0 ? (
                      <Card p={0} sx={{ zIndex: 12 }}>
                        <Text
                          size="14px"
                          color={theme.colors.gray[8]}
                          sx={{
                            textAlign: 'center',
                            fontFamily: 'Open Sans, sans-serif',
                            lineHeight: '22px',
                            opacity: 0.5,
                          }}
                        >
                          No data found
                        </Text>
                      </Card>
                    ) : (
                      <>
                        {currentChallengesData?.states?.map((item, index) => (
                          <Box key={index} sx={{ width: '100%' }}>
                            <Group
                              key={index}
                              spacing="15px"
                              sx={{ display: 'flex', gap: '0' }}
                            >
                              <Text
                                size="12px"
                                color={theme.colors.gray[8]}
                                weight={400}
                                sx={{
                                  lineHeight: '22px',
                                  opacity: 0.5,
                                  width: '100px',
                                  marginRight: '25px',
                                }}
                              >
                                {item.name}
                              </Text>
                              <Progress
                                sections={[
                                  {
                                    value: item.value as number,
                                    color: 'cyan',
                                    label: (item.value || 0).toString(),
                                    tooltip: `${item.value || 0} user${
                                      item.value > 1 && item.value !== 0
                                        ? 's are'
                                        : ' is'
                                    }  from state ${item.name}`,
                                  },
                                ]}
                                size={15}
                                color={'cyan'}
                                sx={{ width: '78%' }}
                                radius={'xs'}
                              />
                            </Group>
                          </Box>
                        ))}
                      </>
                    )}
                  </Group>
                </Card>
              </Skeleton>
            </Card>

            <Card
              shadow="md"
              mt={30}
              p={20}
              sx={{ width: 'calc(50% - 18px)', zIndex: -1 }}
            >
              <Text
                sx={{ fontFamily: 'Open Sans' }}
                align="start"
                size={'14px'}
                weight={700}
              >
                AGE GROUP
              </Text>
              <Skeleton mt={10} visible={isLoading}>
                <Card p={0} sx={{ zIndex: isLoading ? 1 : 12 }}>
                  <Group mt={20}>
                    {currentChallengesData?.ageGroups &&
                    currentChallengesData?.ageGroups.length === 0 ? (
                      <Flex justify={'center'}>
                        <Text
                          size="14px"
                          color={theme.colors.gray[8]}
                          sx={{
                            fontFamily: 'Open Sans, sans-serif',
                            lineHeight: '22px',
                            opacity: 0.5,
                          }}
                        >
                          No data found
                        </Text>
                      </Flex>
                    ) : (
                      <>
                        {currentChallengesData?.ageGroups?.map(
                          (item, index) => (
                            <Box key={index} sx={{ width: '100%' }}>
                              <Group
                                key={index}
                                spacing="15px"
                                display={'flex'}
                                sx={{ gap: '0' }}
                              >
                                <Text
                                  size="12px"
                                  color={theme.colors.gray[8]}
                                  weight={400}
                                  sx={{
                                    lineHeight: '22px',
                                    opacity: 0.5,
                                    width: '67px',
                                    marginRight: '25px',
                                  }}
                                >
                                  {item.ageRange.replace(/yr/g, '')}
                                </Text>
                                <Progress
                                  sections={[
                                    {
                                      value: item.percentage as number,
                                      color: 'cyan',
                                      label: item.value.toString(),
                                      tooltip: `${item.value} users out of ${item.totalUser}`,
                                    },
                                  ]}
                                  size={15}
                                  color={'cyan'}
                                  sx={{ width: '81%' }}
                                  radius={'xs'}
                                />
                              </Group>
                            </Box>
                          )
                        )}
                      </>
                    )}
                  </Group>
                </Card>
              </Skeleton>
            </Card>
          </Group>

          <Confirm
            loading={isActionLoading}
            opened={isConfirmOpen}
            modalTitle="Are you sure you want to Delete the challenge?"
            handleConfirm={handleConfirmDelete}
            handleClose={handleConfirmClose}
            cancelBtnText="Cancel"
            submitBtnText="Delete"
            size={'450px'}
          />

          <ChallengeModal
            loading={isActionLoading || isAllChallengeLoading}
            modalTitle={openModel}
            opened={
              (openModel === 'EDIT A CHALLENGE' &&
                Object.keys(currentChallengesData).length > 0) ||
              openModel === 'Schedule Challenge' ||
              openModel === 'Re-Schedule Challenge'
            }
            challenge={
              openModel !== 'Schedule Challenge' ? currentChallengesData : {}
            }
            handleClose={handleAddChallengeClose}
            scheduleDateList={scheduleDateList}
            handleSubmit={handleSubmit}
          />
        </>
      )}
    </>
  );
};

export default ChallengeDetails;
