import { Action, CORSCheckerState } from '../shared/interface';
import * as actionTypes from './action-types';

const initialState: CORSCheckerState = {
  hasCorsError: false,
};

const reducer = (
  state: CORSCheckerState = initialState,
  action: Action
): CORSCheckerState => {
  switch (action.type) {
    case actionTypes.CORS_STATUS_UPDATE:
      return {
        ...state,
        hasCorsError: action.payload,
      };
    default:
      return state;
  }
};
export default reducer;
