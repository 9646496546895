import { useEffect } from "react";
import { useWindowScroll } from "@mantine/hooks";
import { useLocation } from "react-router-dom";

export default function ScrollToTop() {
  const { pathname } = useLocation();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [scroll, scrollTo] = useWindowScroll();

  useEffect(() => {
    setTimeout(() => {
      scrollTo({ y: 0 });
    }, 100);
  }, [pathname, scrollTo]);

  return null;
}
