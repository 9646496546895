import React, { useMemo, useState } from 'react';
import {
  Box,
  Card,
  Group,
  Image,
  LoadingOverlay,
  Text,
  TextInput,
  Tooltip,
} from '@mantine/core';
import DataTable, { TableColumn } from 'react-data-table-component';
import { IconSearch } from '../../../shared/components/icons/icons';
import { dataTableStyles, searchInputStyle } from '../../../shared/constants/constants';
import { IPagination } from '../../../shared/interface';
import { IUserPostInfo } from '../interface/users.interface';

export interface IUserPostsList {
  userPostsData: IUserPostInfo[];
  loading: boolean;
  pagination: IPagination;
  handleSearch: (value: string) => void;
  searchProps: any;
  postSearch: string;
}

const textStyle = {
  fontFamily: 'Open Sans, sans-serif',
  fontWeight: 400,
  fontSize: '14px',
  lineHeight: 1.5,
};

const UserPostsList: React.FC<IUserPostsList> = (props) => {
  const {
    userPostsData,
    loading,
    pagination,
    handleSearch,
    searchProps,
    postSearch,
  } = props;
  const { currentPage, recordPerPage, total } = pagination;
  const [isInputFocused, setInputFocused] = useState(false);

  const columns: TableColumn<any>[] = useMemo(() => {
    return [
      {
        name: 'Sr. No.',
        width: "80px",
        center: true,
        selector: (row) => row.id,
        cell: (row, index) => {
          return <span>{(currentPage - 1) * recordPerPage + index + 1}</span>
        }
      },
      {
        name: 'Post',
        center: true,
        multiline: true,
        selector: (row) => row.photo || "-",
        cell: (row) => {
          return (
            <Image
              my={'xs'}
              src={
                row.photo ||
                'https://d3gf7zawoqsit4.cloudfront.net/user/150/default.png'
              }
              alt={row.postDescription}
              radius="md"
              width={70}
              height={70}
            />
          )
        }
      },
      {
        name: 'Caption',
        width: "400px",
        center: true,
        selector: (row) => row.postDescription || "-",
        cell: (row) => {
          return (
            <Tooltip label={row.postDescription || '-'} multiline sx={{ maxWidth: "500px" }} >
              <Text sx={textStyle} align="center" lineClamp={4} my={'md'}>
                {row.postDescription}
              </Text>
            </Tooltip>
          )
        }
      },
      {
        name: 'Daily Challenge',
        center: true,
        selector: (row) => row.challengeDescription || "-",
      },
      {
        name: 'Likes',
        center: true,
        width: "150px",
        selector: (row) => row.likeCount ?? '-',
      },
      {
        name: 'Comments',
        center: true,
        width: "150px",
        selector: (row) => row.commentCount ?? '-',
      },
    ]
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userPostsData])

  const loadingComponent = useMemo(() => (
    <Card sx={loading ? { minHeight: "60px" } : {}}>
      {loading ? (
        <LoadingOverlay
          visible={true}
          overlayBlur={2}
          zIndex={5}
          sx={{ borderRadius: '10px' }}
        />
      ) : (
        <Text>
          No any challenges found.
        </Text>
      )}
    </Card>
  ), [loading])

  return (
    <Box pos="relative">
      <Group
        align="center"
        position="apart"
        mb={15}
        sx={{
          height: '37px',
        }}
      >
        <Group>
          <Text
            size="xl"
            weight={700}
            sx={{
              fontFamily: 'Open Sans, sans-serif',
              fontSize: '18px',
              color: '#2E3646',
              fontWeight: 500,
              opacity: 0.5,
            }}
          >
            {`${total} User Posts`}
          </Text>
        </Group>
        <Group>
          <Group sx={{ position: 'relative', borderRadius: 30 }}>
            <TextInput
              placeholder="Search"
              variant="outline"
              value={postSearch}
              styles={searchInputStyle(isInputFocused)}
              icon={<IconSearch color="#ADADAD" />}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                handleSearch(e.target.value);
              }}
              onFocus={() => setInputFocused(true)}
              onBlur={() => setInputFocused(false)}
              {...searchProps}
            />
          </Group>
        </Group>
      </Group>
      <Box sx={{
        boxShadow: "rgba(0, 0, 0, 0.1) 0px 10px 50px -10px",
        borderRadius: "9px",
      }}>
        <DataTable
          striped
          responsive
          pointerOnHover
          persistTableHead
          noDataComponent={loadingComponent}
          highlightOnHover
          customStyles={dataTableStyles}
          columns={columns}
          data={loading ? [] : userPostsData}
        />
      </Box>
    </Box>
  );
};

export default UserPostsList;
