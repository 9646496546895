import {
  Box,
  Button,
  Flex,
  Group,
  Text,
} from '@mantine/core';
import { IconChevronLeft, IconChevronRight } from '@tabler/icons-react';
import moment from 'moment';
import { useState } from 'react';
import { NavigateAction, ToolbarProps } from 'react-big-calendar';
import CustomBadgeGroup from '../../../shared/components/eventBadge/customBadgeGroup';
import { challengeType } from '../../../shared/constants/constants';
import 'react-big-calendar/lib/css/react-big-calendar.css'; // Import the calendar CSS
import '../styles/calender.scss';

interface CustomToolbarProps extends ToolbarProps<any, any> {
  date: Date;
}
const CustomToolbar: React.FC<CustomToolbarProps> = ({
  onNavigate,
  date,
}) => {

  const [activeTab, setActiveTab] = useState<string>(
    moment(date).isSame(moment(), 'day')
      ? 'TODAY'
      : moment(date).isBefore(moment(), 'day')
        ? 'PREV'
        : 'NEXT'
  );

  const handleNavigate = (action: NavigateAction) => {
    setActiveTab(action);
    if (action === 'TODAY') {
      onNavigate(action, new Date()); // Pass a Date for the TODAY action
    } else {
      onNavigate(action);
    }
  };

  const months = moment.months();

  return (
    <Box
      p={15}
      className="custom-toolbar"
      sx={{ borderBottom: '1px solid #ddd', zIndex: 100 }}
    >
      <Group align="center" position="apart">
        <Flex gap={10} align="center">
          {/* <Select
            name="rbc-toolbar-select"
            placeholder="Month"
            value={view}
            iconWidth={100}
            data={[
              { value: 'month', label: 'Month' },
              // { value: 'week', label: 'Week' },
            ]}
            rightSection={<IconChevronDownFilled />}
            rightSectionProps={{
              style: {
                pointerEvents: 'none',
              },
            }}
            sx={{
              width: '100px',
              boxShadow: theme.shadows.sm,
              background: 'transparent',
              borderRadius: '10px',
              fontSize: '12px',
            }}
            searchable
            onChange={(value: string) => handleViewChange(value)}
            transitionProps={{
              transition: 'pop-top-left',
              duration: 100,
              timingFunction: 'ease',
            }}
          /> */}
          <Button.Group>
            <Button
              size="sm"
              variant={activeTab === 'PREV' ? 'filled' : 'outline'}
              color={activeTab === 'PREV' ? 'blue' : 'gray'}
              onClick={() => {
                handleNavigate('PREV');
              }}
              sx={{
                fontFamily: 'Open Sans, sans-serif',
                fontSize: '12px',
              }}
              leftIcon={<IconChevronLeft width="18px" />}
            >
              Back
            </Button>
            <Button
              size="sm"
              variant={activeTab === 'TODAY' ? 'filled' : 'outline'}
              color={activeTab === 'TODAY' ? 'blue' : 'gray'}
              onClick={() => {
                handleNavigate('TODAY');
              }}
              sx={{
                fontFamily: 'Open Sans, sans-serif',
                fontSize: '12px',
              }}
            >
              Today
            </Button>
            <Button
              size="sm"
              variant={activeTab === 'NEXT' ? 'filled' : 'outline'}
              color={activeTab === 'NEXT' ? 'blue' : 'gray'}
              onClick={() => {
                handleNavigate('NEXT');
              }}
              sx={{
                fontFamily: 'Open Sans, sans-serif',
                fontSize: '12px',
              }}
              rightIcon={<IconChevronRight />}
            >
              Next
            </Button>
          </Button.Group>
        </Flex>
        <Text
          size="28px"
          weight={500}
          sx={{ fontFamily: 'Open Sans, sans-serif', lineHeight: 'normal' }}
        >
          {months[date.getMonth()]} {date.getFullYear()}
        </Text>
        <CustomBadgeGroup
          data={challengeType}
          align={'flex-end'}
          sx={{ marginTop: 0 }}
        />
      </Group>
    </Box>
  );
};

export default CustomToolbar;
