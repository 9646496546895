import { createStyles, rem } from '@mantine/core';

export const useNavbarStyles = createStyles((theme) => ({
  control: {
    display: 'flex',
    fontSize: 'normal',
    alignItems: 'center',
    width: '100%',
    paddingLeft: '20px',
    height: '50px',
    borderRadius: '1px',
    transition: '0.2s all ease-in-out',
    borderLeft: `3px solid transparent`,

    '&:hover': {
      backgroundColor: theme.colors.blue[0],
      color: theme.black,
    },
  },

  controlActive: {
    borderLeft: `3px solid ${theme.colors.blue[1]}`,
    backgroundColor: theme.colors.blue[0],
    color: theme.colors.blue[1],
    fontWeight: 700,

    '&:hover': {
      backgroundColor: theme.colors.blue[0],
      color: theme.colors.blue[1],
    },
  },

  link: {
    fontWeight: 'normal',
    display: 'block',
    textDecoration: 'none',
    padding: `${theme.spacing.xs} ${theme.spacing.xs}`,
    paddingLeft: rem(33),
    marginLeft: '34px',
    fontSize: theme.fontSizes.sm,
    color: theme.colors.gray[7],
    borderLeft: `${rem(1)} solid ${
      theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[3]
    }`,

    '&:hover': {
      backgroundColor: theme.colors.gray[0],
      color: theme.black,
    },
  },

  linkActive: {
    backgroundColor: theme.colors.gray[0],
    color: theme.black,
  },

  chevron: {
    transition: 'transform 200ms ease',
  },
}));

export const useNavbarWrapperStyles = createStyles((theme) => ({
  navbar: {
    backgroundColor:
      theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.white,
    paddingBottom: 0,
    boxShadow: '4px 10px 15px 0px #0000000D',
    zIndex: 9999999,
  },

  header: {
    minHeight: '100px',
  },

  links: {
    marginTop: theme.spacing.xl,
  },

  linksInner: {
    paddingTop: theme.spacing.xl,
    paddingBottom: theme.spacing.xl,
  },
}));
