import {
  Box,
  Button,
  Card,
  Flex,
  Grid,
  Group,
  Skeleton,
  Text,
  useMantineTheme,
} from '@mantine/core';
import { useDebouncedValue } from '@mantine/hooks';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Confirm from '../../../shared/components/confirm/confirm';
import { IconBlock } from '../../../shared/components/icons/icons';
import PageHeader from '../../../shared/components/pageHeader/pageHeader';
import PaginationComponent from '../../../shared/components/pagination/pagination';
import {
  API_CONFIG,
  ModalType,
  actionPayloadMapper,
  confirmBtnTextMapper,
  getSearchProps,
  modalTitleMapper,
  redBoxShadow,
} from '../../../shared/constants/constants';
import useLocalStorageFilter from '../../../shared/hoc/useLocalStorageFilter';
import { IPagination } from '../../../shared/interface';
import httpService from '../../../shared/services/http.service';
import {
  addLeadingZero,
  formatUnixTimestamp,
} from '../../../shared/util/utility';
import FlaggedPostContent from '../components/flaggedPostContent';
import FlaggedUsersList from '../components/flaggedUsersList';

import {
  IActionHandlerMap,
  IFlaggedPostDetails,
  IFlaggedUsersList,
} from '../interface/flaggedPosts.interface';
import ImagePreviewModal from '../../../shared/components/imagePreviewModal/ImagePreviewModal';

const FlaggedPostDetails: React.FC = () => {
  const theme = useMantineTheme();
  const navigate = useNavigate();
  const { postId }: any = useParams();
  const [filters, updateFilters] = useLocalStorageFilter(
    'flaggedPostDetailFilters',
    {
      currentPage: '1',
      flaggedUserSearch: '',
    }
  );
  const [currentFlagPostDetails, setCurrentFlagPostDetails] =
    useState<IFlaggedPostDetails>({} as IFlaggedPostDetails);
  const [flaggedUsersList, setFlaggedUsersList] = useState<IFlaggedUsersList[]>(
    []
  );
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingPost, setIsLoadingPost] = useState(false);
  const [isLoadingAction, setIsLoadingAction] = useState(false);
  const [showImagePreview, setShowImagePreview] = useState(false);
  const [pagination, setPagination] = useState<IPagination>({
    currentPage: 1,
    nextPage: null,
    recordPerPage: 5,
    remainingCount: 0,
    total: 0,
    totalPages: 1,
  });
  const savedCurrentPage = parseInt(filters.currentPage || '1', 10);

  const [flaggedUserSearch, setFlaggedUserSearch] = useState(
    filters.flaggedUserSearch || ''
  );
  const [debouncedflaggedUserSearch] = useDebouncedValue(
    flaggedUserSearch,
    flaggedUserSearch ? 700 : 0
  );
  const [modalData, setModalData] = useState<{
    type: ModalType;
    title: string;
  }>({
    type: '',
    title: '',
  });

  useEffect(() => {
    getFlaggedPostDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // Save filter values to local storage
    updateFilters({
      currentPage: savedCurrentPage.toString(),
      flaggedUserSearch,
    });

    const hasOnlySpaces = /^ *$/.test(debouncedflaggedUserSearch);

    if (!hasOnlySpaces) {
      getFlaggedUsersList(
        savedCurrentPage,
        pagination.recordPerPage,
        debouncedflaggedUserSearch.trim()
      );
    } else if (debouncedflaggedUserSearch === '') {
      getFlaggedUsersList(savedCurrentPage, pagination.recordPerPage, '');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedflaggedUserSearch, savedCurrentPage]);

  // Get Flagged Post Details
  const getFlaggedPostDetails = useCallback(
    () => {
      setIsLoading(true);
      const detailUrl = `${API_CONFIG.path.flag}/${postId}`;
      httpService
        .get(detailUrl)
        .then((response) => {
          const flagPostDetailsData = response.data;
          setIsLoading(false);
          setCurrentFlagPostDetails(flagPostDetailsData);
        })
        .catch((error) => {
          console.error(error);
          setIsLoading(false);
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  // Get Flagged Users List
  const getFlaggedUsersList = useCallback(
    (
      currentPage = pagination.currentPage,
      recordPerPage = pagination.recordPerPage,
      search = ''
    ) => {
      setIsLoadingPost(true);
      const detailUrl = `${API_CONFIG.path.flag}/user-flag-list/${postId}`;
      httpService
        .get(detailUrl, {
          currentPage,
          recordPerPage,
          search,
        })
        .then((response) => {
          const { data, ...paginationData } = response.data;
          setPagination(paginationData);
          setFlaggedUsersList(data);
          setIsLoadingPost(false);
        })
        .catch((error) => {
          console.error(error);
          setIsLoadingPost(false);
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [pagination]
  );

  // Resolve Flagged Post
  const resolveOrRejectFlaggedPost = useCallback(
    (actionTypeStatus = '', flaggedPostId = '') => {
      setIsLoadingAction(true);
      const detailUrl = `${API_CONFIG.path.flag}/${postId}`;
      httpService
        .put(detailUrl, {
          status: actionPayloadMapper[actionTypeStatus],
          postId: flaggedPostId,
        })
        .then(() => {
          closeConfirmModal();
          setIsLoadingAction(false);
          if (actionPayloadMapper[actionTypeStatus] === 'reject') {
            handleFlagListView();
          } else {
            getFlaggedPostDetails();
          }
        })
        .catch((error) => {
          console.error(error);
          setIsLoadingAction(false);
          closeConfirmModal();
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  // Block Unblock User
  const blockUser = useCallback(
    (isBlocked: boolean = false, userId: string) => {
      setIsLoadingAction(true);
      const detailUrl = `${API_CONFIG.path.users}/${userId}/status`;
      httpService
        .put(detailUrl, {
          isBlocked,
        })
        .then(() => {
          setIsLoadingAction(false);
          closeConfirmModal();
          getFlaggedPostDetails();
        })
        .catch((error) => {
          console.error(error);
          setIsLoadingAction(false);
          closeConfirmModal();
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  // Delete User Post
  const deleteUserPost = useCallback(
    (flaggedPostId = '') => {
      setIsLoadingAction(true);
      const detailUrl = `${API_CONFIG.path.flag}/${flaggedPostId}/${postId}`;
      httpService
        .deleteRequest(detailUrl)
        .then(() => {
          setIsLoadingAction(false);
          closeConfirmModal();
          handleFlagListView();
        })
        .catch((error) => {
          console.error(error);
          setIsLoadingAction(false);
          closeConfirmModal();
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const handleUserView = (userId: string) => {
    navigate(`/users/${userId}`);
  };

  const handleFlagListView = () => {
    navigate(`/flagged-posts`);
  };

  const handleResolve = () => {
    resolveOrRejectFlaggedPost('resolve', currentFlagPostDetails.postId);
  };

  const handleBlockPost = () => {
    resolveOrRejectFlaggedPost('reject', currentFlagPostDetails.postId);
  };

  const handleBlockUser = () => {
    blockUser(!currentFlagPostDetails.isBlock, currentFlagPostDetails.userId);
  };

  const handleDeleteUser = () => {
    deleteUserPost(currentFlagPostDetails.postId);
    closeConfirmModal();
  };

  // Helper function to open the confirmation modal with specific content
  const openConfirmModal = (type: ModalType) => {
    setModalData({
      type,
      title: modalTitleMapper[type],
    });
  };

  // Helper function to close the confirmation modal
  const closeConfirmModal = () => {
    setModalData({
      ...modalData,
      type: '',
    });
  };

  const actionHandlerMap: IActionHandlerMap = {
    resolve: handleResolve,
    blockPost: handleBlockPost,
    blockUser: handleBlockUser,
    unblockUser: handleBlockUser,
    deletePost: handleDeleteUser,
  };

  const handlePagination = useCallback(
    (page: number) => {
      if (page !== pagination.currentPage) {
        updateFilters({
          ...filters,
          currentPage: page.toString(),
        });
        getFlaggedUsersList(
          page,
          pagination.recordPerPage,
          debouncedflaggedUserSearch ? debouncedflaggedUserSearch.trim() : ''
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [debouncedflaggedUserSearch, pagination]
  );

  const handleAction = () => {
    const actionHandler = actionHandlerMap[modalData.type];
    if (actionHandler) {
      actionHandler();
    }
  };

  const handleSearch = (value: string) => {
    setFlaggedUserSearch(value);
    updateFilters({
      ...filters,
      currentPage: '1',
    });
  };
  const searchProps = getSearchProps(flaggedUserSearch, () => {
    setFlaggedUserSearch('');
  });

  return (
    <>
      <PageHeader title="Flagged Post Details" showBackButton />
      <Box mb={30}>
        <Grid>
          <Grid.Col span={12} mb={30}>
            <FlaggedPostContent
              currentFlagPostDetails={currentFlagPostDetails}
              openConfirmModal={openConfirmModal}
              handleImageClick={() => setShowImagePreview(true)}
              isLoading={isLoading}
            />
          </Grid.Col>
        </Grid>
        <Grid>
          <Grid.Col span={6}>
            <Card shadow="sm" radius="sm" p={20} sx={{ height: '100%' }}>
              <Text align="start" size="xl" weight={700} sx={cardHeaderStyle}>
                POST INFORMATION
              </Text>
              <Skeleton
                visible={isLoading}
                sx={{
                  minHeight: 'calc(100% - 30px)',
                }}
              >
                <Card
                  p={0}
                  sx={{
                    minHeight: 200,
                    zIndex: isLoading ? 1 : 12,
                  }}
                >
                  <Group
                    spacing="sm"
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'flex-start',
                      marginBottom: 10,
                    }}
                  >
                    <Text
                      size="sm"
                      sx={{ ...commonTextStyle, opacity: 0.5, width: '23%' }}
                    >
                      ID
                    </Text>
                    <Text
                      size="sm"
                      sx={{
                        ...commonTextStyle,
                        textAlign: 'end',
                        maxWidth: 'auto',
                        fontFamily: 'Open Sans, sans-serif',
                        width: '73%',
                      }}
                    >
                      {currentFlagPostDetails.postId}
                    </Text>
                  </Group>
                  <Group
                    spacing="sm"
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'flex-start',
                      marginBottom: 10,
                    }}
                  >
                    <Text
                      size="sm"
                      sx={{ ...commonTextStyle, opacity: 0.5, width: '23%' }}
                    >
                      Post created
                    </Text>
                    <Text
                      size="sm"
                      sx={{
                        ...commonTextStyle,
                        textAlign: 'end',
                        maxWidth: 'auto',
                        fontFamily: 'Open Sans, sans-serif',
                        width: '73%',
                      }}
                    >
                      {currentFlagPostDetails.postCreatedAt &&
                        formatUnixTimestamp(
                          Number(currentFlagPostDetails.postCreatedAt)
                        )}
                    </Text>
                  </Group>
                  <Group
                    spacing="sm"
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'flex-start',
                      marginBottom: 10,
                    }}
                  >
                    <Text
                      size="sm"
                      sx={{ ...commonTextStyle, opacity: 0.5, width: '35%' }}
                    >
                      No of times post reported
                    </Text>
                    <Text
                      size="sm"
                      sx={{
                        ...commonTextStyle,
                        textAlign: 'end',
                        maxWidth: 'auto',
                        fontFamily: 'Open Sans, sans-serif',
                        width: '55%',
                        color: '#FF6252',
                      }}
                    >
                      {currentFlagPostDetails.postReportCount &&
                        addLeadingZero(currentFlagPostDetails.postReportCount)}
                    </Text>
                  </Group>
                </Card>
              </Skeleton>
            </Card>
          </Grid.Col>
          <Grid.Col span={6}>
            <Card shadow="sm" p={20} radius="sm" sx={{ height: '100%' }}>
              <Text align="start" size="xl" weight={700} sx={cardHeaderStyle}>
                USER DETAILS
              </Text>
              <Skeleton visible={isLoading}>
                <Card p={0} sx={{ zIndex: isLoading ? 1 : 12 }}>
                  <Group
                    spacing="sm"
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'flex-start',
                      marginBottom: 10,
                    }}
                  >
                    <Text
                      size="sm"
                      sx={{ ...commonTextStyle, opacity: 0.5, width: '23%' }}
                    >
                      User ID
                    </Text>
                    <Text
                      size="sm"
                      sx={{
                        ...commonTextStyle,
                        textAlign: 'end',
                        maxWidth: 'auto',
                        fontFamily: 'Open Sans, sans-serif',
                        width: '73%',
                      }}
                    >
                      {currentFlagPostDetails.userId}
                    </Text>
                  </Group>
                  <Group
                    spacing="sm"
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'flex-start',
                      marginBottom: 10,
                    }}
                  >
                    <Text
                      size="sm"
                      sx={{ ...commonTextStyle, opacity: 0.5, width: '23%' }}
                    >
                      Username
                    </Text>
                    <Text
                      size="sm"
                      sx={{
                        ...commonTextStyle,
                        textAlign: 'end',
                        maxWidth: 'auto',
                        fontFamily: 'Open Sans, sans-serif',
                        width: '73%',
                      }}
                    >
                      {currentFlagPostDetails.username
                        ? currentFlagPostDetails.username
                        : '-'}
                    </Text>
                  </Group>
                  <Group
                    spacing="sm"
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'flex-start',
                      marginBottom: 10,
                    }}
                  >
                    <Text
                      size="sm"
                      sx={{ ...commonTextStyle, opacity: 0.5, width: '23%' }}
                    >
                      Full Name
                    </Text>
                    <Text
                      size="sm"
                      sx={{
                        ...commonTextStyle,
                        textAlign: 'end',
                        maxWidth: 'auto',
                        fontFamily: 'Open Sans, sans-serif',
                        width: '73%',
                      }}
                    >
                      {currentFlagPostDetails.fullname
                        ? currentFlagPostDetails.fullname
                        : '-'}
                    </Text>
                  </Group>
                  <Group
                    spacing="sm"
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'flex-start',
                      marginBottom: 10,
                    }}
                  >
                    <Text
                      size="sm"
                      sx={{ ...commonTextStyle, opacity: 0.5, width: '35%' }}
                    >
                      No of times user Post has been Flagged
                    </Text>
                    <Text
                      size="sm"
                      sx={{
                        ...commonTextStyle,
                        textAlign: 'end',
                        maxWidth: 'auto',
                        fontFamily: 'Open Sans, sans-serif',
                        width: '55%',
                        color: '#FF6252',
                      }}
                    >
                      {addLeadingZero(
                        currentFlagPostDetails.userPostReportCount
                          ? currentFlagPostDetails.userPostReportCount
                          : 0
                      )}
                    </Text>
                  </Group>
                  <Flex
                    mt={25}
                    gap={20}
                    sx={{ width: '100%' }}
                    justify="center"
                  >
                    {!currentFlagPostDetails.isBlock ? (
                      <Button
                        color="red"
                        variant="light"
                        leftIcon={<IconBlock color={'#FF6252'} />}
                        onClick={() => openConfirmModal('blockUser')}
                        sx={{
                          fontSize: '12px',
                          borderRadius: theme.radius.sm,
                          boxShadow: redBoxShadow,
                          width: '170px',
                          fontFamily: 'Open Sans, sans-serif',
                        }}
                        fw={700}
                      >
                        Block User
                      </Button>
                    ) : (
                      <Button
                        color="dark"
                        variant="light"
                        rel="noopener noreferrer"
                        onClick={() => openConfirmModal('unblockUser')}
                        sx={{
                          borderRadius: '10px',
                          boxShadow: redBoxShadow,
                          width: '170px',
                          fontFamily: 'Open Sans, sans-serif',
                        }}
                        loading={isLoadingAction}
                      >
                        Unblock User
                      </Button>
                    )}
                    <Button
                      color="gray"
                      variant="filled"
                      sx={{
                        fontSize: '12px',
                        borderRadius: theme.radius.sm,
                        boxShadow: theme.shadows.sm,
                        fontFamily: 'Open Sans, sans-serif',
                        width: '170px',
                      }}
                      fw={400}
                      onClick={() =>
                        handleUserView(currentFlagPostDetails.userId)
                      }
                    >
                      Go to user profile
                    </Button>
                  </Flex>
                </Card>
              </Skeleton>
            </Card>
          </Grid.Col>
        </Grid>
      </Box>
      <FlaggedUsersList
        flaggedPostsData={flaggedUsersList}
        handleSearch={handleSearch}
        loading={isLoadingPost}
        pagination={pagination}
        flaggedUserSearch={flaggedUserSearch}
        searchProps={searchProps}
      />
      {pagination.totalPages > 1 && (
        <PaginationComponent
          loading={isLoading}
          pagination={pagination}
          fetchData={handlePagination}
        />
      )}
      {modalData.type && (
        <Confirm
          loading={isLoadingAction}
          opened={!!modalData.type}
          modalTitle={modalData.title}
          handleConfirm={handleAction}
          handleClose={closeConfirmModal}
          cancelBtnText="Cancel"
          submitBtnText={confirmBtnTextMapper[modalData.type]}
          size={'450px'}
        />
      )}
      <ImagePreviewModal
        imageLink={currentFlagPostDetails.postImage}
        loading={isLoading}
        opened={showImagePreview}
        handleClose={() => setShowImagePreview(false)}
        className="image-preview-modal"
      />
    </>
  );
};

export default FlaggedPostDetails;

const commonTextStyle = {
  color: '#2E3646',
  fontWeight: 400,
  width: '80%',
  fontFamily: 'Open Sans, sans-serif',
  lineHeight: '22px',
};

const cardHeaderStyle = {
  marginBottom: '16px',
  fontFamily: 'Open Sans, sans-serif',
  fontWeight: 700,
  fontSize: '14px',
};
