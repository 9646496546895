import { useDebouncedValue } from '@mantine/hooks';
import React, { useCallback, useEffect, useState } from 'react';
import Confirm from '../../../shared/components/confirm/confirm';
import PageHeader from '../../../shared/components/pageHeader/pageHeader';
import PaginationComponent from '../../../shared/components/pagination/pagination';
import {
  API_CONFIG,
  actionPayloadMapper,
  confirmBtnTextMapper,
  getSearchProps,
  modalTitleMapper,
} from '../../../shared/constants/constants';
import useLocalStorageFilter from '../../../shared/hoc/useLocalStorageFilter';
import { IPagination } from '../../../shared/interface';
import httpService from '../../../shared/services/http.service';
import FlaggedPostsList from '../components/flaggedPostsList';
import { IFlaggedPostsListInfo } from '../interface/flaggedPosts.interface';

const FlaggedPosts: React.FC = () => {
  const [filters, updateFilters] = useLocalStorageFilter('flaggedPostFilters', {
    currentPage: '1',
    postSearch: '',
    currentStatus: '',
  });
  const [flaggedPostListData, setFlaggedPostListData] = useState<
    IFlaggedPostsListInfo[]
  >([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingAction, setIsLoadingAction] = useState(false);
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const [currentPostId, setCurrentPostId] = useState('');
  const [flaggedPostId, setFlaggedPostId] = useState('');
  const [pagination, setPagination] = useState<IPagination>({
    currentPage: 1,
    nextPage: null,
    recordPerPage: 10,
    remainingCount: 0,
    total: 0,
    totalPages: 1,
  });
  const savedCurrentPage = parseInt(filters.currentPage || '1', 10);

  const [postSearch, setPostSearch] = useState(filters.postSearch || '');
  const [debouncedPostSearch] = useDebouncedValue(
    postSearch,
    postSearch ? 700 : 0
  );
  const [currentStatus, setCurrentStatus] = useState(
    filters.currentStatus || ''
  );
  const [postActionType, setPostActionType] = useState('');

  useEffect(() => {
    // Save filter values to local storage
    updateFilters({
      currentPage: savedCurrentPage.toString(),
      postSearch,
      currentStatus,
    });

    const hasOnlySpaces = /^ *$/.test(debouncedPostSearch);

    if (!hasOnlySpaces) {
      getFlaggedPostList(
        savedCurrentPage,
        pagination.recordPerPage,
        debouncedPostSearch.trim(),
        currentStatus || ''
      );
    } else if (debouncedPostSearch === '') {
      getFlaggedPostList(
        savedCurrentPage,
        pagination.recordPerPage,
        '',
        currentStatus || ''
      );
    }

    return () => {
      setCurrentPostId('');
      setFlaggedPostId('');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedPostSearch, currentStatus, savedCurrentPage]);

  // Get Flagged Post List
  const getFlaggedPostList = useCallback(
    (
      currentPage = pagination.currentPage,
      recordPerPage = pagination.recordPerPage,
      search = '',
      status = ''
    ) => {
      setIsLoading(true);
      const detailUrl = `${API_CONFIG.path.flag}`;
      httpService
        .get(detailUrl, {
          currentPage,
          recordPerPage,
          search,
          status,
        })
        .then((response) => {
          const { data, ...paginationData } = response.data;
          setPagination(paginationData);
          setFlaggedPostListData(data);
          setIsLoading(false);
        })
        .catch((error) => {
          console.error(error);
          setIsLoading(false);
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [pagination]
  );

  // Resolve Flagged Post
  const resolveOrRejectFlaggedPost = useCallback(
    (
      postId: string,
      flaggedPostId: string,
      page = 1,
      postSearch = '',
      filterStatus = '',
      actionTypeStatus = ''
    ) => {
      setIsLoadingAction(true);
      const detailUrl = `${API_CONFIG.path.flag}/${postId}`;
      httpService
        .put(detailUrl, {
          status: actionPayloadMapper[actionTypeStatus],
          postId: flaggedPostId,
        })
        .then(() => {
          getFlaggedPostList(
            page,
            pagination.recordPerPage,
            postSearch,
            filterStatus
          );
          setCurrentPostId('');
          setFlaggedPostId('');
          setIsConfirmOpen(false);
          setIsLoadingAction(false);
        })
        .catch((error) => {
          console.error(error);
          setIsLoadingAction(false);
          setCurrentPostId('');
          setFlaggedPostId('');
          setIsConfirmOpen(false);
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const handlePagination = useCallback(
    (page: number) => {
      if (page !== pagination.currentPage) {
        updateFilters({
          ...filters,
          currentPage: page.toString(),
        });
        getFlaggedPostList(
          page,
          pagination.recordPerPage,
          debouncedPostSearch ? debouncedPostSearch.trim() : '',
          currentStatus ? currentStatus : ''
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [debouncedPostSearch, currentStatus, pagination]
  );

  const handleAction = (
    type: 'resolve' | 'reject' | string,
    postId: string,
    flaggedPostId: string
  ) => {
    if (type && postId) {
      setCurrentPostId(postId);
      setFlaggedPostId(flaggedPostId);
      setIsConfirmOpen(true);
      setPostActionType(type);
    }
  };

  const handleStatus = (status: string) => {
    setCurrentStatus(status);
    updateFilters({
      ...filters,
      currentPage: '1',
    });
  };

  const handleSearch = (value: string) => {
    setPostSearch(value);
    updateFilters({
      ...filters,
      currentPage: '1',
    });
  };

  const handleConfirm = () => {
    resolveOrRejectFlaggedPost(
      currentPostId,
      flaggedPostId,
      pagination.currentPage,
      postSearch,
      currentStatus,
      postActionType
    );
  };

  const handleConfirmClose = () => {
    setIsConfirmOpen(false);
  };

  const searchProps = getSearchProps(postSearch, () => {
    setPostSearch('');
  });

  return (
    <>
      <PageHeader title="Flagged Posts" />
      <FlaggedPostsList
        flaggedPostsData={flaggedPostListData}
        loading={isLoading}
        handleAction={handleAction}
        handleSearch={handleSearch}
        handleStatus={handleStatus}
        pagination={pagination}
        searchProps={searchProps}
        postSearch={postSearch}
        currentPostStatus={currentStatus}
      />
      {pagination.totalPages > 1 && (
        <PaginationComponent
          loading={isLoading}
          pagination={pagination}
          fetchData={handlePagination}
        />
      )}
      <Confirm
        loading={isLoadingAction}
        opened={isConfirmOpen}
        modalTitle={modalTitleMapper[postActionType]}
        handleConfirm={handleConfirm}
        handleClose={handleConfirmClose}
        cancelBtnText="Cancel"
        submitBtnText={confirmBtnTextMapper[postActionType]}
        size={'450px'}
      />
    </>
  );
};

export default FlaggedPosts;
