import {
  Box,
  Button,
  Group,
  Select,
  Text,
  TextInput,
  useMantineTheme,
} from '@mantine/core';
import { useDebouncedValue } from '@mantine/hooks';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Confirm from '../../../shared/components/confirm/confirm';
import {
  IconExcelDownload,
  IconSearch,
} from '../../../shared/components/icons/icons';
import PageHeader from '../../../shared/components/pageHeader/pageHeader';
import PaginationComponent from '../../../shared/components/pagination/pagination';
import {
  API_CONFIG,
  getSearchProps,
  getSelectProps,
  searchInputStyle,
} from '../../../shared/constants/constants';
import useLocalStorageFilter from '../../../shared/hoc/useLocalStorageFilter';
import { IPagination } from '../../../shared/interface';
import httpService from '../../../shared/services/http.service';
import UsersList from '../components/usersList';
import {
  ICountry,
  ICountryRes,
  IStateInfo,
  IStateInfoRes,
  IUserInfo,
} from '../interface/users.interface';
import EditUserModal from '../modals/editUserModal';
import '../styles/styles.scss';

const Users: React.FC = () => {
  const theme = useMantineTheme();
  const [filters, updateFilters] = useLocalStorageFilter('userFilters', {
    currentPage: '1',
    userSearch: '',
    userGender: '',
    userAge: '',
  });

  const [usersListData, setUsersData] = useState<IUserInfo[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isExcelLoading, setIsExcelLoading] = useState(false);
  const [isLoadingAction, setIsLoadingAction] = useState(false);
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const [isBlockUserConfirmOpen, setIsBlockUserConfirmOpen] = useState(false);
  const [isEditUserOpen, setIsEditUserOpen] = useState(false);
  const [currentUserId, setCurrentUserId] = useState('');
  const [currentUserDetails, setCurrentUserDetails] = useState<IUserInfo>(
    {} as IUserInfo
  );

  const [countryList, setCountryList] = useState<ICountry[]>([]);
  const [isCountryLoading, setIsCountryLoading] = useState<boolean>(false);
  const [stateList, setStateList] = useState<IStateInfo[]>([]);
  const [isStateLoading, setIsStateLoading] = useState<boolean>(false);
  const [selectedCountry, setSelectedCountry] = useState<string>('');
  const [pagination, setPagination] = useState<IPagination>({
    currentPage: 1,
    nextPage: null,
    recordPerPage: 10,
    remainingCount: 0,
    total: 0,
    totalPages: 1,
  });

  const [isInputFocused, setInputFocused] = useState(false);

  const savedCurrentPage = parseInt(filters.currentPage || '1', 10);

  const [userSearch, setUserSearch] = useState(filters.userSearch || '');
  const [debouncedUserSearch] = useDebouncedValue(
    userSearch,
    userSearch ? 700 : 0
  );
  const [userGender, setUserGender] = useState(filters.userGender || '');

  const [userAge, setUserAge] = useState(filters.userAge || '');

  const navigate = useNavigate();

  const handleView = (userId: string) => {
    navigate(`/users/${userId}`);
  };

  useEffect(() => {
    if (isEditUserOpen && countryList.length === 0) {
      getCountryList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditUserOpen]);

  useEffect(() => {
    // Save filter values to local storage
    updateFilters({
      userSearch,
      userGender,
      userAge,
      currentPage: savedCurrentPage.toString(),
    });

    const hasOnlySpaces = /^ *$/.test(debouncedUserSearch);
    if (!hasOnlySpaces) {
      getUsersList(
        savedCurrentPage,
        pagination.recordPerPage,
        debouncedUserSearch.trim(),
        userGender || '',
        userAge || ''
      );
    } else if (debouncedUserSearch === '') {
      getUsersList(
        savedCurrentPage,
        pagination.recordPerPage,
        '',
        userGender || '',
        userAge || ''
      );
    }
    return () => {
      setCurrentUserId('');
      setCurrentUserDetails({} as IUserInfo);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedUserSearch, userGender, userAge, savedCurrentPage]);

  useEffect(() => {
    const selectedCountryString =
      selectedCountry || currentUserDetails.country || '';
    const countryId = countryList.find(
      (country: ICountry) =>
        country.name.toLowerCase() === selectedCountryString.toLowerCase()
    );
    if (countryId) {
      getStateList(countryId.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCountry, currentUserDetails.country, countryList]);

  // Get Users List
  const getUsersList = useCallback(
    (
      currentPage = pagination.currentPage,
      recordPerPage = pagination.recordPerPage,
      search = '',
      gender = userGender || '',
      age = userAge || ''
    ) => {
      setIsLoading(true);
      const detailUrl = `${API_CONFIG.path.users}`;
      httpService
        .get(detailUrl, {
          currentPage,
          recordPerPage,
          search,
          gender: gender,
          age: age,
        })
        .then((response) => {
          const { data, ...paginationData } = response.data;
          setPagination(paginationData);
          setUsersData(data);
          setIsLoading(false);
        })
        .catch((error) => {
          console.error(error);
          setIsLoading(false);
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [pagination]
  );

  // Delete User
  const deleteUser = useCallback(
    (
      userId: string,
      page = 1,
      userSearch = '',
      userGender = '',
      userAge = ''
    ) => {
      setIsLoadingAction(true);
      const detailUrl = `${API_CONFIG.path.users}/${userId}`;
      httpService
        .deleteRequest(detailUrl)
        .then(() => {
          getUsersList(
            page,
            pagination.recordPerPage,
            userSearch,
            userGender,
            userAge
          );
          setCurrentUserId('');
          setCurrentUserDetails({} as IUserInfo);
          setIsConfirmOpen(false);
          setIsLoadingAction(false);
        })
        .catch((error) => {
          console.error(error);
          setIsLoadingAction(false);
          setIsConfirmOpen(false);
          setCurrentUserId('');
          setCurrentUserDetails({} as IUserInfo);
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  // Block User
  const blockUser = useCallback(
    (
      isBlocked: boolean = false,
      userId: string,
      page = 1,
      userSearch = '',
      userGender = '',
      userAge = ''
    ) => {
      setIsLoadingAction(true);
      const detailUrl = `${API_CONFIG.path.users}/${userId}/status`;
      httpService
        .put(detailUrl, {
          isBlocked,
        })
        .then(() => {
          setIsLoadingAction(false);
          setIsBlockUserConfirmOpen(false);
          getUsersList(
            page,
            pagination.recordPerPage,
            userSearch,
            userGender,
            userAge
          );
        })
        .catch((error) => {
          console.error(error);
          setIsLoadingAction(false);
          setIsBlockUserConfirmOpen(false);
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  // Edit User
  const editUser = useCallback(
    (
      userId: string,
      data: any,
      page = 1,
      userSearch = '',
      userGender = '',
      userAge = ''
    ) => {
      setIsLoadingAction(true);
      const detailUrl = `${API_CONFIG.path.users}/${userId}`;
      httpService
        .put(detailUrl, data)
        .then(() => {
          getUsersList(
            page,
            pagination.recordPerPage,
            userSearch,
            userGender,
            userAge
          );
          setCurrentUserId('');
          setCurrentUserDetails({} as IUserInfo);
          setIsEditUserOpen(false);
          setIsLoadingAction(false);
        })
        .catch((error) => {
          console.error(error);
          setIsLoadingAction(false);
          setIsEditUserOpen(false);
          setCurrentUserId('');
          setCurrentUserDetails({} as IUserInfo);
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  // Get Users List
  const getCountryList = useCallback(
    () => {
      setIsCountryLoading(true);
      const detailUrl = `${API_CONFIG.path.user}/country`;
      httpService
        .get(detailUrl)
        .then((response) => {
          setIsCountryLoading(false);
          setCountryList(
            response.data.map((country: ICountryRes) => ({
              label: country.name,
              value: country.name,
              ...country,
            }))
          );
        })
        .catch((error) => {
          console.error(error);
          setIsCountryLoading(false);
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const getStateList = useCallback(
    (countryId: number) => {
      setIsStateLoading(true);
      const detailUrl = `${API_CONFIG.path.user}/state/${countryId}`;
      httpService
        .get(detailUrl)
        .then((response) => {
          const sortedStates = response.data
            .map((state: IStateInfoRes) => ({
              label: state.name,
              value: state.name,
              ...state,
            }))
            .sort((a: IStateInfoRes, b: IStateInfoRes) =>
              a.name.localeCompare(b.name)
            );
          setStateList(sortedStates);
          setIsStateLoading(false);
        })
        .catch((error) => {
          console.error(error);
          setIsStateLoading(false);
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedCountry]
  );

  const getExcelData = useCallback(
    (search = '', gender = userGender || '', age = userAge || '') => {
      setIsExcelLoading(true);
      const detailUrl = `${API_CONFIG.path.excelData}`;
      httpService
        .get(
          detailUrl,
          {
            search,
            gender: gender,
            age: age,
          },
          { responseType: 'blob' }
        )
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `${Date.now()}.xlsx`);
          document.body.appendChild(link);
          link.click();
          setIsExcelLoading(false);
        })
        .catch((error) => {
          console.error(error);
          setIsExcelLoading(false);
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const handlePagination = useCallback(
    (page: number) => {
      if (page !== pagination.currentPage) {
        // Save current page to local storage
        updateFilters({
          ...filters,
          currentPage: page.toString(),
        });
        getUsersList(
          page,
          pagination.recordPerPage,
          debouncedUserSearch ? debouncedUserSearch.trim() : '',
          userGender ? userGender : '',
          userAge ? userAge : ''
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [debouncedUserSearch, userAge, userGender, pagination]
  );

  const handleConfirmClose = () => {
    setIsConfirmOpen(false);
  };

  const handleBlockUserConfirmClose = () => {
    setIsBlockUserConfirmOpen(false);
  };

  const handleConfirmDelete = () => {
    deleteUser(
      currentUserId,
      pagination.currentPage,
      debouncedUserSearch,
      userGender,
      userAge
    );
  };

  const handleEditUser = (values: any) => {
    editUser(
      currentUserId,
      values,
      pagination.currentPage,
      debouncedUserSearch,
      userGender,
      userAge
    );
  };

  const handleBlockUser = () => {
    const isBlocked = currentUserDetails.status === 'Active';
    blockUser(
      isBlocked,
      currentUserId,
      pagination.currentPage,
      debouncedUserSearch,
      userGender,
      userAge
    );
  };

  const handleSelectedCountry = (country: string) => {
    setSelectedCountry(country);
  };
  const handleAction = (type: 'edit' | 'delete' | 'block', userId: string) => {
    if (type && userId) {
      const currentUserData = usersListData.filter(
        (user) => user.id === userId
      )[0];
      setCurrentUserId(userId);
      setCurrentUserDetails(currentUserData);
      if (type === 'edit') {
        setIsEditUserOpen(true);
      }
      if (type === 'delete') {
        setIsConfirmOpen(true);
      }
      if (type === 'block') {
        setIsBlockUserConfirmOpen(true);
      }
    }
  };

  const handleUserDataDownload = () => {
    getExcelData(userSearch, userGender, userAge);
  };

  const ageProps = useMemo(() => getSelectProps(userAge), [userAge]);
  const genderProps = useMemo(() => getSelectProps(userGender), [userGender]);
  const searchProps = useMemo(
    () => getSearchProps(userSearch, () => setUserSearch('')),
    [userSearch]
  );

  return (
    <Box>
      <PageHeader title="Users" />
      <Group
        align="center"
        position="apart"
        mb={15}

      >
        <Group>
          <Text
            size="xl"
            weight={700}
            sx={{
              fontFamily: 'Open Sans, sans-serif',
              fontSize: '18px',
              color: '#2E3646',
              fontWeight: 500,
              opacity: 0.5,
            }}
          >
            Users Details
          </Text>
        </Group>
        <Group>
          <Group sx={{ position: 'relative', borderRadius: 30 }}>
            <TextInput
              placeholder="Search"
              variant="outline"
              value={userSearch}
              styles={searchInputStyle(isInputFocused)}
              icon={<IconSearch color="#ADADAD" />}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setUserSearch(e.target.value);
                updateFilters({
                  ...filters,
                  currentPage: '1',
                });
              }}
              onFocus={() => setInputFocused(true)}
              onBlur={() => setInputFocused(false)}
              type="search"
              {...searchProps}
            />
          </Group>
          <Select
            name="gender"
            value={userGender}
            placeholder="Gender"
            iconWidth={100}
            data={[
              { value: 'male', label: 'Male' },
              { value: 'female', label: 'Female' },
              { value: 'non-binary', label: 'Non Binary' },
              { value: 'other', label: 'Other' },
            ]}
            sx={{
              width: '130px',
              boxShadow: theme.shadows.sm,
              background: 'transparent',
              borderRadius: '10px',
              fontSize: '12px',
            }}
            searchable
            clearable
            onChange={(value) => {
              setUserGender(value || '');
              updateFilters({
                ...filters,
                currentPage: '1',
              });
            }}
            transitionProps={{
              transition: 'pop-top-left',
              duration: 100,
              timingFunction: 'ease',
            }}
            {...genderProps}
          />
          <Select
            name="age"
            value={userAge}
            placeholder="Age"
            iconWidth={100}
            data={[
              { value: '13-17', label: '13-17' },
              { value: '18-24', label: '18-24' },
              { value: '25-34', label: '25-34' },
              { value: '35-44', label: '35-44' },
              { value: '45-54', label: '45-54' },
              { value: '55-64', label: '55-64' },
              { value: '65-200', label: '65+' },
            ]}
            sx={{
              width: '100px',
              boxShadow: theme.shadows.sm,
              background: 'transparent',
              borderRadius: '10px',
              fontSize: '12px',
            }}
            onChange={(value) => {
              setUserAge(value || '');
              updateFilters({
                ...filters,
                currentPage: '1',
              });
            }}
            searchable
            clearable
            transitionProps={{
              transition: 'pop-top-left',
              duration: 100,
              timingFunction: 'ease',
            }}
            {...ageProps}
          />
          <Button
            variant="filled"
            color="white"
            sx={{
              color: '#3983F3',
              fontFamily: 'Open Sans',
              fontWeight: 400,
              fontSize: '14px',
              border: '1px solid #3983F3',
              svg: isExcelLoading
                ? {
                  stroke: '#3983F3',
                }
                : {},
            }}
            loading={isExcelLoading}
            leftIcon={<IconExcelDownload color="#3983F3" />}
            onClick={handleUserDataDownload}
          >
            <Text size={'12px'} weight={700} sx={{ fontFamily: 'Open Sans' }}>
              Download Excel File
            </Text>
          </Button>
        </Group>
      </Group>
      <UsersList
        usersData={usersListData}
        loading={isLoading}
        pagination={pagination}
        handleAction={handleAction}
        handleView={handleView}
      />
      {pagination.totalPages > 1 && (
        <PaginationComponent
          pagination={pagination}
          fetchData={handlePagination}
          loading={isLoading}
        />
      )}
      <Confirm
        loading={isLoadingAction}
        opened={isConfirmOpen}
        modalTitle="Are you sure you want to Delete the user?"
        handleConfirm={handleConfirmDelete}
        handleClose={handleConfirmClose}
        cancelBtnText="Cancel"
        submitBtnText="Yes, Delete"
        size={'450px'}
      />
      <Confirm
        loading={isLoadingAction}
        opened={isBlockUserConfirmOpen}
        modalTitle={`Are you sure you want to ${currentUserDetails.status !== 'Active' ? 'unblock' : 'block'
          } this user?`}
        handleConfirm={handleBlockUser}
        handleClose={handleBlockUserConfirmClose}
        cancelBtnText="Cancel"
        submitBtnText={`Yes, ${currentUserDetails.status !== 'Active' ? 'Unblock' : 'Block'
          }`}
        size={'450px'}
      />
      <EditUserModal
        key={JSON.stringify(currentUserDetails)}
        loading={isLoadingAction}
        isStateLoading={isStateLoading}
        isCountryLoading={isCountryLoading}
        opened={isEditUserOpen}
        handleClose={() => setIsEditUserOpen(false)}
        handleSubmit={handleEditUser}
        countryList={countryList}
        stateList={stateList}
        userDetails={currentUserDetails}
        handleLocation={handleSelectedCountry}
        className="edit-user-modal"
      />
    </Box>
  );
};

export default Users;
