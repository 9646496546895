import { useSelector } from 'react-redux';
import { Navigate, Route, Routes } from 'react-router-dom';

import Login from './features/login/container/loginForm';

import Layout from './shared/hoc/layout';
import routes from './shared/hoc/routes';
import { State } from './shared/interface';

import moment from 'moment-timezone';
import { useEffect } from 'react';

function App() {
  const isLoggedIn = useSelector((state: State) => state.auth.authData.auth);
  useEffect(() => {
    moment.tz.setDefault('Etc/UTC');
  }, []);
  if (isLoggedIn) {
    return (
      <Layout>
        <Routes>
          {routes
            .filter((route) => route.href && route.component)
            .map(({ href, component, children }) => {
              if (children) {
                const combinedRoutes = [
                  <Route key={href} path={href} element={component} />,
                ];
                children.forEach((childRoute) => {
                  combinedRoutes.push(
                    <Route
                      key={childRoute.href}
                      path={childRoute.href}
                      element={childRoute.component}
                    />
                  );
                });
                return combinedRoutes;
              } else {
                return <Route key={href} path={href} element={component} />;
              }
            })}
          <Route path="*" element={<Navigate replace to="/" />} />
        </Routes>
      </Layout>
    );
  } else {
    return (
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="*" element={<Navigate replace to="/login" />} />
      </Routes>
    );
  }
}

export default App;
