import React from "react";

import CustomModal, { IModalProps } from "../modal/CustomModal";

interface IConfirmComponentProps {
  modalTitle: string;
  handleConfirm: (reason?: string) => void;
  loading?: boolean;
  submitBtnText?: string;
  cancelBtnText?: string;
  size?: string;
}

const Confirm: React.FC<IModalProps & IConfirmComponentProps> = (props) => {
  return (
    <CustomModal
      opened={props.opened}
      handleClose={props.handleClose}
      className={`confirm-modal`}
      modalTitle={props.modalTitle}
      isConfirm={true}
      cancelBtnText={props.cancelBtnText}
      submitBtnText={props.submitBtnText}
      handleConfirm={props.handleConfirm}
      size={props.size}
      loading={props.loading}
    />
  );
};

export default Confirm;
