import {
  Box,
  Button,
  Card,
  Flex,
  Loader,
  Select,
  Text,
  TextInput,
  useMantineTheme,
} from '@mantine/core';
import { DateInput } from '@mantine/dates';
import { useForm } from '@mantine/form';
import moment from 'moment';
import { IconChevronDownFilled } from '../../../shared/components/icons/icons';
import CustomModal from '../../../shared/components/modal/CustomModal';
import { primaryBoxShadow } from '../../../shared/constants/constants';
import {
  convertFirstLatterToCapital,
  convertFirstLatterToSmall,
  convertStringToGMT,
} from '../../../shared/util/utility';
import { ICountry, IStateInfo, IUserInfo } from '../interface/users.interface';

interface IProps {
  loading: boolean;
  opened: boolean;
  handleClose: () => void;
  handleSubmit: (values: any) => void;
  countryList?: ICountry[];
  stateList?: IStateInfo[];
  handleLocation?: (country: string) => void;
  isStateLoading?: boolean;
  isCountryLoading?: boolean;
  userDetails: IUserInfo;
  className: string;
}

interface FormValues {
  name: string;
  email: string;
  birthday: Date | null;
  gender: string;
  country: string;
  state: string;
}

const EditUserModal = (props: IProps) => {
  const {
    opened,
    handleClose,
    countryList,
    stateList,
    className,
    handleLocation,
    isStateLoading,
    isCountryLoading,
    userDetails,
    handleSubmit,
    loading,
  } = props;

  const initialValues = {
    name: userDetails.name || '',
    email: userDetails.email || '',
    birthday:
      userDetails.birthday ? convertStringToGMT(userDetails.birthday).toDate() || moment().toDate() : null,
    gender: userDetails.gender || '',
    country: convertFirstLatterToCapital(userDetails.country) || '',
    state: convertFirstLatterToCapital(userDetails.state) || '',
  };
  const form = useForm({
    initialValues,
    validate: {
      email: (value) => (/^\S+@\S+$/.test(value) ? null : 'Invalid email'),
    },
  });

  const birthdayFormProps = form.getInputProps('birthday');
  delete birthdayFormProps.onChange;

  const countryFormProps = form.getInputProps('country');
  delete countryFormProps.onChange;

  const handleEditSubmit = (values: FormValues) => {
    const payload = {
      ...values,
      gender: convertFirstLatterToSmall(values.gender),
      birthdate: moment(values.birthday).format('YYYY-MM-DD'),
    };
    handleSubmit(payload);
  };

  const Label = ({ label }: { label: string }) => {
    const theme = useMantineTheme();
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          height: '100%',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            margin: '10px',
            alignItems: 'center',
            borderRight: `1px solid ${theme.colors.gray[3]}`,
            width: 'calc(100% - 20px)',
            height: 'calc(100% - 20px)',
          }}
        >
          <Text size="sm" sx={{ lineHeight: 1 }}>
            {label}
          </Text>
        </Box>
      </Box>
    );
  };

  return (
    <CustomModal
      opened={opened}
      handleClose={handleClose}
      className={className}
      modalTitle="Edit User Details"
      isConfirm={false}
      hideCloseIcon
      size="520px"
    >
      <Card
        radius="10px"
        padding={0}
        mt={25}
        sx={{ '.mantine-Input-input': { height: 40 }, overflow: 'visible' }}
      >
        <form onSubmit={form.onSubmit(handleEditSubmit)}>
          <TextInput
            name="name"
            placeholder="Full Name"
            autoFocus
            icon={<Label label="Name" />}
            iconWidth={100}
            mb="15px"
            {...form.getInputProps('name')}
          />
          <TextInput
            name="email"
            placeholder="email@email.com"
            icon={<Label label="Email" />}
            iconWidth={100}
            mb="15px"
            disabled={true}
            {...form.getInputProps('email')}
          />
          <Flex mb="15px" gap="5px">
            <DateInput
              name="birthday"
              placeholder="Select"
              maw={400}
              mx="auto"
              valueFormat="MM.DD.YYYY"
              icon={<Label label="Birthday" />}
              iconWidth={100}
              popoverProps={{ zIndex: 1000 }}
              rightSection={<IconChevronDownFilled />}
              rightSectionProps={{
                style: {
                  pointerEvents: 'none',
                },
              }}
              {...birthdayFormProps}
              onChange={(date: Date) => {
                form.setFieldValue('birthday', moment(date).toDate());
              }}
              weekendDays={[]}
            />
            <Select
              name="gender"
              placeholder="Select"
              iconWidth={100}
              data={[
                { value: 'male', label: 'Male' },
                { value: 'female', label: 'Female' },
                { value: 'non-binary', label: 'Non Binary' },
                { value: 'other', label: 'Other' },
              ]}
              icon={<Label label="Gender" />}
              rightSection={<IconChevronDownFilled />}
              rightSectionProps={{
                style: {
                  pointerEvents: 'none',
                },
              }}
              searchable
              clearable
              {...form.getInputProps('gender')}
              transitionProps={{
                transition: 'pop-top-left',
                duration: 100,
                timingFunction: 'ease',
              }}
            />
          </Flex>
          <Select
            name="country"
            placeholder="Select"
            iconWidth={100}
            data={countryList ? countryList.map((item) => item.value) : []}
            icon={<Label label="Country" />}
            rightSection={
              isCountryLoading ? (
                <Loader size={16} />
              ) : (
                <IconChevronDownFilled />
              )
            }
            rightSectionProps={{
              style: {
                pointerEvents: 'none',
              },
            }}
            searchable
            {...countryFormProps}
            onChange={(country) => {
              if (country) {
                form.setFieldValue('country', country);
                form.setFieldValue('state', '');
                if (handleLocation) {
                  handleLocation(convertFirstLatterToCapital(country));
                }
              }
            }}
            transitionProps={{
              transition: 'pop-top-left',
              duration: 100,
              timingFunction: 'ease',
            }}
            mb="15px"
          />
          <Select
            name="state"
            placeholder="Select"
            iconWidth={100}
            data={stateList ? stateList.map((item) => item.value) : []}
            icon={<Label label="State" />}
            rightSection={
              isStateLoading ? <Loader size={16} /> : <IconChevronDownFilled />
            }
            rightSectionProps={{
              style: {
                pointerEvents: 'none',
              },
            }}
            searchable
            {...form.getInputProps('state')}
            transitionProps={{
              transition: 'pop-top-left',
              duration: 100,
              timingFunction: 'ease',
            }}
          />
          <Flex
            mt={20}
            sx={{ width: '100%', paddingBottom: '15px' }}
            justify="center"
          >
            <Button
              variant="outline"
              color="gray"
              sx={{ width: '50%', height: '37px', marginRight: '5px' }}
              onClick={() => {
                form.reset();
                handleClose();
              }}
            >
              Cancel
            </Button>
            <Button
              color="app"
              type="button"
              onClick={() => handleEditSubmit(form.values)}
              loading={loading}
              sx={{
                width: '50%',
                height: '37px',
                marginLeft: '5px',
                boxShadow: primaryBoxShadow,
              }}
            >
              Save
            </Button>
          </Flex>
        </form>
      </Card>
    </CustomModal>
  );
};

export default EditUserModal;
