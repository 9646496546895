import React, { useMemo, useState } from 'react';
import {
  Avatar,
  Badge,
  Box,
  Card,
  Flex,
  Group,
  Image,
  List,
  LoadingOverlay,
  Select,
  Text,
  TextInput,
  TextProps,
  Tooltip,
  useMantineTheme,
} from '@mantine/core';
import DataTable, { TableColumn } from 'react-data-table-component';
import { useNavigate } from 'react-router-dom';
import ActionButton from '../../../shared/components/actionButton/actionButton';
import {
  IconBlockFlag,
  IconResolveFlag,
  IconSearch,
  IconView,
} from '../../../shared/components/icons/icons';
import {
  dataTableStyles,
  getSelectProps,
  searchInputStyle,
  statusMapper,
} from '../../../shared/constants/constants';
import { IPagination } from '../../../shared/interface';
import {
  IFlaggedPostsListInfo,
  IReportedUser,
} from '../interface/flaggedPosts.interface';

export interface IFlaggedPostsList {
  flaggedPostsData: IFlaggedPostsListInfo[];
  loading: boolean;
  handleAction: (
    type: 'resolve' | 'reject',
    postId: string,
    flaggedPostId: string
  ) => void;
  handleSearch: (value: string) => void;
  handleStatus: (status: string) => void;
  pagination: IPagination;
  searchProps: any;
  postSearch: string;
  currentPostStatus: string;
}

const textStyle: TextProps = {
  lh: 1.5,
  align: 'center',
  ff: 'Open Sans',
  fw: 'normal',
  truncate: true,
};

const POST_STATUS: any = {
  accept: 'green',
  pending: 'yellow',
  reject: 'red',
};

const FlaggedPostsList: React.FC<IFlaggedPostsList> = (props) => {
  const {
    flaggedPostsData,
    loading,
    handleAction,
    handleSearch,
    handleStatus,
    pagination,
    searchProps,
    postSearch,
    currentPostStatus,
  } = props;
  const { currentPage, recordPerPage } = pagination;
  const theme = useMantineTheme();
  const navigate = useNavigate();
  const [isInputFocused, setInputFocused] = useState(false);

  const handleView = (postId: string) => {
    navigate(`/flagged-posts/${postId}`);
  };

  const handleUserView = (userId: string) => {
    navigate(`/users/${userId}`);
  };

  const columns: TableColumn<any>[] = useMemo(() => {
    return [
      {
        name: 'Sr. No.',
        width: "80px",
        center: true,
        selector: (row) => row.id,
        cell: (row, index) => {
          return <span>{(currentPage - 1) * recordPerPage + index + 1}</span>
        }
      },

      {
        name: 'Username',
        maxWidth: "200px",
        center: true,
        selector: (row) => row.username || "-",
      },
      {
        name: 'Post',
        width: "150px",
        center: true,
        selector: (row) => row.postImage,
        cell: (row) => {
          return (
            <Image
              src={row.postImage}
              my={"xs"}
              alt="Image"
              radius="sm"
              width={70}
              height={70}
              onClick={() => handleView(row.id)}
            />
          )
        }
      },
      {
        name: 'Post Caption',
        center: true,
        maxWidth: "250px",
        selector: (row) => row.postDescription || "-",
        cell: (row) => {
          return (
            <Tooltip label={row.postDescription || '-'} multiline sx={{ maxWidth: "500px" }} onClick={() => handleView(row.id)}>
              <Text {...textStyle}>
                {row.postDescription || '-'}
              </Text>
            </Tooltip>
          );
        },
      },
      {
        name: 'Daily Challenge',
        center: true,
        selector: (row) => row.description || "-",
        cell: (row) => {
          return (
            <Tooltip label={row.description || '-'} multiline sx={{ maxWidth: "500px" }} onClick={() => handleView(row.id)}>
              <Text {...textStyle}>
                {row.description || '-'}
              </Text>
            </Tooltip>
          );
        },
      },
      {
        name: 'No. Of Reporters',
        center: true,
        width: "150px",
        selector: (row) => row.reportedUser || [],
        cell: (row: any) => {
          const { reportedUser } = row;
          const reportersCount = reportedUser.length;
          return reportedUser.length > 0 ? (
            <>
              <Avatar.Group
                spacing="sm"
                sx={{
                  alignItems: 'center',
                  justifyContent: 'center',
                  flexDirection: 'row-reverse',
                }}
              >
                {reportersCount > 2 && (
                  <Text
                    sx={{ fontFamily: 'Open Sans, sans-serif' }}
                    pl={4}
                    weight={600}
                    size={'14px'}
                  >{`+${reportersCount - 2}`}</Text>
                )}
                {reportedUser
                  .slice(0, 2)
                  .map((user: IReportedUser, index: number) => {
                    return (
                      <Tooltip label={user.uName} key={index} withArrow>
                        <Avatar
                          src={user.uProfilePhoto}
                          size={'30px'}
                          radius="xl"
                          onClick={(
                            e: React.MouseEvent<HTMLDivElement, MouseEvent>
                          ) => {
                            e.stopPropagation();
                            handleUserView(user.uId);
                          }}
                        />
                      </Tooltip>
                    );
                  })}
              </Avatar.Group>
            </>
          ) : (
            '--'
          )
        },
      },
      {
        name: 'Status',
        center: true,
        width: "170px",
        selector: (row) => row.status || "-",
        cell: (row) => {
          return (
            <Badge
              sx={{
                fontWeight: 600,
                fontFamily: 'Open Sans',
                fontSize: '14px',
                textTransform: 'capitalize',
              }}
              color={POST_STATUS[`${row.status}`]}
              size={'xl'}
            >
              {statusMapper[row.status]}
            </Badge>
          );
        },
      },
      {
        name: 'Action',
        center: true,
        width: "200px",
        ignoreRowClick: true,
        cell: (row) => {
          return (
            <Flex align={'center'} justify={'center'}>
              <ActionButton
                onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
                  e.stopPropagation();
                  handleView(row.id);
                }}
                icon={<IconView />}
              />
              {row.status === 'pending' && (
                <ActionButton
                  onClick={(
                    e: React.MouseEvent<HTMLDivElement, MouseEvent>
                  ) => {
                    e.stopPropagation();
                    handleAction('resolve', row.id, row.postId);
                  }}
                  icon={<IconResolveFlag color="#2AC670" />}
                />
              )}
              {row.status === 'pending' && (
                <ActionButton
                  onClick={(
                    e: React.MouseEvent<HTMLDivElement, MouseEvent>
                  ) => {
                    e.stopPropagation();
                    handleAction('reject', row.id, row.postId);
                  }}
                  icon={<IconBlockFlag color="#FF6252" />}
                />
              )}
            </Flex>
          )
        }
      },
    ]
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flaggedPostsData])

  const statusProps = getSelectProps(currentPostStatus);

  const loadingComponent = useMemo(() => (
    <Card sx={loading ? { minHeight: "60px" } : {}}>
      {loading ? (
        <LoadingOverlay
          visible={true}
          overlayBlur={2}
          zIndex={5}
          sx={{ borderRadius: '10px' }}
        />
      ) : (
        <Text>
          No any challenges found.
        </Text>
      )}
    </Card>
  ), [loading])

  return (
    <>
      <List
        size="sm"
        center
        listStyleType={'none'}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          margin: 0,
          fontFamily: 'Open Sans, sans-serif',
          color: '#2E3646',
        }}
        fw={500}
        fz={'18px'}
        mb={15}
      >
        <List.Item icon={<IconResolveFlag color="#2AC670" />} mr={'sm'}>
          <Text
            sx={{
              fontFamily: 'Open Sans, sans-serif',
              opacity: 0.5,
            }}
          >
            I find the post Appropriate
          </Text>
        </List.Item>
        <List.Item icon={<IconBlockFlag color="#FF6252" />}>
          <Text
            sx={{
              fontFamily: 'Open Sans, sans-serif',
              opacity: 0.5,
            }}
          >
            I find the post Inappropriate(Block post for all)
          </Text>
        </List.Item>
      </List>
      <Group
        align="center"
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          height: '37px',
          marginBottom: '15px',
        }}
      >
        <Group>
          <Text
            size="xl"
            weight={700}
            sx={{
              fontFamily: 'Open Sans, sans-serif',
              fontSize: '18px',
              color: '#2E3646',
              fontWeight: 500,
              opacity: 0.5,
            }}
          >
            {`${flaggedPostsData.length} Flagged Posts`}
          </Text>
        </Group>
        <Group>
          <Group
            style={{ position: 'relative', borderRadius: 30 }}
            sx={{ boxShadow: theme.shadows.md }}
          >
            <TextInput
              placeholder="Search..."
              variant="outline"
              value={postSearch}
              styles={searchInputStyle(isInputFocused)}
              icon={<IconSearch color="#ADADAD" />}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                handleSearch(e.target.value);
              }}
              onFocus={() => setInputFocused(true)}
              onBlur={() => setInputFocused(false)}
              {...searchProps}
            />
          </Group>
          <Select
            placeholder="Select Status"
            value={currentPostStatus}
            data={[
              { value: 'pending', label: 'Pending' },
              { value: 'accept', label: 'Appropriate' },
              { value: 'reject', label: 'Inappropriate' },
            ]}
            sx={{
              width: '145px',
              boxShadow: theme.shadows.md,
              background: 'transparent',
              borderRadius: '10px',
            }}
            clearable
            searchable
            onChange={(value) => {
              handleStatus(value || '');
            }}
            transitionProps={{
              transition: 'pop-top-left',
              duration: 100,
              timingFunction: 'ease',
            }}
            {...statusProps}
          />
        </Group>
      </Group>
      <Box pos={'relative'} sx={{
        boxShadow: "rgba(0, 0, 0, 0.1) 0px 10px 50px -10px",
        borderRadius: "9px",
      }}>
        <DataTable
          striped
          responsive
          onRowClicked={(row) => {
            handleView(row.id)
          }}
          pointerOnHover
          persistTableHead
          noDataComponent={loadingComponent}
          highlightOnHover
          customStyles={dataTableStyles}
          columns={columns}
          data={loading ? [] : flaggedPostsData}
        />
      </Box>
    </>
  );
};

export default FlaggedPostsList;
