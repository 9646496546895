import { Global } from "@mantine/core";

import boldRubik from "./fonts/Rubik/Rubik-Bold.ttf";
import extraBoldRubik from "./fonts/Rubik/Rubik-ExtraBold.ttf";
import mediumRubik from "./fonts/Rubik/Rubik-Medium.ttf";
import regularRubik from "./fonts/Rubik/Rubik-Regular.ttf";

import boldOpenSans from "./fonts/OpenSans/OpenSans-Bold.ttf";
import extraBoldOpenSans from "./fonts/OpenSans/OpenSans-ExtraBold.ttf";
import lightOpenSans from "./fonts/OpenSans/OpenSans-Light.ttf";
import mediumOpenSans from "./fonts/OpenSans/OpenSans-Medium.ttf";
import regularOpenSans from "./fonts/OpenSans/OpenSans-Regular.ttf";

import boldInter from "./fonts/Inter/Inter-Bold.ttf";
import lightInter from "./fonts/Inter/Inter-Light.ttf";
import mediumInter from "./fonts/Inter/Inter-Medium.ttf";
import regularInter from "./fonts/Inter/Inter-Regular.ttf";

import semiBoldInter from "./fonts/Inter/Inter-SemiBold.ttf";

import boldPoppins from "./fonts/Poppins/Poppins-Bold.ttf";
import lightPoppins from "./fonts/Poppins/Poppins-Light.ttf";
import mediumPoppins from "./fonts/Poppins/Poppins-Medium.ttf";
import regularPoppins from "./fonts/Poppins/Poppins-Regular.ttf";
import semiBoldPoppins from "./fonts/Poppins/Poppins-SemiBold.ttf";

export function CustomFonts() {
  return (
    <Global
      styles={[
        {
          "@font-face": {
            fontFamily: "Rubik",
            src: `url('${regularRubik}')`,
            fontWeight: 400,
            fontStyle: "normal",
          },
        },
        {
          "@font-face": {
            fontFamily: "Rubik",
            src: `url('${mediumRubik}')`,
            fontWeight: 500,
            fontStyle: "normal",
          },
        },
        {
          "@font-face": {
            fontFamily: "Rubik",
            src: `url('${boldRubik}')`,
            fontWeight: 700,
            fontStyle: "normal",
          },
        },
        {
          "@font-face": {
            fontFamily: "Rubik",
            src: `url('${extraBoldRubik}')`,
            fontWeight: 800,
            fontStyle: "normal",
          },
        },
        {
          "@font-face": {
            fontFamily: "Open Sans",
            src: `url('${lightOpenSans}')`,
            fontWeight: 400,
            fontStyle: "normal",
          },
        },
        {
          "@font-face": {
            fontFamily: "Open Sans",
            src: `url('${regularOpenSans}')`,
            fontWeight: 400,
            fontStyle: "normal",
          },
        },
        {
          "@font-face": {
            fontFamily: "Open Sans",
            src: `url('${mediumOpenSans}')`,
            fontWeight: 500,
            fontStyle: "normal",
          },
        },
        {
          "@font-face": {
            fontFamily: "Open Sans",
            src: `url('${boldOpenSans}')`,
            fontWeight: 700,
            fontStyle: "normal",
          },
        },
        {
          "@font-face": {
            fontFamily: "Open Sans",
            src: `url('${extraBoldOpenSans}')`,
            fontWeight: 800,
            fontStyle: "normal",
          },
        },
        {
          "@font-face": {
            fontFamily: "Inter",
            src: `url('${regularInter}')`,
            fontWeight: 400,
            fontStyle: "normal",
          },
        },
        {
          "@font-face": {
            fontFamily: "Inter",
            src: `url('${mediumInter}')`,
            fontWeight: 500,
            fontStyle: "normal",
          },
        },
        {
          "@font-face": {
            fontFamily: "Inter",
            src: `url('${boldInter}')`,
            fontWeight: 700,
            fontStyle: "normal",
          },
        },
        {
          "@font-face": {
            fontFamily: "Inter",
            src: `url('${semiBoldInter}')`,
            fontWeight: 800,
            fontStyle: "normal",
          },
        },
        {
          "@font-face": {
            fontFamily: "Inter",
            src: `url('${lightInter}')`,
            fontWeight: 400,
            fontStyle: "normal",
          },
        },
        {
          "@font-face": {
            fontFamily: "Poppins",
            src: `url('${regularPoppins}')`,
            fontWeight: 400,
            fontStyle: "normal",
          },
        },
        {
          "@font-face": {
            fontFamily: "Poppins",
            src: `url('${mediumPoppins}')`,
            fontWeight: 500,
            fontStyle: "normal",
          },
        },
        {
          "@font-face": {
            fontFamily: "Poppins",
            src: `url('${boldPoppins}')`,
            fontWeight: 700,
            fontStyle: "normal",
          },
        },
        {
          "@font-face": {
            fontFamily: "Poppins",
            src: `url('${semiBoldPoppins}')`,
            fontWeight: 800,
            fontStyle: "normal",
          },
        },
        {
          "@font-face": {
            fontFamily: "Poppins",
            src: `url('${lightPoppins}')`,
            fontWeight: 400,
            fontStyle: "normal",
          },
        },
      ]}
    />
  );
}
