import { useCallback, useEffect, useState } from 'react';
import { Box, Card, LoadingOverlay } from '@mantine/core';
import { Reset } from '@mantine/form/lib/types';
import { useDebouncedValue } from '@mantine/hooks';
import moment from 'moment-timezone';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop';
import { useNavigate } from 'react-router-dom';
import CustomBadge from '../../../shared/components/eventBadge/customBadge';
import { IconAdd } from '../../../shared/components/icons/icons';
import PageHeader from '../../../shared/components/pageHeader/pageHeader';
import {
  API_CONFIG,
  GetPromptLabelColor,
  getSearchProps,
} from '../../../shared/constants/constants';
import notify from '../../../shared/notification/notification';
import httpService from '../../../shared/services/http.service';
import ChallengeModal from '../../dailyChallenges/modals/challengeModal';
import CustomToolbar from '../component/customToolbar';
import { ICalendarEvent } from '../interface/calendar.interface';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import '../styles/calender.scss';
import {
  convertToGMTDate,
  formatDateToMMDDYYYY,
} from '../../../shared/util/utility';

interface IOnEventDrop<TEvent> {
  event: TEvent;
  start: string | Date;
  end: string | Date;
  isAllDay: boolean;
}

const EventCalendar = withDragAndDrop(Calendar);

const CalendarComponent = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingAction, setIsLoadingAction] = useState(false);
  const [rescheduling, setRescheduling] = useState(false);
  const [challengesList, setChallengesList] = useState<ICalendarEvent[]>([]);
  const [scheduleDateList, setScheduleDateList] = useState<string[]>([]);
  const [isAddChallenge, setIsAddChallenge] = useState(false);
  const [challengeSearch, setChallengeSearch] = useState('');
  const [debouncedChallengeSearch] = useDebouncedValue(
    challengeSearch,
    challengeSearch ? 700 : 0
  );
  const [selectedSlot, setSelectedSlot] = useState<string | undefined>();
  const [currentCalendarView, setCurrentCalendarView] = useState<Date>(
    new Date()
  );

  // Event drag or click detector listener----------------------------------

  let drag = false;
  let threshold = 0;
  let startingX: number;
  let startingY: number;
  let eveId = '';

  let downListener = (event: any, id: string) => {
    eveId = id;
    drag = false;
    startingX = event.pageX;
    startingY = event.pageY;
  };

  let moveListener = (e: any) => {
    const moveX = Math.abs(e.pageX - startingX);
    const moveY = Math.abs(e.pageY - startingY);

    if (moveX >= threshold || moveY >= threshold) {
      drag = true;
    }
  };

  let upListener = () => {
    if (drag) {
    } else {
      handleView(eveId);
    }
    eveId = '';
  };
  // Event drag or click detector listener----------------------------------

  // useEffect(() => {
  //   moment.tz.setDefault(GMT_TIMEZONE);
  // }, []);

  // ChallengeSearch
  useEffect(() => {
    const hasOnlySpaces = /^ *$/.test(debouncedChallengeSearch);
    if (!hasOnlySpaces) {
      getAllScheduledChallenges(
        undefined,
        undefined,
        debouncedChallengeSearch.trim()
      );
    } else if (debouncedChallengeSearch === '') {
      getAllScheduledChallenges(undefined, undefined, '');
    }
    return () => {
      setChallengesList([]);
      setCurrentCalendarView(new Date());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedChallengeSearch]);

  const getAllScheduledChallenges = useCallback(
    (startDate = '', endDate = '', challengeDescription = '') => {
      setIsLoading(true);
      const detailUrl = `${API_CONFIG.path.challenge}/all`;
      httpService
        .get(detailUrl, {
          startDate,
          endDate,
          challengeDescription,
        })
        .then((response) => {
          const userPostsData = { ...response }.data;
          const modifiedPostEvents = [...userPostsData].map((item: any) => {
            return {
              ...item,
              start: item.scheduleDate * 1000,
              end: item.scheduleDate * 1000,
              title: item.description,
              dragging: false,
              dragged: false,
            };
          });
          setChallengesList(modifiedPostEvents);
          setScheduleDateList(
            userPostsData.map((item: any) =>
              formatDateToMMDDYYYY(convertToGMTDate(item.scheduleDate) as Date)
            )
          );
          setIsLoading(false);
        })
        .catch((error) => {
          console.error(error);
          setIsLoading(false);
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const addChallenge = useCallback(
    (values: any, resetForm?: Reset) => {
      const payload = {
        label: values.label,
        description: values.description,
        scheduleDate: values.scheduleDate
          ? moment(values.scheduleDate).format('YYYY-MM-DD')
          : null,
      };

      if (payload.scheduleDate === 'Invalid date') {
        payload.scheduleDate = null;
      }

      setCurrentCalendarView(
        payload.scheduleDate ? new Date(payload.scheduleDate) : new Date()
      );

      setIsLoadingAction(true);
      const detailUrl = `${API_CONFIG.path.challenge}`;
      httpService
        .post(detailUrl, payload)
        .then((response) => {
          setIsAddChallenge(false);
          setIsLoadingAction(false);
          getAllScheduledChallenges();
          if (resetForm) {
            resetForm();
          }
          setSelectedSlot(undefined);
        })
        .catch((error) => {
          console.error(error);
          setIsLoadingAction(false);
          setIsAddChallenge(false);
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const scheduleChallenge = useCallback(
    (challengeId: string, scheduleDate: any) => {
      const payload = {
        scheduleDate: moment(scheduleDate).format('YYYY-MM-DD'),
      };
      setRescheduling(true);
      const detailUrl = `${API_CONFIG.path.challenge}/${challengeId}/schedule`;
      httpService
        .put(detailUrl, payload)
        .then((response) => {
          setRescheduling(false);
          getAllScheduledChallenges();
        })
        .catch((error) => {
          console.error(error);
          setRescheduling(false);
          setChallengesList(challengesList);
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const handleAddChallengeClose = () => {
    setIsAddChallenge(false);
    setSelectedSlot(undefined);
  };

  const handleAddChallenge = (values: any, resetForm?: Reset) => {
    addChallenge(values, resetForm);
  };

  const handleAddChallengeAction = () => {
    setIsAddChallenge(true);
  };

  const handleView = (userId: string) => {
    navigate(`/challenges/${userId}`);
  };

  const handleSearch = (value: string) => setChallengeSearch(value);

  const onEventDrop = (data: IOnEventDrop<any>) => {
    const { start, event } = data;
    const nextEvents = [...challengesList];
    const idx = challengesList.findIndex((ev) => ev.id === event.id);
    if (moment(event.end).isSame(start, 'day')) {
      nextEvents[idx] = event;
      setChallengesList(nextEvents);
      return;
    }
    const existingEvent = challengesList.find((challenge) =>
      moment(challenge.end).isSame(start, 'day')
    );
    if (existingEvent) {
      notify(
        `Sorry, you can't move this event because it conflicts with "${existingEvent.description}".`,
        'info'
      );
      nextEvents[idx] = event;
      setChallengesList(nextEvents);
      return;
    }
    // Temporarily remove the dragged event from the list
    nextEvents[idx] = { ...event, start };
    setChallengesList(nextEvents);
    scheduleChallenge(event.id, start);
  };

  const actionButtons = [
    {
      label: 'Add Challenge',
      icon: <IconAdd color="white" />,
      type: 'add',
      handleAction: handleAddChallengeAction,
    },
  ];

  const searchProps = getSearchProps(challengeSearch, () => {
    setChallengeSearch('');
  });

  return (
    <Box>
      <PageHeader
        title="Calendar"
        showSearchButton
        showActionButtons={actionButtons}
        handleSearchAction={handleSearch}
        searchValue={challengeSearch}
        searchProps={searchProps}
      />
      <Card sx={{ position: 'relative' }} shadow="md" p={0}>
        <LoadingOverlay
          visible={isLoading || rescheduling}
          overlayBlur={2}
          zIndex={5}
          sx={{ borderRadius: '10px' }}
        />
        <EventCalendar
          key={`${challengesList.length}`}
          events={challengesList}
          defaultDate={currentCalendarView}
          defaultView="month"
          selectable
          localizer={momentLocalizer(moment)}
          onEventDrop={onEventDrop}
          draggableAccessor={(event: any) =>
            !moment(event.start).isSame(new Date(), 'day')
          }
          onDragStart={(data: any) => {
            const { event } = data;
            const nextEvents = [...challengesList];
            const idx = challengesList.indexOf(event);
            nextEvents.splice(idx, 1, {
              ...event,
              dragging: true,
            });
            setChallengesList(nextEvents);
          }}
          onSelectSlot={({ start }) => {
            if (
              !scheduleDateList.includes(moment(start).format('MM/DD/YYYY'))
            ) {
              handleAddChallengeAction();
              setSelectedSlot(moment(start).format('MM/DD/YYYY'));
            }
          }}
          style={{ height: '100vh' }}
          className="event-calender"
          views={['month', 'week']}
          components={{
            event: ({ event }: any) => {
              const labelColor = GetPromptLabelColor(event.label.toLowerCase());
              return (
                <Box
                  onMouseUp={upListener}
                  onMouseDown={(
                    e: React.MouseEvent<HTMLDivElement, MouseEvent>
                  ) => downListener(e, event.id)}
                  onMouseMove={moveListener}
                  sx={{
                    transition: '0.2s opacity ease-in-out',
                    opacity: event.dragging ? 0.3 : 1,
                  }}
                >
                  <CustomBadge
                    truncate
                    color={labelColor || ''}
                    label={event.description}
                    labelStyle={{
                      fontFamily: 'Open Sans, sans-serif',
                      lineHeight: 'normal',
                      color: labelColor || '',
                      fontSize: '12px',
                      maxWidth: '210px',
                    }}
                  />
                </Box>
              );
            },
            toolbar: CustomToolbar,
          }}
        />
      </Card>
      <ChallengeModal
        loading={isLoadingAction}
        modalTitle={'Add Challenge'}
        challenge={{ scheduleDate: selectedSlot }}
        opened={isAddChallenge}
        scheduleDateList={scheduleDateList}
        handleClose={handleAddChallengeClose}
        handleSubmit={handleAddChallenge}
        isCalendar={true}
      />
    </Box>
  );
};

export default CalendarComponent;
