import { Button, Flex, Modal, Sx, Title } from '@mantine/core';
import React, { PropsWithChildren } from 'react';
import { primaryBoxShadow } from '../../constants/constants';

export interface IModalProps extends PropsWithChildren {
  opened: boolean;
  handleClose: () => void | undefined;
  className?: string;
  modalTitle?: string;
  loading?: boolean;
  restrictCloseOnEscape?: boolean;
  closeOnOutSideClick?: boolean;
  isConfirm?: boolean;
  cancelBtnText?: string;
  submitBtnText?: string;
  handleConfirm?: () => void;
  hideCloseIcon?: boolean;
  size?: string;
}

const headerStyle = (isConfirm?: boolean) => ({
  justifyContent: 'center',
  padding: isConfirm ? '0 25px 0 25px' : '10px 20px 20px 20px',
  maxWidth: '300px',
  margin: '0 auto',
});
const headerTitleStyle = (isConfirm?: boolean) =>
  ({
    textAlign: 'center',
    fontFamily: 'Open Sans',
    fontSize: isConfirm ? '18px' : '25px',
  } as Sx);

const CustomModal: React.FC<IModalProps> = (props) => {
  const {
    opened,
    handleClose,
    className,
    modalTitle,
    loading,
    isConfirm,
    cancelBtnText,
    submitBtnText,
    handleConfirm,
    hideCloseIcon,
    size,
    children,
  } = props;

  return (
    <Modal
      opened={opened}
      onClose={() => handleClose()}
      className={`${className || ''} fadeIn `}
      withCloseButton={!hideCloseIcon}
      trapFocus={false}
      size={size}
      sx={{
        '.mantine-Modal-content': { overflowY: 'visible' },
        '.mantine-Modal-header': { borderRadius: '10px', paddingBottom: 0 },
      }}
      centered
    >
      {modalTitle && (
        <Modal.Header sx={headerStyle(isConfirm)}>
          <Title order={isConfirm ? 3 : 2} sx={headerTitleStyle(isConfirm)}>
            {modalTitle}
          </Title>
        </Modal.Header>
      )}
      <Modal.Body sx={{ padding: '0 20px 5px 20px' }}>{children}</Modal.Body>
      {isConfirm && (
        <Flex
          sx={{ padding: '20px 20px 15px 20px' }}
          justify="center"
          align="center"
        >
          <Button
            variant="outline"
            color="gray"
            onClick={handleClose}
            sx={{ width: '50%', height: '37px', marginRight: '5px' }}
          >
            {cancelBtnText || 'No'}
          </Button>
          <Button
            color={'app'}
            sx={{
              width: '50%',
              height: '37px',
              marginLeft: '5px',
              boxShadow: primaryBoxShadow,
            }}
            loading={loading}
            onClick={handleConfirm}
          >
            {submitBtnText || 'Yes'}
          </Button>
        </Flex>
      )}
    </Modal>
  );
};

export default CustomModal;
