import findIndex from 'lodash/findIndex';
import moment, { Moment } from 'moment-timezone';

import { isArray } from 'lodash';
import AuthService from '../../shared/services/auth.service';
import { Action } from '../interface';

export const createAction = (ACTION: string, data: any = null): Action => {
  return {
    type: ACTION,
    payload: data,
  };
};

/**
 * function which returns formatted date
 * @param date
 */
export const formatDate = (date: any, format?: string) => {
  if (!date) {
    return '';
  }
  return moment(date)
    .local()
    .format(format || 'DD-MM-YYYY | HH:mm:ss');
};
/**
 * function to convert date to iso string and set seconds/milliseconds to zero
 * @param date
 */
export const convertDateToISOString = (date: any) => {
  if (!date) {
    return '';
  }
  return moment(date).set({ seconds: 0, milliseconds: 0 }).toISOString();
};

export const debounce = (func: any, wait = 400) => {
  let h: NodeJS.Timeout;
  return (...args: any) => {
    clearTimeout(h);
    h = setTimeout(() => func(...args), wait);
  };
};

export const setGMTtimeZone = () => { };

const numberFormatterInstance = new Intl.NumberFormat('de-DE');

export const numberFormatter = (value: number) => {
  return numberFormatterInstance.format(value);
};

export const setFileInputValue = (
  event: React.ChangeEvent<HTMLInputElement>,
  callBack: (name: string, value: any) => void,
  key: string,
  values: any[] | Record<string, unknown>,
  types: string[]
) => {
  const files = event.target.files;
  if (!files || !files[0]) {
    return;
  }
  const fileList = Array.from(files);
  let data: any = {};
  if (isArray(values)) {
    data = values;
    if (!data) {
      data = [];
    }
    if (fileList.length > 0 && fileList.length <= 8) {
      for (const file of fileList) {
        const validateFileIndex = types
          ? findIndex(types, (type) => file.type.startsWith(type))
          : -1;
        if (validateFileIndex >= 0) {
          data.push({
            file: file,
            url: window.URL.createObjectURL(file),
            type: file.type.startsWith('image') ? 'image' : 'video',
          });
        }
      }
    }
  } else {
    const file = fileList[0];
    data = {
      file: file,
      url: window.URL.createObjectURL(file),
      type: file.type.startsWith('image') ? 'image' : 'video',
    };
  }
  callBack(key, data);
};

export const isMobile = () => {
  if (
    typeof navigator === 'undefined' ||
    typeof navigator.userAgent !== 'string'
  ) {
    return false;
  }
  return /Mobile/.test(navigator.userAgent);
};

export const windowScroll = (
  value: number,
  position: 'top' | 'bottom' = 'top',
  behavior: 'auto' | 'smooth' = 'smooth'
) =>
  window.scrollTo({
    [position]: value,
    behavior: behavior,
  });

export const checkPermission = (props: any) => {
  const userData: any = AuthService.getAuthData();
  if (userData) {
    const { userRoleDetails } = userData;
    const { rolePermissions } = userRoleDetails;
    const modules = Object.keys(rolePermissions);
    if (
      props.module &&
      modules.includes(props.module) &&
      rolePermissions[props.module].includes(props.permission)
    ) {
      return true;
    }
  }
  return false;
};

export const toRoundTwoDecimal = (num: number) => {
  return Math.round(num * 100) / 100 || 0;
};

export const dateFormate = (date: Moment) => {
  return moment(date).format('DD.MM.YY');
};

////////////////////////////////// This is for Exclamation Point  //////////////////////////// /////////////

export const addLeadingZero = (number: number) => {
  return number.toString().padStart(2, '0');
};
export const unixTimestampToFormattedDate = (unixTimestamp: number) => {
  const date = new Date(unixTimestamp * 1000); // Convert Unix timestamp to milliseconds
  const day = String(date.getUTCDate()).padStart(2, '0');
  const month = String(date.getUTCMonth() + 1).padStart(2, '0');
  const year = date.getUTCFullYear();
  return `${month}-${day}-${year}`;
};

export const convertFirstLatterToCapital = (key: string) => {
  if (!!key) {
    return key.charAt(0).toUpperCase() + key.slice(1);
  } else {
    return '';
  }
};

export const convertFirstLatterToSmall = (key: string) => {
  if (!!key) {
    return key.charAt(0).toLowerCase() + key.slice(1);
  } else {
    return '';
  }
};

export const convertToLocalTimeZone = (unixTimestamp: string | number) => {
  const testDateUtc =
    typeof unixTimestamp === 'string'
      ? moment(unixTimestamp).utc()
      : moment.utc(unixTimestamp * 1000);
  const localDate = moment(testDateUtc).local();
  return localDate;
};

export const convertUnixToGMT = (unixTimestamp: number) => {
  // Create a Moment.js object from the Unix timestamp
  const gmtMoment = moment.unix(unixTimestamp);
  // Set the UTC offset to 0 (GMT+0000)
  gmtMoment.utcOffset(0);
  return gmtMoment;
};

export const convertStringToGMT = (dateString: string) => {
  const inputFormat = 'YYYY-MM-DD';
  const gmtMoment = moment.utc(dateString, inputFormat);
  return gmtMoment;
};

export const formatDateToMMDDYYYY = (date: Date, format = 'MM/DD/YYYY') => {
  const day = String(date.getDate()).padStart(2, '0'); // Ensure 2-digit day
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Month is zero-based
  const year = date.getFullYear();

  // Combine the day, month, and year in DD/MM/YYYY format
  // const formattedDate = `${month}/${day}/${year}`;
  const formattedDate = format
    .replace('DD', day)
    .replace('MM', month)
    .replace('YYYY', year.toString());
  return formattedDate;
};

export const convertToGMTDate = (date: Date | string | number | null) => {
  if (date === null) return null;
  let newDate;
  switch (typeof date) {
    case 'number':
      newDate = new Date(date * 1000);
      newDate.setMinutes(newDate.getMinutes() + newDate.getTimezoneOffset());
      break;

    case 'string':
      newDate = new Date(date);
      break;

    default:
      newDate = date;
      break;
  }
  return newDate;
};

export const getChallengeStatus = (schedule_date: any) => {
  if (!schedule_date) {
    return "unscheduled";
  }

  const convertedScheduledDate = moment(schedule_date * 1000);
  const difference = moment().diff(convertedScheduledDate, 'days')

  if (difference === 0) {
    return "active";
  } else if (difference < 0) {
    return "scheduled";
  } else {
    return "posted";
  }
};

export const formatTimestamp = (
  timestamp: number,
  dateFormat = 'YYYY-MM-DD HH:mm:ss.SSSSSS'
) => {
  return moment.unix(timestamp).format(dateFormat);
};

export const dateToUnixTimestamp = (dateString: string | Date) => {
  const momentObject = moment(dateString);
  return momentObject.unix();
};

export const formatUnixTimestamp = (timestamp: number): string => {
  const formattedDate = moment.unix(timestamp).format('HH:mm , DD-MM-YYYY');
  return formattedDate;
};

export const changeMinutesToHours = (minutes: number) => {
  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60;
  return `${String(hours).padStart(2, '0')}:${String(remainingMinutes).padStart(2, '0')} H`;
}