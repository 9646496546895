import {
  Box,
  Button,
  Card,
  Divider,
  Group,
  LoadingOverlay,
  Text,
} from '@mantine/core';
import moment from 'moment';
import { useCallback, useEffect, useRef, useState } from 'react';
import CustomBadgeGroup from '../../../shared/components/eventBadge/customBadgeGroup';
import {
  API_CONFIG,
  challengeType,
  daysMapper,
} from '../../../shared/constants/constants';
import httpService from '../../../shared/services/http.service';
import { IChartData } from '../interface/dailyChallenges.interface';
import ChallengesChart from './challengesChart';

const ChallengesTypeChart = () => {
  const [chartData, setChartData] = useState<IChartData[]>([]);
  const [activeDay, setActiveDay] = useState<number>(7);
  const [loading, setIsLoading] = useState(false);

  const chartWrapperRef: React.RefObject<HTMLDivElement> = useRef(null);

  const [innerWidth, setInnerWidth] = useState(
    chartWrapperRef.current?.offsetWidth || 0
  );

  const enddate = moment().format('MM-DD-YYYY');
  const startdate = moment()
    .subtract(activeDay - 1, 'days')
    .format('MM-DD-YYYY');

  useEffect(() => {
    getChartDetails(enddate, startdate, activeDay);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeDay]);

  useEffect(() => {
    const handleResize = () => {
      if (chartWrapperRef && chartWrapperRef.current) {
        setInnerWidth(chartWrapperRef.current.offsetWidth);
      }
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (chartWrapperRef && chartWrapperRef.current && innerWidth === 0) {
      setInnerWidth(chartWrapperRef.current.offsetWidth);
    }
  }, [chartWrapperRef, innerWidth]);

  const getChartDetails = useCallback(
    (enddate: string, startdate: string, activeDay: number) => {
      setIsLoading(true);
      httpService
        .get(API_CONFIG.path.chartData, {
          startdate,
          enddate,
          dayFilter: activeDay,
        })
        .then((response) => {
          const modiFiedData = response.data.map((item: any) => {
            return {
              x:
                activeDay === 7 || activeDay === 14
                  ? moment(item.x).format('MM-DD-YYYY')
                  : item.x,
              y: Number(item.y / 100).toFixed(2),
              label: item.label,
              value: item.y,
              bgColor:
                PROMPT_LABEL_COLOR[
                labelArray.includes(item.label) ? item.label : 'none'
                ],
            };
          });
          setChartData(modiFiedData);
          setIsLoading(false);
        })
        .catch((error) => {
          console.error(error);
          setIsLoading(false);
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const handleDays = (days: number) => {
    setActiveDay(days);
  };

  const PROMPT_LABEL_COLOR: any = {
    draw: '#7CA9FF',
    write: '#C57CFF',
    business: '#74D2CD',
    other: '#FF7CBB',
    none: '#ADADAD',
  };

  const labelArray = ['draw', 'write', 'business', 'other'];

  return (
    <Card padding="lg">
      <Group position="apart" mt={'md'} mb={25}>
        <Box sx={{ '*': { fontFamily: 'Open Sans' } }}>
          <Text fz="xl" mb={'5px'}>
            Challenge Types
          </Text>
          <Text fz="lg" color="gray">
            {daysMapper[activeDay]}
          </Text>
        </Box>
        <Box>
          <Button
            radius="lg"
            variant={activeDay === 7 ? 'filled' : 'outline'}
            color={activeDay === 7 ? 'blue' : 'gray'}
            sx={{ width: '143px' }}
            mr={5}
            onClick={() => handleDays(7)}
          >
            Last 7 Days
          </Button>
          <Button
            radius="lg"
            variant={activeDay === 14 ? 'filled' : 'outline'}
            color={activeDay === 14 ? 'blue' : 'gray'}
            sx={{ width: '143px' }}
            mr={5}
            onClick={() => handleDays(14)}
          >
            Last 14 Days
          </Button>
          <Button
            radius="lg"
            variant={activeDay === 30 ? 'filled' : 'outline'}
            color={activeDay === 30 ? 'blue' : 'gray'}
            sx={{ width: '143px' }}
            mr={5}
            onClick={() => handleDays(30)}
          >
            Last 30 Days
          </Button>
          <Button
            radius="lg"
            variant={activeDay === 90 ? 'filled' : 'outline'}
            color={activeDay === 90 ? 'blue' : 'gray'}
            sx={{ width: '143px' }}
            onClick={() => handleDays(90)}
          >
            Last 90 Days
          </Button>
        </Box>
      </Group>
      <Divider my="sm" />
      <CustomBadgeGroup data={challengeType} align={'end'} />
      <Box
        mt={25}
        display={'flex'}
        sx={{
          justifyContent: 'center',
          position: 'relative',
          height: '420px',
        }}
        key={activeDay}
        ref={chartWrapperRef}
      >
        <LoadingOverlay
          visible={loading}
          overlayBlur={2}
          zIndex={5}
          sx={{ borderRadius: '10px' }}
        />
        {chartData ? (
          <ChallengesChart
            chartWidth={innerWidth}
            key={`${activeDay}-${chartData.length}-${innerWidth}`}
            chartData={chartData}
          />
        ) : (
          <Text>No Data Found</Text>
        )}
      </Box>
    </Card >
  );
};

export default ChallengesTypeChart;
